import { ApiResult, DailyAverageByMonth, DesignDayVolumes, Reports, useAuth } from "@app/shared";
import { useFetch } from "../../hooks";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { OptionallySplitLineChart } from "./OptionallySplitLineChart";
import { BarStackMapped, OptionallyStackedBarChart } from "./OptionallyStackedBarChart";
import { useContext, useMemo } from "react";
import { ReportContext, buildStackedData, groupByKeys, tooltipBuilder } from "./reportHelpers";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";


async function GetAnnualComparison(result: ApiResult<DesignDayVolumes>) { // probably shouldn't consider incomplete months based on data range
    const volumes = await result;
    const dataToChart = [] as DailyAverageByMonth[];

    volumes?.monthlyAverages?.forEach(datum => {
        const existingIdx = dataToChart.findIndex(x => x.year === datum.year);
        if (existingIdx < 0) {
            dataToChart.push({ ...datum });
        } else if (dataToChart[existingIdx].averageDailyVolume < datum.averageDailyVolume) {
            dataToChart[existingIdx] = datum;
        }
    });
    return dataToChart;
}

export const ChartDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const [data, error, { isLoading }] = useFetch(() => GetAnnualComparison(Reports.getAverageDailyVolumes(searchParams)), [searchParams]);
    const years = useMemo(() => data?.length ? Array.from(new Set(data.map((d) => d.year))) : undefined, [data]);

    const fitData = useMemo(() => {
        if (!data?.length) return undefined;
        const cData = groupByKeys(data, ['year', 'month'], 'averageDailyVolume');//[...data];
        return buildStackedData<DailyAverageByMonth>(cData, "averageDailyVolume", "year");
    }, [data]);

    const chartProps = {
        ...props,
        toolTip: tooltipBuilder((x: DailyAverageByMonth) => x.year + '', (d: DailyAverageByMonth) => numberFormatter.format(Math.round(d.averageDailyVolume)), 'Average Daily Volume'),
        data: data as BarStackMapped<DailyAverageByMonth>[],
        xKey: "year" as keyof DailyAverageByMonth,
        yKey: "averageDailyVolume" as keyof DailyAverageByMonth,
        xTickCount: years?.length
    }

    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >
        {reportConfig.chartType === 'lines' ?
            <OptionallySplitLineChart {...chartProps} />
            : <OptionallyStackedBarChart {...chartProps} />}
    </ReportLoadingErrorWrapper>);
}

export const AnnualVolumeComparisonReport = {
    name: "Annual Volume Comparison Report",
    description: "This report shows the average daily volume for every year in the specified range",
    component: ChartDataLoader,
    defaultChartType: 'bars',
    defaultRange: DateOptions.AllData,
    key: 'atvc',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: false
    }
} as IConfiguredTrafficReport;