import { DateTime, IDateTime } from "./System.DateTime.gen";

export interface IForwardedPortBase {
	id: string | undefined;

	deviceId: string | undefined;

	requested: string | undefined;

	deviceConnected: string | undefined;

	terminated: string | undefined;

	serverPort: number;

	clientPort: number;

	$type?: string | undefined;
}
export class ForwardedPortBase {
	constructor(data?: IForwardedPortBase) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.requested) { this.requested = new Date(data.requested); }
		if (data.deviceConnected) { this.deviceConnected = new Date(data.deviceConnected); }
		if (data.terminated) { this.terminated = new Date(data.terminated); }
	}

	id: string | undefined;

	deviceId: string | undefined;

	requested: Date | undefined = new Date();

	deviceConnected: Date | undefined;

	terminated: Date | undefined;

	serverPort: number = 0;

	clientPort: number = 0;

	static $type: string = "CoreApi.Models.ForwardedPortBase, ";
	$type?: string | undefined = ForwardedPortBase.$type;
}

