import { ApiResult, ServerError, UserError } from "@app/shared";
import { useCallback, useEffect, useState } from "react";
import { useStateWithCallback } from ".";

type Fetcher<T> = () => ApiResult<T>;
type UseFetchHelpers = {
	isLoading?: boolean;
	refreshData: () => void;
};
type UseFetchResult<T> = [T | undefined, string | undefined, UseFetchHelpers];

export function useFetch<T>(
	fetcher: Fetcher<T>,
	dependencies?: any[]
): UseFetchResult<T> {
	const [data, setData] = useStateWithCallback<T | undefined>(undefined);
	const [error, setError] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [needsRefresh, setNeedsRefresh] = useState(0);

	const refreshData = () => {
		setNeedsRefresh((prev) => prev + 1);
	};

	const fetchData = useCallback(async () => {
		//if (data !== undefined) setData(undefined);
		setIsLoading(true);

		try {
			//await sleep(5000);
			if (process.env.NODE_ENV !== 'production') {
				console.debug(`fetching data from ${fetcher}`);
			}
			const newData = await fetcher();
			//If the newData is exact to the old data, our callback will never be called
			if (newData === data) setIsLoading(false);
			setData(newData, () => {
				setIsLoading(false);
			});
		} catch (err) {
			setIsLoading(false);
			if (err instanceof UserError) {
				setError(err.message);
			} else if (err instanceof ServerError) {
				setError(`Server error: ${err.message}`);
			} else {
				// eslint-disable-line react-hooks/exhaustive-deps
				//TODO: Need to fix this in case error is not of type Error
				setError(err + "");
			}
		}
	}, [...(dependencies ?? []), needsRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setError("");
		fetchData();
	}, [fetchData]); // eslint-disable-line react-hooks/exhaustive-deps

	//If we get an error that is not UserError or ServerError, throw it.  Are we sure we want to do this?
	/*if (exception) {
		throw exception;
	}*/

	return [data, error, { isLoading, refreshData }];
}
