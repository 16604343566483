import { Vector2, IVector2 } from "./System.Numerics.Vector2.gen";
import { Single, ISingle } from "./System.Single.gen";
import { DetectionZoneType } from "./DetectionZoneType.gen";

export interface IDetectionZone {
	zoneType: DetectionZoneType;

	name: string | undefined;

	points: IVector2[] | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	zoneDirection: number | undefined;

	$type?: string | undefined;
}
export class DetectionZone {
	constructor(data?: IDetectionZone) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.points = data.points ? data.points.map((p: any) => new Vector2(p)) : [];
	}

	zoneType: DetectionZoneType = 0;

	name: string | undefined;

	points: Vector2[] | undefined = [];

	id: string | undefined;

	facilityId: string | undefined;

	zoneDirection: number | undefined;

	static $type: string = "CoreApi.Models.DetectionZone, ";
	$type?: string | undefined = DetectionZone.$type;
}

