export interface IFacilityGroup {
	facilityCount: number;

	id: string | undefined;

	isDeleted: boolean;

	organizationId: string | undefined;

	name: string | undefined;

	$type?: string | undefined;
}
export class FacilityGroup {
	constructor(data?: IFacilityGroup) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	facilityCount: number = 0;

	id: string | undefined;

	isDeleted: boolean = false;

	organizationId: string | undefined;

	name: string | undefined;

	static $type: string = "CoreApi.Models.FacilityGroup, ";
	$type?: string | undefined = FacilityGroup.$type;
}

