import { useTimeout } from "react-use";

export const ApplicationLoading = () => {
    const [isTakingALongTime] = useTimeout(200);

    if (!isTakingALongTime()) { return <></>; }

    return (
        <div className='l-error-page'>
            <div className='row align-items-center'>
                <div className='d-flex col-12 col-md-auto justify-content-center text-brand-primary error-icon fs-2'>
                    <div className='loading-spinner' />
                </div>
            </div>
        </div>
    );
}