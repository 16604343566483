import { Facilities } from "@app/shared";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Pages } from "..";
import { useFetch } from "../../../hooks";
import { DashboardLayout } from "../../layouts";
import { TabbedDashLayout } from "../../layouts/TabbedDashLayout";
import { SkeletonGrid } from "../../shared";
import { FacilityDetailsInfo } from "./FacilityDetailsInfo";
import { FacilityDevices } from "./FacilityDevices";
import { FacilityLayoutInfo } from "./FacilityLayoutInfo";
import { ReportsView } from "../../reports/ReportsView";
import { FacilitySettings } from "./FacilitySettings";
import { FacilityParkingView } from "./FacilityParkingView";
import { FacilityLiveView } from "./FacilityLiveView";

export const FacilityDetails: FC = (props) => {
    const { id } = useParams();
    const [facility, , helpers] = useFetch(() => Facilities.getById(id));
    const navigate = useNavigate();

    useEffect(() => {
        if (!facility && helpers.isLoading === false) {
            navigate(Pages.ViewFacilityGroups.getUrl());
        }
    }, [facility, helpers.isLoading, navigate])

    if (helpers.isLoading || helpers.isLoading === undefined) {
        return (
            <DashboardLayout centerChildren={"Loading..."}>
                <SkeletonGrid rows={3} cols={1} />
            </DashboardLayout>
        );
    }

    if (!facility)
        return <></>;

    var key = 1;

    return (
        <TabbedDashLayout
            tabs={[
                { title: "Details", child: <FacilityDetailsInfo key={key++} facility={facility} onChange={helpers.refreshData} /> },                
                { title: "Live View", child: <FacilityLiveView key={key++} facility={facility} /> },
                { title: "Parking View", child: <FacilityParkingView key={key++} facility={facility} /> },
                { title: "Layout", child: <FacilityLayoutInfo key={key++} facility={facility} onChange={helpers.refreshData} /> },
                { title: "Devices", child: <FacilityDevices key={key++} facility={facility} onChange={helpers.refreshData} /> },
                { title: "Reports", child: <ReportsView key={key++} facility={facility} onChange={helpers.refreshData} /> },
                { title: "Settings", child: <FacilitySettings key={key++} facility={facility} onChange={helpers.refreshData} /> }]}
            centerChildren={facility?.name}
        />
    );
}