import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react";
import { MapContext } from "../../../helpers";
import { MaxHeightContainer } from "../charts/MaxHeightContainer";
import "./OmniGoogleMap.css";

export interface OmniMapMethods { reCenter: () => void, map: google.maps.Map | undefined, topRightControlDiv: HTMLDivElement | undefined };
export type MapProps = {
    width?: number,
    height?: number,
    noWrap?: boolean,
    center?: google.maps.LatLng,
    fitToPolygons?: boolean,
    showPositionCircles?: boolean,
    children?: ReactNode;
    onClick?: (pos: google.maps.LatLng | null) => void;
    draggable?: boolean;
    draggableCursor?: string;
    onMouseMove?: (map: google.maps.Map, pos: google.maps.LatLng | null) => void;
};

export const OmniGoogleMap = forwardRef<OmniMapMethods, MapProps>((props, ref) => {

    const initMap = () => {
        const mapEle = mapRef.current as any as HTMLElement//document.getElementById("map") as HTMLElement;

        const mapOpts: google.maps.MapOptions = {
            zoom: 20,
            center: props?.center ?? { lat: 37, lng: -122 },
            mapTypeId: "hybrid",
            scrollwheel: false,
            disableDefaultUI: true,
            clickableIcons: false,
            zoomControl: true,
            gestureHandling: "cooperative",
        };

        const thisMap = new google.maps.Map(
            mapEle,
            mapOpts
        );

        return thisMap;
    };

    const mapRef = useRef(null);
    const [mapObj, setMapObj] = useState<google.maps.Map>();
    const [topRightControlDiv, setTopRightControlDiv] = useState<HTMLDivElement>();
    const [mapBounds, setBounds] = useState(new google.maps.LatLngBounds())
    const [, setCenter] = useState(null as null | google.maps.LatLng);
    const hasFit = useRef(false);

    useEffect(() => {
        if (mapObj) {
            const div = document.createElement('div');
            setTopRightControlDiv(div);
            mapObj.controls[google.maps.ControlPosition.TOP_RIGHT].push(div);
        }
    }, [mapObj]);

    useEffect(() => { //fit to bounds manually as fit to bounds doesn't work well (screws up zoom after centering)
        if (props.fitToPolygons && mapObj && !hasFit.current) {
            const l = mapObj.addListener('idle', () => {
                setTimeout(() => {
                    if (!hasFit.current) {
                        hasFit.current = true;
                        fitBounds();
                    }
                }, 25);//time for loading all children: zones lines etc
            });
            return () => {
                l.remove();
            }
        }
    }, [mapBounds, props.fitToPolygons, mapObj]);//eslint-disable-line react-hooks/exhaustive-deps


    const fitBounds = (force = false) => {

        if (mapBounds.isEmpty()) { return; }
        setCenter(curr => {
            const center = mapBounds.getCenter();
            if (center && (!center.equals(curr) || force)) {
                const pxDims = { width: (mapRef.current! as HTMLDivElement).offsetWidth, height: (mapRef.current! as HTMLElement).offsetHeight };
                if (pxDims.width === 0 || pxDims.height === 0) { return curr; }
                const panFinished = mapObj?.addListener('idle', () => {
                    panFinished?.remove();
                    smoothZoom(mapObj, getBoundsZoomLevel(mapBounds, pxDims), mapObj.getZoom()!);
                });
                mapObj?.panTo(center);
                return center;
            }
            return curr;
        });
    }

    useEffect(() => {
        setMapObj(curr => {
            if (!mapRef.current) { return undefined; }
            if (curr?.getDiv() === mapRef.current) { return curr; }
            return initMap();
        });
        return () => {
            if (!mapObj) return;
            google.maps.event.clearInstanceListeners(mapObj);
        }
    }, [mapRef]);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.onClick && mapObj) {
            const h = props.onClick;
            const listener = mapObj.addListener('click', (e: google.maps.MapMouseEvent) => h(e.latLng))
            return () => listener.remove();
        }
    }, [props.onClick, mapObj])
    useEffect(() => {
        if (props.draggable !== undefined && mapObj) {
            mapObj.set('draggable', props.draggable);
        }
    }, [props.draggable, mapObj])
    useEffect(() => {
        if (props.onMouseMove && mapObj) {
            const h = props.onMouseMove;
            const listener = mapObj.addListener('mousemove', (e: google.maps.MapMouseEvent) => h(mapObj, e.latLng))
            return () => listener.remove();
        }
    }, [props.onMouseMove, mapObj])
    useEffect(() => {
        if (mapObj) {
            mapObj.set('draggableCursor', props.draggableCursor);
        }
    }, [props.draggableCursor, mapObj])

    // Expose the recenterMap function to the parent using useImperativeHandle
    useImperativeHandle(ref, () => ({
        reCenter: () => {
            fitBounds(true);
        },
        map: mapObj,
        topRightControlDiv
    }));
    if (props.noWrap) {
        return (<>
            <div ref={mapRef} style={{ height: '100%' }}></div>
            <MapContext.Provider value={[mapObj, setBounds]}>{props.children}</MapContext.Provider>
        </>);
    }
    if ((props.width ?? 0) > 0) {
        return (<div id='wrap' style={{ width: props.width + 'px', height: props.height + 'px' }}>
            <div ref={mapRef} style={{ height: '100%' }}></div>
            <MapContext.Provider value={[mapObj, setBounds]}>{props.children}</MapContext.Provider>
        </div>);
    }
    return (<MaxHeightContainer minHeight={300} className="" margin={{ left: 0, right: -96, top: 32, bottom: 32 }}>{({ height }) => {
        return (<div id='wrap' style={{ height: height + 'px' }}>
            <div ref={mapRef} style={{ height: '100%' }}></div>
            <MapContext.Provider value={[mapObj, setBounds]}>{props.children}</MapContext.Provider>
        </div>);
    }}</MaxHeightContainer>);
});

//https://stackoverflow.com/a/13274361
function getBoundsZoomLevel(bounds: google.maps.LatLngBounds, mapDim: { height: number, width: number }) {
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    function latRad(lat: number) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
}


function smoothZoom(map: google.maps.Map, desired: number, current: number) {
    if (current !== desired) {
        const z = google.maps.event.addListener(map, 'zoom_changed', function (event: google.maps.ZoomChangeEvent) {
            google.maps.event.removeListener(z);
            smoothZoom(map, desired, current > desired ? current - 1 : current + 1);
        });
    }
    setTimeout(function () { map.setZoom(current) }, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
}