import { DependencyList, useEffect, useRef } from "react";

export function useIsMounted(deps: DependencyList = []) {
    const isMountedRef = useRef(false);
    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false; }
    }, deps);
    return isMountedRef;
}
