import { Int32, IInt32 } from "./System.Int32.gen";

export interface IProcessorInfo {
	id: string | undefined;

	numCores: number;

	coreUsages: number[];

	coreFrequencies: number[];

	gpuFrequency: number;

	gpuUsage: number;

	$type?: string | undefined;
}
export class ProcessorInfo {
	constructor(data?: IProcessorInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	numCores: number = 0;

	coreUsages: number[] = [];

	coreFrequencies: number[] = [];

	gpuFrequency: number = 0;

	gpuUsage: number = 0;

	static $type: string = "CoreApi.Models.Hardware.ProcessorInfo, ";
	$type?: string | undefined = ProcessorInfo.$type;
}

