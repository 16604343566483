/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post, del, patch } from "./api_client";
import { FacilityUtilizationReport, IFacilityUtilizationReport, Facility, IFacility, FacilityOccupancy, IFacilityOccupancy, Target, ITarget, FacilityLayoutConfiguration, IFacilityLayoutConfiguration, FacilityGeometryConfiguration, IFacilityGeometryConfiguration } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getUtilizations(guids?: string[]): ApiResult<FacilityUtilizationReport[]> {
		const queryParams = [guids !== undefined ? `guids=${guids}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IFacilityUtilizationReport[]>(`/api/facility/utilizations${query}`).then(result => result === undefined ? undefined : result.map(o => new FacilityUtilizationReport(o)));
}

export async function getById(id?: string): ApiResult<Facility> {
	return get<IFacility>(`/api/facility/${ id }`).then(result => result === undefined ? undefined :  new Facility(result));
}

export async function getByGroupId(id?: string): ApiResult<Facility[]> {
	return get<IFacility[]>(`/api/facility/in-group/${ id }`).then(result => result === undefined ? undefined : result.map(o => new Facility(o)));
}

export async function getOccupancy(id?: string): ApiResult<FacilityOccupancy> {
	return get<IFacilityOccupancy>(`/api/facility/${ id }/occupancy`).then(result => result === undefined ? undefined :  new FacilityOccupancy(result));
}

export async function getAll(): ApiResult<Facility[]> {
	return get<IFacility[]>(`/api/facility`).then(result => result === undefined ? undefined : result.map(o => new Facility(o)));
}

export async function updateLayoutConfiguration(id?: string, newConfig?: FacilityLayoutConfiguration): ApiResult<Facility> {
	return patch<IFacility>(`/api/facility/${ id }/layoutconfiguration`, newConfig).then(result => result === undefined ? undefined :  new Facility(result));
}

export async function updateSettings(id?: string, facility?: Facility): ApiResult<Facility> {
	return patch<IFacility>(`/api/facility/${ id }/updatesettings`, facility).then(result => result === undefined ? undefined :  new Facility(result));
}

export async function updateGeometryConfiguration(id?: string, config?: FacilityGeometryConfiguration): ApiResult<Facility> {
	return patch<IFacility>(`/api/facility/${ id }/geometryconfiguration`, config).then(result => result === undefined ? undefined :  new Facility(result));
}

export async function getTargets(facilityId?: string, startTime?: number, endTime?: number): ApiResult<Target[]> {
	return get<ITarget[]>(`/api/facility/${ facilityId }/targets/${ startTime }/${ endTime }`).then(result => result === undefined ? undefined : result.map(o => new Target(o)));
}

export async function save(facility: Facility): ApiResult<string> {
	return post<string>(`/api/facility`, facility);
}

export async function deleteFacility(id?: string): ApiResult<boolean> {
	return del<boolean>(`/api/facility/${ id }`);
}
