import { Spot, Vector2 } from "@app/shared";
import { InputRow, NumericInput } from "../../inputs";

type SpotConfigProps = {
    spot: Spot;
    isEditable?: boolean;
    onChange: (newSpot: Spot) => void;
    isCollapsed: boolean;
};

export const SpotConfig: React.FC<SpotConfigProps> = ({ spot, isEditable, isCollapsed, onChange }) => {
    const handleOnChange = (e: number | undefined, key: keyof Vector2) => {
        var newOrigin = { ...spot.origin, [key]: e } as Vector2;
        var newSpot = { ...spot, origin: newOrigin, points: [newOrigin] };
        onChange(newSpot);
    };

    return isCollapsed ? null : (
        <>
            <InputRow>
                <div className="grid grid-cols-2 gap-5">
                    <NumericInput disabled={!isEditable} label="Origin X" value={spot.origin?.x} onChange={(e) => handleOnChange(e, "x")} />
                    <NumericInput disabled={!isEditable} label="Origin Y" value={spot.origin?.y} onChange={(e) => handleOnChange(e, "y")} />
                </div>
            </InputRow>
        </>
    );
}