export interface IFirmwareDeviceCount {
	firmwareVersion: string | undefined;

	deviceCount: number;

	$type?: string | undefined;
}
export class FirmwareDeviceCount {
	constructor(data?: IFirmwareDeviceCount) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	firmwareVersion: string | undefined;

	deviceCount: number = 0;

	static $type: string = "CoreApi.Models.View.FirmwareDeviceCount, ";
	$type?: string | undefined = FirmwareDeviceCount.$type;
}

