import { CustomClaimValue } from "@app/shared";
import cn from "classnames";
import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "../shared";
import { AuthorizeView } from "../shared/AuthorizeView";
import { ErrorBoundaryFallback } from "../shared/ErrorBoundaryFallback";
import { DashboardLayout, DashboardLayoutProps } from "./DashboardLayout";

type TabBarTab = {
    title: string | ReactNode;
    child: ReactNode;
    requiredClaim?: CustomClaimValue
}

type TabbedDashLayoutProps = {
    tabs: TabBarTab[];
} & Omit<DashboardLayoutProps, "children">;


export const TabbedDashLayout: React.FC<TabbedDashLayoutProps> = ({ tabs, ...props }) => {
    const navigate = useNavigate();
    const params = useSearchParams();
    const tab = parseInt(params[0].get("tab") ?? "0");
    const innerShadow = { boxShadow: "inset 0px -4px 4px -4px rgba(0, 0, 0, .1)" };

    return (
        <DashboardLayout {...props} bodyClassName="">
            <div className="flex">
                {tabs.map((t, i) => {
                    const TabHtml = (
                        <div key={t.requiredClaim ? undefined : i} style={i === tab ? {} : innerShadow} className={cn("border-r border-separators-page py-2 px-6", { "border-b bg-background-top-nav-tabs": i !== tab })}>
                            <Link onClick={() => {
                                var newParams = new URLSearchParams(params[0]);
                                newParams.set("tab", i.toString());
                                navigate(`?${newParams.toString()}`);
                            }}>{t.title}</Link>
                        </div>
                    );
                    return t.requiredClaim ? (<AuthorizeView key={i} claim={t.requiredClaim} children={TabHtml}></AuthorizeView>) : TabHtml;
                })}
                <div style={innerShadow} className="border-b flex-grow border-separators-page bg-background-top-nav-tabs"></div>
            </div>
            <div className="p-3">
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    {tabs[tab].child}
                </ErrorBoundary>
            </div>
        </DashboardLayout>
    );
}