import cn from "classnames";
type StatusDotProps = {
    status?: 'ok' | 'warn' | 'error'
};

export const StatusDot: React.FC<StatusDotProps> = ({ status }) => {
    const color = status === 'ok' ? "#30D15F" : (status === 'error' ? "#D00E0E" : (status === 'warn' ? "#FFD700" : "#FFFFFF"));

    return (
        <div className={cn("rounded h-2 w-2 my-auto mx-auto", { "border": status === undefined })} style={{ backgroundColor: color }} />
    );
}