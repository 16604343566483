import { forwardRef } from "react";
import { InputLabel, InputProps } from "..";
import { ValidatedInputGroup } from "./ValidatedInputGroup";

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(props, ref) => {
		const {
			fieldName,
			validationError,
			validateModel,
			className = "w-full",
			onEnter,
			...inputProps
		} = props;

		return (
			<ValidatedInputGroup
				fieldName={fieldName}
				validate={validateModel}
				validationError={validationError}>
				{({ label, handleBlur }) => (
					<>
						{props.label && <InputLabel>{props.label}</InputLabel>}
						<span className='relative'>
							{props.addon && (
								<span className='absolute right-3 inline-block bottom-0'>
									{props.addon}
								</span>
							)}
							<input
								className={"omnisight " + className}
								ref={ref}
								placeholder={label}
								onBlur={handleBlur}
								onKeyUp={
									async (e) => {
										if (onEnter && e.key === "Enter") {
											await onEnter();
										}
									}
								}
								{...inputProps}
							/>
						</span>
					</>
				)}
			</ValidatedInputGroup>
		);
	}
);
