import { trimRight } from "./stringHelpers";

export const parseFloatWithDefault = (text: string, defaultValue?: number) => {
    var retVal = Number.parseFloat(text);
    if (Number.isNaN(retVal))
        return defaultValue;
    return retVal;
}

export const toOrdinalString = (num: number) => {
    if (num <= 0)
        return "0";
    const sfx = ["th", "st", "nd", "rd"];
    const v = num % 100;
    return num + (sfx[(v - 20) % 10] || sfx[v] || sfx[0]);
}

export const toFixedMax = (num: number, maxDigits: number) => {
    if (num === undefined || num === null) return "";

    const retVal = num.toFixed(maxDigits);
    return trimRight(trimRight(retVal, "0"), ".");
}

export const MaxServerIntValue = 2147483647;