import { PortCommand, StreamPortCommand, StreamPortCommandParams } from "@app/shared";
import { useModel } from "../../../hooks";
import { EnumSelect, InputRow, NumericInput } from "../inputs";
import { DeviceCommandEditorProps } from "./DeviceCommandEditor";

type EditStreamPortCommandProps = {
} & DeviceCommandEditorProps;

export const EditStreamPortCommand: React.FC<EditStreamPortCommandProps> = (props) => {
    const [, paramsHelper] = useModel(StreamPortCommandParams, (props.command as StreamPortCommand).commandParamsJson);
    const onChange = (newParams: Partial<StreamPortCommandParams>) => {
        props.onChange({ ...props.command, commandParamsJson: newParams } as StreamPortCommand);
    };
    return (
        <>
            <InputRow><EnumSelect label="Command" enumType={PortCommand} {...paramsHelper.bindingsFor("command", onChange)} /></InputRow>
            <InputRow><NumericInput label="Port Number" allowFloat={false} {...paramsHelper.bindingsFor("portNumber", onChange)} /></InputRow>
        </>
    );
}