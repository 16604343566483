import { Facilities, Facility } from "@app/shared";
import { useEditModelDialog } from "../../../hooks/useEditModelDialog";
import { IconLink, Icons, InputRow, LabeledText, PageContainer, SelectTimezone } from "../../shared";
import { ClientEndpointTable } from "./ClientEndpointTable";

type FacilitySettingsProps = {
    facility: Facility;
    onChange: () => void;
};

export const FacilitySettings: React.FC<FacilitySettingsProps> = ({ facility, onChange }) => {
    const facilitySettingsDialog = useEditModelDialog(Facility);

    const handleEditSettingsClick = () => {
        facilitySettingsDialog.show(async (f) => {
            await Facilities.save(f as Facility);
            await onChange();

        }, "Edit Location Settings", facility);
    };

    return (
        <>
            <PageContainer Icon={Icons.Settings} title="Location Settings" titleRight={<IconLink Icon={Icons.Edit} onClick={handleEditSettingsClick}>Edit</IconLink>}>
                <div className="grid grid-cols-2 gap-3">
                    <LabeledText label="Location Timezone">{facility.localTimezoneId}</LabeledText>
                </div>
            </PageContainer>

            <ClientEndpointTable facility={facility} />

            {facilitySettingsDialog.renderDialog((model, helpers) => (
                <>
                    <InputRow>
                        <SelectTimezone label="Location TimeZone" {...helpers.bindingsFor("localTimezoneId")} />
                    </InputRow>
                </>
            ))}
        </>
    );
}