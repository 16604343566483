import { DailyAverageByMonth, IDailyAverageByMonth } from "./Reports.DailyAverageByMonth.gen";
import { DailyAverageByYear, IDailyAverageByYear } from "./Reports.DailyAverageByYear.gen";

export interface IDesignDayVolumes {
	monthlyAverages: IDailyAverageByMonth[] | undefined;

	annualAverages: IDailyAverageByYear[] | undefined;

	$type?: string | undefined;
}
export class DesignDayVolumes {
	constructor(data?: IDesignDayVolumes) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.monthlyAverages = data.monthlyAverages ? data.monthlyAverages.map((m: any) => new DailyAverageByMonth(m)) : [];
		this.annualAverages = data.annualAverages ? data.annualAverages.map((a: any) => new DailyAverageByYear(a)) : [];
	}

	monthlyAverages: DailyAverageByMonth[] | undefined = [];

	annualAverages: DailyAverageByYear[] | undefined = [];

	static $type: string = "CoreApi.Models.Reports.DesignDayVolumes, ";
	$type?: string | undefined = DesignDayVolumes.$type;
}

