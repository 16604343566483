import { Facilities, Facility } from "@app/shared";
import { useEditModelDialog } from "../../../hooks/useEditModelDialog";
import { EditFacilityDetailsInputs, IconLink, Icons, LabeledText, PageContainer } from "../../shared";
import { FacilityTargetsDashboard } from "./FacilityTargetsDashboard";

type FacilityDetailsInfoProps = {
    facility: Facility;
    onChange: () => void;
};
export const FacilityDetailsInfo: React.FC<FacilityDetailsInfoProps> = ({ facility, onChange }) => {
    const facilityDetailsDialog = useEditModelDialog(Facility);

    const handleEditDetailsClick = () => {
        facilityDetailsDialog.show(async (f) => {
            await Facilities.save(f as Facility);
            await onChange();
        }, "Edit Location Details", facility);
    };

    return (
        <>
            <FacilityTargetsDashboard facility={facility} />
            <PageContainer Icon={Icons.Details} title="Facility Details" titleRight={<IconLink Icon={Icons.Edit} onClick={handleEditDetailsClick}>Edit</IconLink>}>
                <div className="grid grid-cols-2 gap-3">
                    <LabeledText label="Name">{facility.name}</LabeledText>
                    <LabeledText label="Client Location Code">{facility.locationCode}</LabeledText>
                    <LabeledText className="col-start-1" label="Address">{facility.address1}</LabeledText>
                    <LabeledText label="Address Line 2">{facility.address2}</LabeledText>
                    <LabeledText label="City">{facility.city}</LabeledText>
                    <LabeledText label="State">{facility.state}</LabeledText>
                    <LabeledText label="Zipcode">{facility.zip}</LabeledText>
                </div>
            </PageContainer>

            {facilityDetailsDialog.renderDialog((model, helpers) => (
                <EditFacilityDetailsInputs model={model} helpers={helpers} />
            ))}

        </>
    );
}