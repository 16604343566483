export const sortArray = <T>(arr: T[] | undefined, sortKey: keyof T, direction?: "asc" | "desc" | "up" | "down" | "ascending" | "descending") => {
    const isAscending = !direction || direction === "asc" || direction === "up" || direction === "ascending";

    return arr?.sort((a, b) => {
        const aVal = a[sortKey];
        const bVal = b[sortKey];

        if (aVal > bVal) {
            return isAscending ? 1 : -1;
        } else if (aVal < bVal) {
            return isAscending ? -1 : 1;
        }
        return 0;
    })
}