import { CustomClaimValue, Device, DeviceType, OperationMode, SensorConfiguration } from "@app/shared";
import { ModelHelpers } from "../../hooks";
import { AuthorizeView } from "./AuthorizeView";
import { EditSensorConfig } from "./EditSensorConfig";
import { HeaderWithSeparator } from "./HeaderWithSeparator";
import { CheckboxInput, EnumSelect, Input, InputRow, NumericInput } from "./inputs";

type EditDeviceDetailsProps = {
    device: Partial<Device>;
    helpers: ModelHelpers<Device>;
    section: 'general' | 'network' | 'radar' | 'all';
};

export const EditDeviceDetails: React.FC<EditDeviceDetailsProps> = ({ device, helpers, section }) => {
    return (<>
        {(section === 'general' || section === 'all') &&
            <>
                <HeaderWithSeparator>General</HeaderWithSeparator>
                <InputRow><Input label="Name" {...helpers.bindingsFor("name")} /></InputRow>
                <InputRow><Input label="Description" {...helpers.bindingsFor("description")} /></InputRow>
                <InputRow><EnumSelect enumType={DeviceType} label="Device Type" {...helpers.bindingsFor("type")} /></InputRow>
                <InputRow><EnumSelect enumType={OperationMode} label="Operation Mode" {...helpers.bindingsFor("mode")} /></InputRow>
                <InputRow><CheckboxInput label="Take Target Photos" checked={device.takeTargetPhotos} onChange={(e) => helpers.updateModel("takeTargetPhotos", e.target.checked)} /></InputRow>
                <InputRow><CheckboxInput label="Always Stream Video" checked={device.alwaysStreamVideo} onChange={(e) => helpers.updateModel("alwaysStreamVideo", e.target.checked)} /></InputRow>
                {device.facilityId &&
                    <>
                        <HeaderWithSeparator>Lot Location</HeaderWithSeparator>
                        <InputRow>
                            <div><Input label="X Origin Offset (m)" {...helpers.bindingsFor("positionX")} /></div>
                            <div><Input label="Y Origin Offset (m)" {...helpers.bindingsFor("positionY")} /></div>
                        </InputRow>
                        <InputRow><Input label="Heading (deg)" {...helpers.bindingsFor("headingDegrees")} /></InputRow>
                        <InputRow><Input label="Desired Tilt (deg)" {...helpers.bindingsFor("desiredTiltDegrees")} /></InputRow>
                        <InputRow><Input label="Height (m)" {...helpers.bindingsFor("height")} /></InputRow>
                    </>
                }
                {!device.facilityId &&
                    <>
                        <HeaderWithSeparator disabled>Lot Location (Device must be assigned to Location)</HeaderWithSeparator>
                        <InputRow>
                            <div><Input disabled label="X Origin Offset (m)" {...helpers.bindingsFor("positionX")} /></div>
                            <div><Input disabled label="Y Origin Offset (m)" {...helpers.bindingsFor("positionY")} /></div>
                        </InputRow>
                        <InputRow><Input disabled label="Heading (deg)" {...helpers.bindingsFor("headingDegrees")} /></InputRow>
                        <InputRow><Input disabled label="Desired Tilt (deg)" {...helpers.bindingsFor("desiredTiltDegrees")} /></InputRow>
                        <InputRow><Input disabled label="Height (m)" {...helpers.bindingsFor("height")} /></InputRow>
                    </>
                }
            </>}

        {(section === 'network' || section === 'all') &&
            <>
                <HeaderWithSeparator>Network</HeaderWithSeparator>
                <InputRow><CheckboxInput label="Enable DHCP" checked={device.enableDhcp} onChange={(e) => helpers.updateModel("enableDhcp", e.target.checked)} /></InputRow>
                <InputRow><Input label="IP Address" {...helpers.bindingsFor("ipv4Address")} disabled={device.enableDhcp} /></InputRow>
                <InputRow><Input label="Gateway Addresses" {...helpers.bindingsFor("gatewayAddresses")} disabled={device.enableDhcp} /></InputRow>
                <InputRow><Input label="DNS Servers" {...helpers.bindingsFor("dnsServers")} disabled={device.enableDhcp} /></InputRow>
                <InputRow><NumericInput label="Heartbeat Frequency (seconds)" allowFloat={false} {...helpers.bindingsFor("secondsBetweenHeartbeat")} /></InputRow>
                <InputRow><Input label="NTP Servers" {...helpers.bindingsFor("ntpServers")} /></InputRow>
            </>
        }
        {(section === 'radar' || section === 'all') &&
            <AuthorizeView claim={CustomClaimValue.SuperAdmin}>
                {device.sensorConfig &&
                    <EditSensorConfig config={device.sensorConfig} onChange={(newConfig) => helpers.updateModel("sensorConfig", newConfig as SensorConfiguration)} />
                }
            </AuthorizeView>
        }
    </>);
}