import { DetectionZone, Vector2 } from "../models";

declare module "../models/DetectionZone.gen" {
    interface DetectionZone {
        clampPointIfClosing(point: Vector2, threshold: number): Vector2;
        isComplete(): boolean;
    }
}

DetectionZone.prototype.isComplete = function () {
    const numPoints = this.points?.length ?? 0;
    return (numPoints > 2 && this.points![0].x === this.points![numPoints - 1].x && this.points![0].y === this.points![numPoints - 1].y);
}

DetectionZone.prototype.clampPointIfClosing = function (point: Vector2, threshold: number) {
    var isAPolygon = (this.points?.length ?? 0) > 2;
    if (!isAPolygon) { return point; }

    var isCloseToStartingPoint = this.points![0].getDistanceTo(point) < threshold;
    if (!isCloseToStartingPoint) { return point; }

    return this.points![0];
}
