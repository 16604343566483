type QueryValues = { [key: string]: string };
export type ParamValues = { [key: string]: any } | undefined;
type OptionalArgument<T> = T extends undefined ? [] : [T];
export type RequiredArguments<T> = {
    [P in keyof T]-?: boolean;
};
export type PageComponent = React.ComponentType<any>;
export class Page<T extends ParamValues = undefined> {
    page: React.ComponentType<any>;
    path: string;
    requiredParams?: RequiredArguments<T>;

    get routeUrl(): string {
        var retVal = this.path;
        if (this.requiredParams) {
            for (var k in this.requiredParams) {
                //If the path doesn't have this param, append it
                if (this.path.indexOf(`:${k}`) < 0)
                    retVal += `/:${k}${!this.requiredParams[k] ? '' : ''}`;
            }
        }
        return retVal;
    };

    getRouteValues(matches: QueryValues) {
        var ret: { [P in keyof T]?: string } = {};
        if (this.requiredParams) {
            for (var k in this.requiredParams) {
                if (matches[k])
                    ret[k] = matches[k];
            }
        }
        return ret;

    }

    getUrlWithQuery(val: ParamValues, queryVals: QueryValues | undefined): string {
        var retVal = this.path;
        for (var k in this.requiredParams) {
            if (val && val[k]) {
                if (retVal.indexOf(`:{k}`) >= 0) {
                    retVal = retVal.replace(`:{k}`, encodeURI(val[k]));
                } else {
                    retVal += `/${encodeURI(val[k])}`;
                }
            } else if (this.requiredParams[k]) {
                console.warn(`Values for route do not include required url parameter: ${k}`);
            }
        }

        var queryString = "";

        if (queryVals) {
            var isFirst = true;
            for (var q in queryVals) {
                if (!isFirst)
                    queryString += "&";
                isFirst = false;
                queryString += q + "=" + encodeURI(queryVals[q]);
            }
        }

        if (queryString.length > 0)
            retVal += `?${queryString}`;

        return retVal;
    }

    getUrl(...vals: OptionalArgument<T>): string {
        var val = vals[0];
        return this.getUrlWithQuery(val, undefined);
    }

    constructor(page: PageComponent, path: string, paramTypes?: RequiredArguments<T>) {
        this.page = page;
        this.path = path;
        this.requiredParams = paramTypes;
    }
}
