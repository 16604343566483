import { Facility, ScreenLine, Vector2 } from "@app/shared";
import { Input, InputRow, NumericInput } from "../../inputs";
import { useFacilityCoordsHelper } from "../../../../helpers";

type ScreenLineConfigProps = {
    screenLine: ScreenLine;
    facility: Facility,
    onChange: (screenLine: ScreenLine) => void;
    isEditable?: boolean;
    isCollapsed: boolean;
};

export const ScreenLineConfig: React.FC<ScreenLineConfigProps> = ({ facility, screenLine, onChange, isCollapsed, isEditable = false }) => {
    const coordsHelper = useFacilityCoordsHelper(facility);
    const handleOnChange = (key: keyof ScreenLine, value: ScreenLine[keyof ScreenLine]) => {
        onChange(new ScreenLine({ ...screenLine, [key]: value }));
    };

    const handleOnChangePoint = (ind: "start" | "end", key: keyof google.maps.LatLngLiteral, value: undefined | Vector2[keyof Vector2]) => {
        if ("start" === ind) {
            const start = coordsHelper.fromLatLng(new google.maps.LatLng({ lat: startLatLng?.lat() ?? 0, lng: startLatLng?.lng() ?? 0, [key]: value }));//startLatLngscreenLine.points![0];
            if (start.x !== 0 && start.y !== 0) {
                onChange(Object.assign(new ScreenLine(), { ...screenLine, points: [new Vector2({ ...start, [key]: value }), screenLine.points![1]] }));
            }
        } else {
            const end = coordsHelper.fromLatLng(new google.maps.LatLng({ lat: endLatLng?.lat() ?? 0, lng: endLatLng?.lng() ?? 0, [key]: value }));//startLatLngscreenLine.points![0];
            if (end.x !== 0 && end.y !== 0) {
                onChange(Object.assign(new ScreenLine(), { ...screenLine, points: [screenLine.points![0], new Vector2({ ...end, [key]: value })] }));
            }
        }
    }
    const startLatLng = screenLine.points?.[0] ? coordsHelper.toLatLng(screenLine.points?.[0]) : null;
    const endLatLng = screenLine.points?.[1] ? coordsHelper.toLatLng(screenLine.points?.[1]) : null;


    return isCollapsed ? null : (
        <>
            <InputRow>
                <Input label="Name" onChange={(e) => handleOnChange("name", e.target.value)} value={screenLine.name} disabled={!isEditable} />
            </InputRow>
            <InputRow>
                <div className="grid grid-cols-2 gap-5">
                    <NumericInput label="Inbound Direction" onChange={(e) => handleOnChange("inboundDirection", e)} value={screenLine.inboundDirection} disabled={!isEditable} />
                    <NumericInput label="Outbound Direction" onChange={(e) => handleOnChange("outboundDirection", e)} value={screenLine.outboundDirection} disabled={!isEditable} />
                    <NumericInput label="Start Longitude" onChange={(e) => handleOnChangePoint("start", "lng", e)} value={startLatLng?.lng()} disabled={!isEditable} />
                    <NumericInput label="Start Latitude" onChange={(e) => handleOnChangePoint("start", "lat", e)} value={startLatLng?.lat()} disabled={!isEditable} />
                    <NumericInput label="End Longitude" onChange={(e) => handleOnChangePoint("end", "lng", e)} value={endLatLng?.lng()} disabled={!isEditable} />
                    <NumericInput label="End Latitude" onChange={(e) => handleOnChangePoint("end", "lat", e)} value={endLatLng?.lat()} disabled={!isEditable} />
                </div>
            </InputRow>

        </>
    );
}