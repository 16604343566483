import { useMemo } from 'react';
import { ChartSizeProps } from '../shared/charts';
import { AxisScaleOutput, TickRendererProps } from "@visx/axis";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { ScaleConfig, scaleOrdinal } from "@visx/scale";
import { ScaleOrdinal } from "d3-scale";
import { Text } from "@visx/text";
import { Axis, BarSeries, BarStack, Grid, Tooltip, XYChart, EventHandlerParams, BarGroup, lightTheme } from "@visx/xychart";
import { extLightThemeColors } from '../../styles/chartThemeColors';


export type OptionallyStackedBarChartProps<TData extends {}> = {
    id?: string;
    title?: string | ((data: any) => string);
    subTitle?: string | ((data: any) => string);
    data: BarStackMapped<TData>[];
    barGroupKey?: keyof TData;
    barStackKey?: keyof TData;
    stackOrGroupLabel?: (stackKeyVal: number | symbol | string) => string,
    toolTip?: (datum: BarStackMapped<TData>, colorScale: ScaleOrdinal<string, any, any>, stackKey?: string | number | symbol) => JSX.Element,
    xKey: keyof TData;
    xTickCount?: number;
    xTickLabeler?: (props: TickRendererProps) => React.ReactNode | undefined;//(formattedValue: string | undefined, tickProps: any) => JSX.Element;
    yKey: keyof TData;
    isLoading?: boolean;
    onClick?: (datum: BarStackMapped<TData>, stackKey?: string | number | symbol) => void;
    size: ChartSizeProps;
}

function defaultTickLabeler(props: TickRendererProps) {
    const { formattedValue, ...tickProps } = props;
    return (<>
        {formattedValue?.split("|").map((str: string, ind: number) => <Text key={str} {...tickProps} dy={ind > 0 ? 15 : 0}>{str}</Text>)}
    </>);
}

export type BarStackMapped<T> = T & { $$BarStackMap: { [key: string]: number } };
const GroupStackBarChart = <TData extends {}>(props: OptionallyStackedBarChartProps<TData>) => {
    const { data = [] as BarStackMapped<TData>[], xKey, yKey, barGroupKey, barStackKey } = props;
    let { width = 50, height = 600, margin } = props.size;
    if (!!!margin || margin?.top === undefined) {
        margin = { top: 16, right: 25, bottom: 30, left: 50 };
        margin.top += props.title ? 24 : 0;
        margin.top += props.subTitle ? 24 : 0;
    }
    if ((barStackKey || barGroupKey) && !props.stackOrGroupLabel)
        throw new Error('stackOrGroupLabel function must be provided when providing stack or group key');

    function xAccessor(datum: TData) {
        return datum[xKey];
    }
    function getStackVal(stackKeyVal: string, datum: BarStackMapped<TData>) {
        return stackKeyVal !== undefined && stackKeyVal !== null ? datum.$$BarStackMap[stackKeyVal] : 0;
    }
    function yAccessor(stackKeyVal: string) {
        if (barStackKey)
            return (te: BarStackMapped<TData>) => getStackVal(stackKeyVal, te);
        return (te: BarStackMapped<TData>) => te[yKey];
    }

    const yScale = { type: 'linear', nice: true } as ScaleConfig<AxisScaleOutput, any, any>;
    const xScale = { type: 'band', paddingInner: .3 } as ScaleConfig<AxisScaleOutput, any, any>;

    const uniqueStackValues = useMemo(() => barStackKey ? Array.from(new Set(data.flatMap((d) => Object.keys(d.$$BarStackMap)))) : ['any'], [data, barStackKey]);
    const uniqueXValues = useMemo(() => Array.from(new Set(data.map((d) => d[xKey]))), [data]);

    const stackScale = useMemo(() => scaleOrdinal<string>({
        domain: [...uniqueStackValues!],
        range: [...extLightThemeColors]
    }), [uniqueStackValues]);

    function getColorFunc(key: string, scale: typeof stackScale) {
        return (d: BarStackMapped<TData>, i: number) => {
            return scale(key) as string | null;
        };
    }
    const chartHeight = height - (barStackKey ? margin.top : 0);

    const handleClick = (event: EventHandlerParams<BarStackMapped<TData>>) => {
        if (props.onClick && event.datum) props.onClick(event.datum, event.key);
    };
    type GroupedData = {
        [group: string]: BarStackMapped<TData>[];
    };
    const groups = useMemo(() => props.barGroupKey ? data.reduce((acc: GroupedData, curr: BarStackMapped<TData>) => {
        const group = curr[props.barGroupKey!] as unknown as string;
        if (!acc[group]) {
            acc[group] = [];
        }
        acc[group].push(curr);
        return acc;
    }, {}) : {}, [data, props.barGroupKey]);

    const groupScale = useMemo(() => scaleOrdinal<string>({
        domain: [...Object.keys(groups)!],
        range: [...extLightThemeColors]
    }), [groups]);
    const theme = { ...lightTheme, colors: extLightThemeColors }
    return width < 10 ? null : (<>
        <XYChart height={chartHeight} width={width} xScale={xScale} yScale={yScale} margin={margin} captureEvents onPointerUp={handleClick} theme={theme}>
            {props.title && <Text x={width / 2} y={16} textAnchor="middle" fontSize={20} fontWeight="bold" >{typeof props.title === 'function' ? props.title(props.data) : props.title}</Text>}
            {props.subTitle && <Text x={width / 2} y={props.title ? 40 : 16} textAnchor="middle" fontSize={16} fontWeight="bold" >{typeof props.subTitle === 'function' ? props.subTitle(props.data) : props.subTitle}</Text>}
            <Axis orientation="bottom" numTicks={props.xTickCount ?? uniqueXValues.length} tickComponent={props.xTickLabeler ?? defaultTickLabeler} />
            <Axis orientation="left" numTicks={15} />
            <Grid columns={false} numTicks={5} />
            {props.barGroupKey ? <BarGroup>
                {Object.keys(groups).map(barGroup => (
                    <BarSeries key={barGroup + ''} barPadding={.5} dataKey={barGroup + ''} data={data.filter(d => (d as any)[barGroupKey] + '' === barGroup)} xAccessor={xAccessor} yAccessor={yAccessor(barGroup as any)} colorAccessor={getColorFunc(barGroup, groupScale)} />
                ))}
            </BarGroup> :
                <BarStack>
                    {
                        uniqueStackValues!.map(stackVal => (
                            <BarSeries key={stackVal + ''} barPadding={.5} dataKey={stackVal + ''} data={data} xAccessor={xAccessor} yAccessor={yAccessor(stackVal as any)} colorAccessor={getColorFunc(stackVal, stackScale)} />
                        ))
                    }
                </BarStack>
            }
            <Tooltip<BarStackMapped<TData>>
                snapTooltipToDatumX
                snapTooltipToDatumY
                //showVerticalCrosshair
                showSeriesGlyphs
                renderTooltip={({ tooltipData, colorScale }) => (
                    props.toolTip && tooltipData?.nearestDatum && props.toolTip(tooltipData.nearestDatum.datum, colorScale!, tooltipData.nearestDatum.key)
                )}
            />
        </XYChart>
        {barStackKey && <StackOrGroupLegend {...props} scale={stackScale!} />}
        {barGroupKey && <StackOrGroupLegend {...props} scale={groupScale!} />}
    </>);
}

const StackOrGroupLegend = <TData extends {}>(props: OptionallyStackedBarChartProps<TData> & { scale: ScaleOrdinal<string, any, never> }) => {
    const legendGlyphSize = 15;
    return (<div
        style={{
            top: (props.size.margin?.top ?? 20) / 2 - 10,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '14px',
        }}
    >
        <LegendOrdinal scale={props.scale} direction="row" labelMargin="0 15px 0 0">
            {labels => (
                <div className="flex">
                    {labels.map((label, i) => (
                        <LegendItem key={`legend-${i}`} className="cursor-pointer" >
                            <svg width={legendGlyphSize} height={legendGlyphSize}>
                                <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                            </svg>
                            <LegendLabel align="left" margin="0 20px 0 8px">
                                {props.stackOrGroupLabel!(label.datum) ?? '*[BAD DATA]'}
                            </LegendLabel>
                        </LegendItem>
                    ))}
                </div>
            )}
        </LegendOrdinal>
    </div>);
}

export const OptionallyStackedBarChart = GroupStackBarChart as <T extends {}>(props: OptionallyStackedBarChartProps<T>) => JSX.Element;