export enum FirmwareType {
	Device = 0,
	Sensor = 1,
}

export const FirmwareTypeDisplayNames: { [key in keyof typeof FirmwareType]?: string} = {
	"Device": "Device",
	0 : "Device",
	"Sensor": "Sensor",
	1 : "Sensor",
}

export const getFirmwareTypeDisplayName = (key: FirmwareType | (keyof typeof FirmwareType & string)) => {
	return FirmwareTypeDisplayNames[key];
}

