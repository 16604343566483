import { SensorConfigType } from "./Shared.SensorConfigType.gen";

export interface IRadarConfiguration {
	transmitterEnabled: boolean;

	secondsToTransmitPerMinute: number;

	minimumSecondsToTransmit: number;

	calibrationConfig: string | undefined;

	chirpConfig: string | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	configType: SensorConfigType;

	$type?: string | undefined;
}
export class RadarConfiguration {
	constructor(data?: IRadarConfiguration) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	transmitterEnabled: boolean = false;

	secondsToTransmitPerMinute: number = 0;

	minimumSecondsToTransmit: number = 0;

	calibrationConfig: string | undefined;

	chirpConfig: string | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	configType: SensorConfigType = 0;

	static $type: string = "CoreApi.Models.RadarConfiguration, ";
	$type?: string | undefined = RadarConfiguration.$type;
}

