/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post } from "./api_client";
import { NewUserInvite, INewUserInvite, NewUser, INewUser } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getUserInvite(id?: string): ApiResult<NewUserInvite> {
		const queryParams = [id !== undefined ? `id=${id}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<INewUserInvite>(`/api/userinvite/user-invite${query}`).then(result => result === undefined ? undefined :  new NewUserInvite(result));
}

export async function getUserInvites(orgId?: string, facId?: string): ApiResult<NewUserInvite[]> {
		const queryParams = [orgId !== undefined ? `orgId=${orgId}` : undefined,
			facId !== undefined ? `facId=${facId}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<INewUserInvite[]>(`/api/userinvite/user-invites${query}`).then(result => result === undefined ? undefined : result.map(o => new NewUserInvite(o)));
}

export async function inviteUser(newUserInvite: NewUserInvite): ApiResult<undefined> {
	return post(`/api/userinvite/invite-user`, newUserInvite);
}

export async function resendInviteUser(newUserInvite: NewUserInvite): ApiResult<undefined> {
	return post(`/api/userinvite/resend-invite-user`, newUserInvite);
}

export async function deleteInviteUser(newUserInvite: NewUserInvite): ApiResult<undefined> {
	return post(`/api/userinvite/delete-invite-user`, newUserInvite);
}

export async function acceptInvite(inviteId?: string): ApiResult<undefined> {
	return post(`/api/userinvite/${ inviteId }/accept-invite`);
}

export async function acceptInviteUser(inviteId?: string, newUser?: NewUser): ApiResult<undefined> {
	return post(`/api/userinvite/${ inviteId }/accept-invite-user`, newUser);
}
