export enum TriggerType {
	ScreenLineChange = 0,
	ScreenLineInbound = 1,
	ScreenLineOutbound = 2,
	DetectionZoneEntry = 3,
	DetectionZoneExit = 4,
	DetectionZoneChange = 5,
	TargetDetected = 6,
}

export const TriggerTypeDisplayNames: { [key in keyof typeof TriggerType]?: string} = {
	"ScreenLineChange": "Screen Line Change",
	0 : "Screen Line Change",
	"ScreenLineInbound": "Screen Line Inbound",
	1 : "Screen Line Inbound",
	"ScreenLineOutbound": "Screen Line Outbound",
	2 : "Screen Line Outbound",
	"DetectionZoneEntry": "Detection Zone Entry",
	3 : "Detection Zone Entry",
	"DetectionZoneExit": "Detection Zone Exit",
	4 : "Detection Zone Exit",
	"DetectionZoneChange": "Detection Zone CHange",
	5 : "Detection Zone CHange",
	"TargetDetected": "Target Detected",
	6 : "Target Detected",
}

export const getTriggerTypeDisplayName = (key: TriggerType | (keyof typeof TriggerType & string)) => {
	return TriggerTypeDisplayNames[key];
}

