import { CustomClaimValue, Devices } from "@app/shared";
import { useFetch } from "../../../hooks";
import { DevicesTable } from "../../shared";
import { AuthorizeView } from "../../shared/AuthorizeView";


type GlobalDevicesProps = {
};

export const GlobalDevices: React.FC<GlobalDevicesProps> = (props) => {
    const [devices, , devicesHelper] = useFetch(() => Devices.getAllDevices());

    return (
        <AuthorizeView claim={CustomClaimValue.SuperAdmin}>
            <DevicesTable devices={devices} isLoading={devicesHelper.isLoading} onChange={() => devicesHelper.refreshData()} />
        </AuthorizeView>
    );
}