import { Device, Facility, Vector2 } from "@app/shared";
import { Input, InputLabel, InputRow, NumericInput } from "../../inputs";
import { ColorPicker } from "../ColorPicker";
import { useFacilityCoordsHelper } from "../../../../helpers";

type FovDeviceConfigProps = {
    facility: Facility;
    device: Device;
    availableDevices: Device[];
    onChange: (device: Device) => void;
    isCollapsed: boolean;
    isEditable?: boolean;
};

export const FovDeviceConfig: React.FC<FovDeviceConfigProps> = ({ facility, device, availableDevices, onChange, isCollapsed, isEditable = false }) => {
    const coordsHelper = useFacilityCoordsHelper(facility);

    const handleNewPos = (key: | 'lat' | 'lng', value: number | undefined) => {
        if (typeof value !== 'number') { return; }

        const curr = { lat: deviceLatLng.lat(), lng: deviceLatLng.lng() };
        (curr as any)[key] = value;
        const v2Pos = coordsHelper.fromLatLng(new google.maps.LatLng(curr));
        onChange(new Device({ ...device, positionX: v2Pos.x, positionY: v2Pos.y } as any));
    }
    const handleOnChange = (key: keyof Device, value: Device[keyof Device]) => {
        const dev = new Device({ ...device, [key]: value } as any);
        onChange(dev);
    }
    const deviceLatLng = coordsHelper.toLatLng(new Vector2({ x: device.positionX, y: device.positionY }));

    return isCollapsed ? null : (
        <>
            <InputRow>
                <InputLabel>Color</InputLabel>
                <div className="flex place-items-center">
                    <ColorPicker value={device.color ?? ""} onChange={(newColor) => handleOnChange("color", newColor)} disabled={!isEditable} />
                    <div className="flex-grow ml-5">
                        <Input value={device.color ?? ""} onChange={(e) => handleOnChange("color", e.target.value)} disabled={!isEditable} />
                    </div>
                </div>
            </InputRow>
            <InputRow>
                <div className="grid grid-cols-2 gap-5">
                    <NumericInput label="Longitude" value={deviceLatLng.lng()} onChange={(e) => handleNewPos("lng", e)} disabled={!isEditable} />
                    <NumericInput label="Latitude" value={deviceLatLng.lat()} onChange={(e) => handleNewPos("lat", e)} disabled={!isEditable} />
                    <NumericInput label="Tilt" value={device.desiredTiltDegrees} onChange={(e) => handleOnChange("desiredTiltDegrees", e)} disabled={!isEditable} />
                    <NumericInput label="Heading &deg;" value={device.headingDegrees} onChange={(e) => handleOnChange("headingDegrees", e)} disabled={!isEditable} />
                    <NumericInput label="Height" value={device.height} onChange={(e) => handleOnChange("height", e)} disabled={!isEditable} />
                </div>
            </InputRow>
        </>
    );
}