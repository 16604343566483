/* eslint-disable @typescript-eslint/no-unused-vars */
import { Compare } from './validators';
import { IsEmail, IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface INewUserWithOrganization {
	organizationName: string;

	organizationAddress: string | undefined;

	organizationAddress2: string | undefined;

	organizationCity: string | undefined;

	organizationState: string | undefined;

	organizationZipcode: string | undefined;

	organizationPhoneNumber: string | undefined;

	emailAddress: string;

	firstName: string;

	lastName: string;

	password: string;

	confirmPassword: string;

	$type?: string | undefined;
}
export class NewUserWithOrganization {
	constructor(data?: INewUserWithOrganization) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	@IsNotEmpty()
	organizationName: string = "";

	organizationAddress: string | undefined;

	organizationAddress2: string | undefined;

	organizationCity: string | undefined;

	organizationState: string | undefined;

	organizationZipcode: string | undefined;

	organizationPhoneNumber: string | undefined;

	@IsNotEmpty()
	@IsEmail({}, {
	  "message": "The $property field is not a valid e-mail address."
	})
	emailAddress: string = "";

	@IsNotEmpty()
	firstName: string = "";

	@IsNotEmpty()
	lastName: string = "";

	@IsNotEmpty()
	password: string = "";

	@IsNotEmpty()
	@Compare("password", {
	  "message": "The passwords do not match."
	})
	confirmPassword: string = "";

	static $type: string = "CoreApi.Models.View.NewUserWithOrganization, ";
	$type?: string | undefined = NewUserWithOrganization.$type;
}

