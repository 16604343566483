export enum DeviceType {
	MegaRadar = 0,
	PicoRadar = 1,
}

export const DeviceTypeDisplayNames: { [key in keyof typeof DeviceType]?: string} = {
	"MegaRadar": "MegaRadar",
	0 : "MegaRadar",
	"PicoRadar": "PicoRadar",
	1 : "PicoRadar",
}

export const getDeviceTypeDisplayName = (key: DeviceType | (keyof typeof DeviceType & string)) => {
	return DeviceTypeDisplayNames[key];
}

