import { Guid, IGuid } from "./System.Guid.gen";
import { UserRole, IUserRole } from "./UserRole.gen";
import { AcceptedTerms, IAcceptedTerms } from "./AcceptedTerms.gen";

export interface IOmniUser {
	id: string | undefined;

	userName: string | undefined;

	email: string | undefined;

	authToken: string | undefined;

	firstName: string | undefined;

	lastName: string | undefined;

	name: string | undefined;

	roles: IUserRole[] | undefined;

	terms: IAcceptedTerms[] | undefined;

	lastViewedOrganizationId: string | undefined;

	$type?: string | undefined;
}
export class OmniUser {
	constructor(data?: IOmniUser) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.roles = data.roles ? data.roles.map((r: any) => new UserRole(r)) : [];
		this.terms = data.terms ? data.terms.map((t: any) => new AcceptedTerms(t)) : [];
	}

	id: string | undefined;

	userName: string | undefined;

	email: string | undefined;

	authToken: string | undefined;

	firstName: string | undefined;

	lastName: string | undefined;

	name: string | undefined;

	roles: UserRole[] | undefined = [];

	terms: AcceptedTerms[] | undefined = [];

	lastViewedOrganizationId: string | undefined;

	static $type: string = "CoreApi.Models.OmniUser, ";
	$type?: string | undefined = OmniUser.$type;
}

