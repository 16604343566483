import { CustomClaimValue, Device, DeviceCommand, DeviceCommands, Devices } from "@app/shared";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Pages } from "..";
import { toastSuccess } from "../../../helpers/toastHelpers";
import { useDialog, useFetch } from "../../../hooks";
import { useEditModelDialog } from "../../../hooks/useEditModelDialog";
import { TabbedDashLayout } from "../../layouts/TabbedDashLayout";
import { ReportsView } from '../../reports/ReportsView';
import { DeviceBoundaries, DeviceHomography, DeviceStatusView, DeviceVideoStream, EditDeviceCommand, EditDeviceDetails, IconLink, Icons, Link } from "../../shared";
import { AuthorizeView } from "../../shared/AuthorizeView";
import { DeviceNotesView } from "../../shared/DeviceNotesView";
import { DropDownMenu } from "../../shared/DropDownMenu";
import { DeviceCommandTypes } from "../../shared/EditDeviceCommand/DeviceCommandEditor";

export const DeviceDetails: FC = (props) => {
    const { id } = useParams();
    const [device, errors, fetchHelpers] = useFetch(() => Devices.getById(id));
    const navigate = useNavigate();

    const deviceDialog = useEditModelDialog(Device);
    const sendCommandDialog = useDialog<Device>();
    const [commandToSend, setCommandToSend] = useState<DeviceCommandTypes>();
    const commandToSendRef = useRef(commandToSend);

    //Keep a ref to the commandToSend for our callback
    useEffect(() => {
        commandToSendRef.current = commandToSend;
    }, [commandToSend]);

    //Check for not-found devices (permissions usually)
    useEffect(() => {
        if (!device && fetchHelpers.isLoading == false && errors) {
            console.error("Error loading device", errors);
            navigate(Pages.Devices.getUrl());
        }
    }, [errors])

    const handleEditOnClick = (device: Device) => {
        deviceDialog.show(
            async (device) => {
                await Devices.save(device as Device);
                fetchHelpers.refreshData();
            },
            "Edit Device",
            device
        );
    };

    const handleRequestSshClick = async (device: Device) => {
        await Devices.requestSshAccess(device.id);
        toastSuccess("Submitted", "An ssh request has been submitted.");
    }

    const handleSendCommandClick = (device: Device) => {
        setCommandToSend(undefined);
        sendCommandDialog.show("Send Device Command", "Send", device, async () => {
            await DeviceCommands.sendCommand(commandToSendRef.current as DeviceCommand);
            toastSuccess("Command Created", "A new command is pending for this device.");
            fetchHelpers.refreshData();
        });
    }

    const NotesTitle = useMemo(() => {
        const notes = device?.notes?.filter(n => !n.isDeleted);
        if (!!!notes?.length) return 'Notes';
        return (<><span>Notes</span><div className="w-5 h-5 ml-1 rounded-full bg-brand-primary flex items-center justify-center text-bright-text text-xs font-bold mt-px">{notes.length}</div></>);
    }, [device?.notes]);

    return !!!device ? null : (
        <>
            <TabbedDashLayout
                leftChildren={
                    <>
                        {(device && device.facilityId) &&
                            <Link onClick={() => { navigate(Pages.FacilityDetails.getUrl({ id: device!.facilityId! })) }}>{device?.facilityName}</Link>
                        }
                    </>
                }
                tabs={[
                    { title: "Status", child: <DeviceStatusView device={device} onChange={fetchHelpers.refreshData} /> },
                    { title: "Camera", child: <DeviceVideoStream device={device} /> },
                    { title: "Boundary", child: <DeviceBoundaries device={device} onChange={fetchHelpers.refreshData} /> },
                    { title: "Homography", child: <DeviceHomography device={device} onChange={fetchHelpers.refreshData} /> },
                    { title: "Reports", child: <ReportsView device={device} onChange={fetchHelpers.refreshData} /> },
                    { title: NotesTitle, requiredClaim: CustomClaimValue.SuperAdmin, child: <DeviceNotesView device={device} onChange={fetchHelpers.refreshData} /> },
                    // { title: "Visualizer", child: <DeviceVisualizer device={device} /> }
                ]}
                centerChildren={device?.name ?? "Loading..."}
                rightChildren={
                    <DropDownMenu toggleChild={<Icons.Settings className="w-7 h-7" />} >
                        <IconLink Icon={Icons.Edit} onClick={() => handleEditOnClick(device!)} >Edit</IconLink>
                        <IconLink Icon={Icons.Send} onClick={() => handleSendCommandClick(device!)}>Send Command</IconLink>
                        <AuthorizeView claim={CustomClaimValue.SuperAdmin}>
                            <IconLink Icon={Icons.Send} onClick={() => handleRequestSshClick(device!)}>Open SSH Request</IconLink>
                        </AuthorizeView>

                    </DropDownMenu>}
            />
            {deviceDialog.renderDialog((model, helpers) => (
                <EditDeviceDetails device={model} helpers={helpers} section="all" />
            ))}
            {sendCommandDialog.renderDialog((device) => (
                <EditDeviceCommand command={commandToSend} deviceId={device?.id ?? ""} onChange={(command) => setCommandToSend(command)} />
            ), { closeOnOutsideClick: false })}
        </>
    );
}
