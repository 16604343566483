import { useAuth } from "@app/shared";
import { FC } from "react";
import { DashboardLayout } from "../../layouts";
import { IconLink, Icons, LabeledText, PageContainer } from "../../shared";
import { DropDownMenu } from "../../shared/DropDownMenu";

export const AccountDetails: FC = (props) => {
    const auth = useAuth();

    return (
        <DashboardLayout centerChildren="Account Details">
            <PageContainer title="Profile Information" titleRight={
                <DropDownMenu>
                    <IconLink Icon={Icons.Edit}>Edit Profile</IconLink>
                    <IconLink iconClassName="reset-icon" Icon={Icons.Reset}>Change Password</IconLink>
                </DropDownMenu>
            }>
                <LabeledText label="Name">{auth.user?.name}</LabeledText>
                <LabeledText label="Email">{auth.user?.email}</LabeledText>
            </PageContainer>
        </DashboardLayout>
    );
}