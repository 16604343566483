import Chart from "react-apexcharts";
import type { ApexOptions } from "apexcharts";
import { extLightThemeColors } from '../../styles/chartThemeColors';
import { useMemo } from "react";

type NumberKeyOf<T> = {
    [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

export type GroupedStackedApexChartProps<TData extends {}> = {
    height?: number;
    /**Ensure data is sorted by xKey */
    hideLegend?: boolean;
    data: Array<TData>;
    xKey: keyof TData;
    yKey: NumberKeyOf<TData>;
    groupKey: keyof TData;
    stackKey: keyof TData;
    title: string;
    subTitle?: string;
};

export function GroupedStackedApexChart<TData extends {}>(props: GroupedStackedApexChartProps<TData>) {
    const { data, hideLegend, height, xKey, yKey, groupKey, stackKey, subTitle, title } = props;

    const stacksInData = useMemo(() => {
        if (!data?.length) { return []; }
        const filteredData = data.filter(x => {
            const yValue = x[yKey];
            return typeof yValue === 'number' && yValue !== 0;
        });
        return Array.from(new Set(filteredData.map(x => x[stackKey])));
    }, [data, stackKey, yKey]);

    const groupsInData = useMemo(() => {
        if (!data?.length) { return []; }
        return Array.from(new Set(data.map(x => x[groupKey])));

    }, [data, groupKey]);

    const xValues = useMemo(() => {
        if (!data?.length) { return []; }
        return Array.from(new Set(data.map(x => x[xKey])));
    }, [data, xKey]);

    const series = useMemo(() => {
        const colorMap = createWrappedMap(stacksInData, extLightThemeColors);

        const r = [];
        for (const stackVal of stacksInData) {
            for (const groupVal of groupsInData) {
                r.push({
                    name: (stackVal ?? "") + '%-%' + groupVal,//need unique name
                    color: colorMap.get(stackVal),
                    group: groupVal as string,
                    data: data.filter(x => x[groupKey] === groupVal && (!!!stackVal || x[stackKey] === stackVal)).map(x => x[yKey]) as Array<number> //data is positional so must be sorted by xKey
                });
                
            }
        }
        return r;

    }, [data, stackKey, groupKey, yKey, stacksInData, groupsInData]);

    var options = useMemo(() => ({
        chart: {
            type: 'bar',
            //height: 350,
            stacked: true,
            toolbar: {
                show: false
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        plotOptions: {
            bar: {
                horizontal: false
            }
        },
        xaxis: {
            categories: xValues
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: stacksInData?.length > 1,
            position: 'top',
            horizontalAlign: 'left',
            floating: true,
            offsetY: -60,
            customLegendItems: stacksInData,
            markers: {
                fillColors: extLightThemeColors
            }
        },
        title: {
            align: 'center',
            text: title
        },
        subtitle: {
            align: 'center',
            text: subTitle
        },
        tooltip: {
            // shared: true,
            // intersect: false,
            // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            //     return '<div class="arrow_box">' +
            //         '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
            //         '</div>'
            // },
            y: {
                title: {
                    formatter: (seriesName) => {
                        if (stacksInData.length === 1) {
                            return seriesName.substring(seriesName.indexOf('%-%') + 3)
                        }
                        return seriesName.substring(0, seriesName.indexOf('%-%'))
                    }
                }
            },
            x: {
                formatter(val, opts) {
                    const xValLabel = xValues[opts.dataPointIndex];
                    const group = series[(opts.seriesIndex as number)].group;
                    return xValLabel + ' ' + group;
                },
            }
        }
    } as ApexOptions), [series, stacksInData, title, subTitle, xValues]);

    if (!series.length || !height) {
        return null;
    }
    
    return (
        <>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height={height ? height - 80 : undefined}
            />
        </>
    );
}

function createWrappedMap<T, U>(array1: T[], array2: U[]): Map<T, U> {
    const map = new Map<T, U>();
    for (let i = 0; i < array1.length; i++) {
        const wrappedIndex = i % array2.length;
        map.set(array1[i], array2[wrappedIndex]);
    }
    return map;
}