export interface IString {
	length: number;

	$type?: string | undefined;
}
export class String {
	constructor(data?: IString) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	length: number = 0;

	static $type: string = "System.String, ";
	$type?: string | undefined = String.$type;
}

