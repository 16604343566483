/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, put, post, del } from "./api_client";
import { DeviceNote, IDeviceNote } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function createNote(deviceId?: string, note?: DeviceNote): ApiResult<DeviceNote> {
	return post<IDeviceNote>(`/api/devicenote/${ deviceId }/notes`, note).then(result => result === undefined ? undefined :  new DeviceNote(result));
}

export async function getNotes(deviceId?: string, includeDeleted?: boolean): ApiResult<DeviceNote[]> {
		const queryParams = [includeDeleted !== undefined ? `includeDeleted=${includeDeleted}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IDeviceNote[]>(`/api/devicenote/${ deviceId }/notes${query}`).then(result => result === undefined ? undefined : result.map(o => new DeviceNote(o)));
}

export async function deleteNote(deviceId?: string, noteId?: string): ApiResult<DeviceNote> {
	return del<IDeviceNote>(`/api/devicenote/${ deviceId }/notes/${ noteId }`).then(result => result === undefined ? undefined :  new DeviceNote(result));
}

export async function updateNote(deviceId?: string, noteId?: string, note?: DeviceNote): ApiResult<DeviceNote> {
	return put<IDeviceNote>(`/api/devicenote/${ deviceId }/notes/${ noteId }`, note).then(result => result === undefined ? undefined :  new DeviceNote(result));
}
