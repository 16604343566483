import { Vector2 } from "@app/shared";
import { Matrix, Vector } from 'ts-matrix';

//Simple rewrite of https://github.com/jlouthan/perspective-transform to typescript
export class HomographyHelper {
    srcPts: Array<number>;
    dstPts: Array<number>;
    coeffs: Array<number>;
    coeffsInv: Array<number>;

    constructor(srcPts: Array<number>, dstPts: Array<number>) {

        this.srcPts = srcPts;
        this.dstPts = dstPts;
        this.coeffs = this.getNormalizationCoefficients(this.srcPts, this.dstPts, false);
        this.coeffsInv = this.getNormalizationCoefficients(this.srcPts, this.dstPts, true);

        if (srcPts.length !== 8 || dstPts.length !== 8) {
            console.log('Invalid length for source or destination points, cannot calculate homography.');
            return;
        }

        return this;
    }

    //Dot product of a matrix and vector
    dotMV(x: Matrix, y: Vector) {
        var p = x.rows, i;
        var ret = new Vector();

        for (i = 0; i < p; i++) {
            var row = new Vector(x.values[i]);
            ret.values[i] = y.dot(row);
        }
        return ret;
    }

    getNormalizationCoefficients(srcPts: Array<number>, dstPts: Array<number>, isInverse: boolean) {
        if (isInverse) {
            var tmp = dstPts;
            dstPts = srcPts;
            srcPts = tmp;
        }

        var matA = new Matrix(8, 8,
            [[srcPts[0], srcPts[1], 1, 0, 0, 0, -1 * dstPts[0] * srcPts[0], -1 * dstPts[0] * srcPts[1]],
            [0, 0, 0, srcPts[0], srcPts[1], 1, -1 * dstPts[1] * srcPts[0], -1 * dstPts[1] * srcPts[1]],
            [srcPts[2], srcPts[3], 1, 0, 0, 0, -1 * dstPts[2] * srcPts[2], -1 * dstPts[2] * srcPts[3]],
            [0, 0, 0, srcPts[2], srcPts[3], 1, -1 * dstPts[3] * srcPts[2], -1 * dstPts[3] * srcPts[3]],
            [srcPts[4], srcPts[5], 1, 0, 0, 0, -1 * dstPts[4] * srcPts[4], -1 * dstPts[4] * srcPts[5]],
            [0, 0, 0, srcPts[4], srcPts[5], 1, -1 * dstPts[5] * srcPts[4], -1 * dstPts[5] * srcPts[5]],
            [srcPts[6], srcPts[7], 1, 0, 0, 0, -1 * dstPts[6] * srcPts[6], -1 * dstPts[6] * srcPts[7]],
            [0, 0, 0, srcPts[6], srcPts[7], 1, -1 * dstPts[7] * srcPts[6], -1 * dstPts[7] * srcPts[7]]]);

        var matC = matA.transpose().multiply(matA).inverse();
        var matD = matC.multiply(matA.transpose());
        var matX = this.dotMV(matD, new Vector(dstPts))
        matX.values[8] = 1;
        return matX.values;
    }

    transform(x: number, y: number) {
        var ret = new Vector2();
        ret.x = (this.coeffs[0] * x + this.coeffs[1] * y + this.coeffs[2]) / (this.coeffs[6] * x + this.coeffs[7] * y + 1);
        ret.y = (this.coeffs[3] * x + this.coeffs[4] * y + this.coeffs[5]) / (this.coeffs[6] * x + this.coeffs[7] * y + 1);
        return ret;
    }

    transformInverse(x: number, y: number) {
        var ret = new Vector2();
        ret.x = (this.coeffsInv[0] * x + this.coeffsInv[1] * y + this.coeffsInv[2]) / (this.coeffsInv[6] * x + this.coeffsInv[7] * y + 1);
        ret.y = (this.coeffsInv[3] * x + this.coeffsInv[4] * y + this.coeffsInv[5]) / (this.coeffsInv[6] * x + this.coeffsInv[7] * y + 1);
        return ret;
    }
};