import { Firmwares, FirmwareType, FirmwareUpdateCommand, FirmwareUpdateCommandParams } from "@app/shared";
import { ChangeEventHandler } from "react";
import { useFetch, useModel } from "../../../hooks";
import { CheckboxInput, InputRow, Select } from "../inputs";
import { DeviceCommandEditorProps } from "./DeviceCommandEditor";

type EditFirmwareUpdateCommandProps = {
} & DeviceCommandEditorProps;

export const EditFirmwareUpdateCommand: React.FC<EditFirmwareUpdateCommandProps> = (props) => {
    const [firmwares] = useFetch(() => Firmwares.getAvailable(FirmwareType.Device));
    const [params, paramsHelper] = useModel(FirmwareUpdateCommandParams, (props.command as FirmwareUpdateCommand).commandParamsJson);

    const onChange = (newParams: Partial<FirmwareUpdateCommandParams>) => {
        props.onChange({ ...props.command, commandParamsJson: newParams } as FirmwareUpdateCommand);
    };

    const handleSelectFirmwareOnChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        var firmware = firmwares?.find(f => f.id === e.target.value);
        if (!firmware)
            return;
        onChange({
            firmwareId: firmware.id,
            v: firmware.firmwareVersion,
            hash: firmware.firmwareHash
        } as Partial<FirmwareUpdateCommandParams>);
    };

    return (
        <>
            <InputRow>
                <Select label="Select Firmware"
                    options={firmwares?.map(f => ({ label: f.firmwareVersion, value: f.id }))}
                    emptyOptionDisabled={false}
                    emptyOptionText="--Select Firmware--"
                    onChange={handleSelectFirmwareOnChange} />
            </InputRow>
            <InputRow><CheckboxInput label="Force Update" checked={params.force} onChange={(e) => paramsHelper.updateModel("force", e.target.checked)} /></InputRow>
        </>
    );
}