import { Page } from "@app/shared";
import { AccountDetails } from "./account/Account";
import { CreateAccount } from "./account/CreateAccount";
import { ForgotPassword } from "./account/ForgotPassword";
import { Login } from "./account/Login";
import { ResetPassword } from "./account/ResetPassword";
import { ViewInvite } from "./account/ViewInvite";
import { Devices } from "./Devices";
import { CalibrateDevice } from "./facility/CalibrateDevice";
import { DeviceDemo } from "./facility/DeviceDemo";
import { DeviceDetails } from "./facility/DeviceDetails";
import { FacilityDetails } from "./facility/FacilityDetails";
import { ManageUsers } from "./ManageUsers";
import { AddOrganization } from "./organization/AddOrganization";
import { ManageFirmware } from "./settings/ManageFirmware";
import { Settings } from "./settings/Settings";
import { ViewFacilityGroup } from "./ViewFacilityGroup";
import { ViewFacilityGroups } from "./ViewFacilityGroups";
import { Terms } from "./account/Terms";


export const Pages = {
    Devices: new Page(Devices, "/devices"),
    AccountDetails: new Page(AccountDetails, "/account"),
    AddOrganization: new Page(AddOrganization, "/account/add-organization"),
    ForgotPassword: new Page(ForgotPassword, "/account/forgot-password"),
    CreateAccount: new Page(CreateAccount, "/account/create"),
    Terms: new Page(Terms, "/account/terms"),
    ViewInvite: new Page<{ id: string }>(ViewInvite, "/account/view-invite", { id: true }),
    Login: new Page(Login, "/login"),
    ResetPassword: new Page<{ id: string }>(ResetPassword, "/account/reset-password", { id: true }),
    CalibrateDevice: new Page(CalibrateDevice, "/calibrate-device"),
    DeviceDetails: new Page<{ id: string }>(DeviceDetails, "/device-details", { id: true }),
    DeviceDemo: new Page<{ id: string }>(DeviceDemo, "/device-demo", { id: true }),
    FacilityDetails: new Page<{ id: string }>(FacilityDetails, "/facilities", { id: true }),
    ManageFirmware: new Page(ManageFirmware, "/firmware"),
    Settings: new Page(Settings, "/settings"),
    ViewFacilityGroup: new Page<{ id: string }>(ViewFacilityGroup, "/facilities/group", { id: true }),
    ViewFacilityGroups: new Page(ViewFacilityGroups, "/facilities"),
    Users: new Page(ManageUsers, "/users"),
};
