import { Device, Facility, FacilityGroup } from "@app/shared";
import { ChartSizeProps } from "../shared/charts";
import { SelectedReportConfiguration } from "./ReportComponents";

export type TrafficReportContext = { device?: Device, facility?: Facility, facilityGroup?: FacilityGroup };
export type TrafficReportProps = { size: ChartSizeProps, title?: string | ((data: any) => string), subTitle?: string | ((data: any) => string) };

export enum DateOptions {
    AllData = "All Data",
    ThisYear = "This Year",
    ThisMonth = "This Month",
    LastYear = "Last Year",
    Last90Days = "Last 90 Days",
    LastMonth = "Last Month",
    LastWeek = "Last Week",
    Custom = "Custom"

}
export interface IConfiguredTrafficReport {
    component: React.FC<TrafficReportProps>,
    name: string,
    key: string,
    description: string;
    defaultChartType: 'lines' | 'bars' | 'other',
    defaultRange: DateOptions,
    downloadData?: (args: SelectedReportConfiguration) => void,
    config?: {
        allowsCustomRange?: boolean;
        disallowChartTypeToggling?:boolean;
        requiresMonth?: boolean;
        requiresYear?: boolean;
        allowTargetTypeGrouping: boolean;
        disabled?: boolean;
    }
}