import { OrientationDirection, reOrientXyPoint } from "../helpers";
import { Vector2 } from "../models";

declare module "../models/System.Numerics.Vector2.gen" {
    interface Vector2 {
        getDistanceTo(point: Vector2): number;
        reOrientPoint(origin: Vector2, scale: number, direction: OrientationDirection): Vector2;
    }
}
Vector2.prototype.getDistanceTo = function (point2: Vector2) {
    var dx = point2.x - this.x;
    var dy = point2.y - this.y;

    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
}
Vector2.prototype.reOrientPoint = function (origin: Vector2, scale: number, direction: OrientationDirection) {
    var retVal = reOrientXyPoint(this, origin, scale, direction);
    return new Vector2(retVal);
}

