import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { Reports, TrafficReportConfiguration, TrafficVelocityReport, getTargetTypeDisplayName, rawPost, useAuth } from "@app/shared";
import React, { useMemo, useContext } from "react";
import { useFetch } from "../../hooks";
import { BarStackMapped, OptionallyStackedBarChart } from "./OptionallyStackedBarChart";
import { OptionallySplitLineChart, SeriesDetails } from "./OptionallySplitLineChart";
import { ReportContext, buildLabeler, groupBy, stackedTooltipBuilder, tooltipBuilder } from "./reportHelpers";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";

type SpeedDistribution = { [idx: number]: TrafficVelocityReport[] };
const TargetVelocitiesLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { searchParams, reportConfig } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const [data, error, { isLoading }] = useFetch(() => Reports.targetVelocities(searchParams), [searchParams]);

    const speedDist = useMemo(() => {
        if (isLoading) return {};
        const dist = {} as SpeedDistribution;
        data?.filter(d => d.count > 0).forEach(cv => {
            const rounded = Math.round(cv.avgMaxVelocityMPH!);
            if (!dist[rounded]) {
                dist[rounded] = [];
            }
            dist[rounded].push(cv);
        }, {} as SpeedDistribution);
        return dist;
    }, [data, isLoading]);

    const chartData = useMemo(() => {
        if (isLoading || !data?.length) return [];
        const d = [];
        for (var key in speedDist) {
            d.push({ mph: parseInt(key) + '', count: speedDist[key].length, percentage: speedDist[key].length / data!.filter(d => d.count > 0).length, targetType: -1 });
        }

        if (reportConfig.groupByTargetType) {
            const stack = [] as BarStackMapped<{ mph: string, count: number, percentage: number, targetType: number }>[];
            const rounded = data?.filter(d => d.count > 0).map(d => ({ count: d.count, percentage: 0, tt: d.targetType, mph: Math.round(d.avgMaxVelocityMPH!) }));
            const gMph = groupBy(rounded!, 'mph');

            for (const speed in gMph) {
                const gTT = groupBy(gMph[speed], 'tt');
                const stackMap = {} as { [key: string]: number };
                for (const tt in gTT) {
                    const totalTargets = gTT[tt].reduce((cv, agg) => cv + agg.count, 0);
                    stackMap[tt] = totalTargets;
                }
                stack.push({ mph: speed, $$BarStackMap: stackMap, count: -1, percentage: -1, targetType: -1 })
            }
            return stack;
        }
        return d;
    }, [speedDist, isLoading, data, reportConfig.groupByTargetType]);

    const ttCounts = useMemo(() => {
        const ttMap = {} as { [key: string]: { count: number, } };
        data?.filter(d => d.count > 0).forEach(d => {
            if (!ttMap[d.targetType]) {
                ttMap[d.targetType] = { count: d.count };
            } else {
                ttMap[d.targetType].count += d.count;
            }
        })
        return ttMap;
    }, [data])

    const mphTooltip = tooltipBuilder(
        (str: typeof chartData[0]) => str.mph + ' mph',
        d => Math.round(1000 * d.percentage) / 10 + `% (${d.count}) targets`,
        'Target Speeds');
    const mphTooltipStacked = stackedTooltipBuilder<typeof chartData[0]>(numberFormatter,
        (d, tt) => getTargetTypeDisplayName(tt as any)!,
        (d, tt) => Math.round(1000 * (d.$$BarStackMap[tt] / ttCounts[tt].count)) / 10 + `% (${d.$$BarStackMap[tt]}) targets`,
        str => 'Target Speeds ' + str.mph + ' mph');


    const Report = useMemo(() => {
        if (!data?.length || isLoading) {
            return null;
        }

        const ttWithData = Array.from(new Set(data?.filter(d => d.count > 0).map((d) => d.targetType)));
        const series: SeriesDetails<BarStackMapped<typeof chartData[0]>>[] = ttWithData.map(tt => ({
            accessors: {
                xAccessor: dc => dc?.mph,
                yAccessor: dc => dc?.$$BarStackMap ? dc?.$$BarStackMap[tt] : dc?.count
            },
            dataKey: tt + ''
        }));

        return reportConfig.chartType === 'lines' ?
            (<OptionallySplitLineChart {...props} data={chartData as BarStackMapped<typeof chartData[0]>[]} xKey="mph" yKey="count" series={series as any} lineSplitKey={reportConfig.groupByTargetType ? "targetType" : undefined} xTickCount={24} xTickLabeler={buildLabeler(str => str + ' mph')} toolTip={reportConfig.groupByTargetType ? mphTooltipStacked : mphTooltip} lineSplitLabel={getTargetTypeDisplayName as any} isLoading={isLoading} />)
            : (<OptionallyStackedBarChart {...props} data={chartData as BarStackMapped<typeof chartData[0]>[]} xKey="mph" yKey="count" barStackKey={reportConfig.groupByTargetType ? 'targetType' : undefined} stackOrGroupLabel={getTargetTypeDisplayName as any} xTickLabeler={buildLabeler(str => str + ' mph')} xTickCount={24} toolTip={reportConfig.groupByTargetType ? mphTooltipStacked : mphTooltip} />)

    }, [data, searchParams, chartData, props, isLoading, mphTooltip, reportConfig, mphTooltipStacked]);

    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >{Report}</ReportLoadingErrorWrapper>);
}


export const TargetVelocitiesReport: IConfiguredTrafficReport = {
    name: "Average Speeds",
    key: "tveld",
    description: "This report shows maximum target speeds for the specified period and allows filtering by speed",
    defaultChartType: 'lines',
    component: TargetVelocitiesLoader,
    downloadData: targetVelocitiesExcel as any,//TODO:
    defaultRange: DateOptions.ThisYear,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};


export async function targetVelocitiesExcel(opts: TrafficReportConfiguration): Promise<void> {
    try {
        const response = await rawPost(`/api/report/target-velocities-excel`,
            JSON.stringify(opts),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/octet-stream'
                }
            });
        const blob = await response.blob();
        if (!response.ok) {
            console.error(response.statusText);
            return;
        }

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        // Extract the file name from the response headers
        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDispositionHeader || '');
        const fileName = matches && matches[1] ? matches[1] : 'SpeedData.xlsx';

        link.download = fileName;
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading Excel file:', error);
    }
}
