export const trimRight = function (str: string, charlist: string) {
    if (charlist === undefined)
        charlist = "\\s";

    return str.replace(new RegExp("[" + charlist + "]+$"), "");
};
export const trimLeft = function (str: string, charlist: string) {
    if (charlist === undefined)
        charlist = "\\s";

    return str.replace(new RegExp("^[" + charlist + "]+"), "");
};
export const trimChars = function (str: string, charlist: string) {
    return trimRight(trimLeft(str, charlist), charlist);
};
export const objectContainsString = function (obj: any, str: string) {
    for (var key in obj) {
        const val = obj[key];
        if (!val || key === "id" || key.endsWith("Id"))
            continue;
        if (val.toString().toLowerCase().indexOf(str.toLowerCase()) >= 0) {
            return true;
        }
    }
    return false;
}