import { ComponentType, createElement, Dispatch, SetStateAction, useState } from "react";
import { ErrorAlert } from "../components";

type Errors = string | string[] | undefined;

export interface ErrorSummaryProps {
    errors?: Errors;
}

export type UseErrorsResult = [
    ComponentType,
    Dispatch<SetStateAction<Errors>>,
    Errors
];

export function useErrors(): UseErrorsResult {
    const [errors, setErrors] = useState<string | string[]>();
    const ErrorDisplay = () => createElement(ErrorAlert, { errors });

    return [ErrorDisplay, setErrors, errors];
}