import { TargetType } from "./Shared.TargetType.gen";

export interface IDailyAverageByMonth {
	year: number;

	month: number;

	targetType: TargetType | undefined;

	averageDailyVolume: number;

	numDaysIncluded: number;

	$type?: string | undefined;
}
export class DailyAverageByMonth {
	constructor(data?: IDailyAverageByMonth) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	year: number = 0;

	month: number = 0;

	targetType: TargetType | undefined;

	averageDailyVolume: number = 0;

	numDaysIncluded: number = 0;

	static $type: string = "CoreApi.Models.Reports.DailyAverageByMonth, ";
	$type?: string | undefined = DailyAverageByMonth.$type;
}

