import { useEffect, useState, useRef } from "react";
import { useDebounce } from "react-use";

export type DynamicFixedSizeContainerProps = {
    debounceTime?: number;
    getWidth: () => number;
    getHeight: () => number;
    className?: string;
    margin?: { top: number, right: number, bottom: number, left: number }
    children: (
        args: {
            ref: HTMLDivElement | null;
        } & ParentSizeState,
    ) => React.ReactNode;
};

type ParentSizeState = {
    width: number;
    height: number;
};

export const DynamicFixedSizeContainer: React.FC<DynamicFixedSizeContainerProps> = ({ getWidth, getHeight, debounceTime = 20, className, children, margin }) => {
    const calcHeight = () => getHeight() - (margin?.top ?? 0) - (margin?.bottom ?? 0);
    const calcWidth = () => getWidth() - (margin?.left ?? 0) - (margin?.right ?? 0);

    const dynamicHeight = useRef<HTMLDivElement>(null);
    const [height, updateHeight] = useState(getHeight());
    const [width, updateWidth] = useState(getWidth());
    const [debouncedDimensions, updateDebouncedDimensions] = useState({ width, height });


    function onResize() {
        if (dynamicHeight.current) {
            updateHeight(calcHeight());
            updateWidth(calcWidth());
        }
    };
    useDebounce(() => {
        if (width !== debouncedDimensions.width || height !== debouncedDimensions.height) {
            updateDebouncedDimensions({ width, height });
        }
    }, debounceTime, [height, width]);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        onResize();
        return () => window.removeEventListener("resize", onResize);
    });
    
    return (
        <div ref={dynamicHeight} className={className ?? "p-3"}>
            {children({
                ...debouncedDimensions,
                ref: dynamicHeight.current
            })}
        </div >
    );
}