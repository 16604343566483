import { Single, ISingle } from "./System.Single.gen";
import { TargetType } from "./Shared.TargetType.gen";

export interface ITrafficVelocityReport {
	deviceId: string | undefined;

	startHour: string | undefined;

	targetType: TargetType;

	count: number;

	avgMaxVelocityMPH: number | undefined;

	$type?: string | undefined;
}
export class TrafficVelocityReport {
	constructor(data?: ITrafficVelocityReport) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.startHour) { this.startHour = new Date(data.startHour); }
	}

	deviceId: string | undefined;

	startHour: Date | undefined = new Date();

	targetType: TargetType = 0;

	count: number = 0;

	avgMaxVelocityMPH: number | undefined;

	static $type: string = "CoreApi.Models.Reports.TrafficVelocityReport, ";
	$type?: string | undefined = TrafficVelocityReport.$type;
}

