import { CustomClaimValue, FirmwareType } from "@app/shared";
import { FC } from "react";
import { AuthorizeView } from "../../shared/AuthorizeView";
import { FirmwareTable } from "./FirmwareTable";

export const ManageFirmware: FC = (props) => {
    return (
        <AuthorizeView claim={CustomClaimValue.SuperAdmin}>
            <FirmwareTable firmwareType={FirmwareType.Device} />
            <FirmwareTable firmwareType={FirmwareType.Sensor} />
        </AuthorizeView>
    );
}