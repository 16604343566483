/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post, del } from "./api_client";
import { OmniUser, IOmniUser, ChangeUserPassword, IChangeUserPassword, UserLogin, IUserLogin, PasswordReset, IPasswordReset } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function currentIdentity(): ApiResult<OmniUser> {
	return get<IOmniUser>(`/api/session`).then(result => result === undefined ? undefined :  new OmniUser(result));
}

export async function changePassword(newPassword: ChangeUserPassword): ApiResult<undefined> {
	return post(`/api/session/changepassword`, newPassword);
}

export async function login(login: UserLogin): ApiResult<undefined> {
	return post(`/api/session`, login);
}

export async function forgotPassword(email?: string): ApiResult<undefined> {
	return post(`/api/session/forgotpassword`, email);
}

export async function resetPassword(credentials: PasswordReset): ApiResult<undefined> {
	return post(`/api/session/resetpassword`, credentials);
}

export async function logout(): ApiResult<undefined> {
	return del(`/api/session`);
}
