import { RamUsageInfo } from "@app/shared";
import { HardwareStatusChart, HardwareStatusChartProps, SeriesDetails } from "./HardwareStatusChart";

type RamUsageChartProps = Omit<HardwareStatusChartProps<RamUsageInfo>, "series" | "domain" | "yUnit">;

const dataAccessors = (index: keyof RamUsageInfo) => {
    return {
        date: (data?: RamUsageInfo) => data?.statusTimestamp,
        xAccessor: (data?: RamUsageInfo) => data?.statusTimestamp,
        yAccessor: (data?: RamUsageInfo) => {
            return data?.[index] as number | undefined;
        }
    };
}

const charts: SeriesDetails<RamUsageInfo>[] = [
    {
        dataKey: "RAM",
        accessors: dataAccessors("usedRam")
    },
];

export const RamUsageChart: React.FC<RamUsageChartProps> = ({ data = [], ...props }) => {
    var domain: [number, number] | undefined = undefined;
    const maxRam = data.find((val) => val.maxRam)?.maxRam;
    if (maxRam) {
        domain = [0, maxRam];
    }
    return <HardwareStatusChart domain={domain} data={data} series={charts} yUnit="K" {...props} />
}