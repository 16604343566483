/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, post } from "./api_client";
import { AggregationLevel } from "../models";
import { TrafficReport, ITrafficReport, TotalVolumeByPeriod, ITotalVolumeByPeriod, TrafficVelocityReport, ITrafficVelocityReport, DesignDayVolumes, IDesignDayVolumes, LocationUsageGrouping, ILocationUsageGrouping, TrafficReportConfiguration, ITrafficReportConfiguration } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function busiestDay(opts: TrafficReportConfiguration): ApiResult<TrafficReport[]> {
	return post<ITrafficReport[]>(`/api/report/busiest-day`, opts).then(result => result === undefined ? undefined : result.map(o => new TrafficReport(o)));
}

export async function totalVolume(level: AggregationLevel, opts: TrafficReportConfiguration): ApiResult<TotalVolumeByPeriod[]> {
	return post<ITotalVolumeByPeriod[]>(`/api/report/total-volume/by-${ level }`, opts).then(result => result === undefined ? undefined : result.map(o => new TotalVolumeByPeriod(o)));
}

export async function totalHourlyVolume(opts: TrafficReportConfiguration): ApiResult<TrafficReport[]> {
	return post<ITrafficReport[]>(`/api/report/total-hourly-volumes`, opts).then(result => result === undefined ? undefined : result.map(o => new TrafficReport(o)));
}

export async function targetVelocities(opts: TrafficReportConfiguration): ApiResult<TrafficVelocityReport[]> {
	return post<ITrafficVelocityReport[]>(`/api/report/target-velocities`, opts).then(result => result === undefined ? undefined : result.map(o => new TrafficVelocityReport(o)));
}

export async function hourlyVolume(opts: TrafficReportConfiguration): ApiResult<TrafficReport[]> {
	return post<ITrafficReport[]>(`/api/report/hourly-volumes`, opts).then(result => result === undefined ? undefined : result.map(o => new TrafficReport(o)));
}

export async function getAverageDailyVolumes(opts: TrafficReportConfiguration): ApiResult<DesignDayVolumes> {
	return post<IDesignDayVolumes>(`/api/report/average-daily-volumes`, opts).then(result => result === undefined ? undefined :  new DesignDayVolumes(result));
}

export async function facilityScreenLineTotals(level: AggregationLevel, opts: TrafficReportConfiguration): ApiResult<LocationUsageGrouping[]> {
	return post<ILocationUsageGrouping[]>(`/api/report/facility-usage/by-${ level }`, opts).then(result => result === undefined ? undefined : result.map(o => new LocationUsageGrouping(o)));
}
