import { CustomClaimValue, Device, DeviceNote, DeviceNotes, Users, useAuth } from "@app/shared";
import { useMemo } from "react";
import { useConfirmDialog, useFetch } from "../../hooks";
import { useEditModelDialog } from "../../hooks/useEditModelDialog";
import { IconLink } from "./IconLink";
import { Icons } from "./Icons";
import { AuthorizeView } from "./AuthorizeView";
import { Chrono } from "react-chrono";
import { EditDeviceNote } from "./EditDeviceNote";
import { DropDownMenu } from "./DropDownMenu";
import { format } from "date-fns";

type DeviceNotesProps = {
    device: Device | undefined;
    onChange?: (device: Device) => void;
};

export const DeviceNotesView: React.FC<DeviceNotesProps> = ({ device, onChange }) => {
    const { user, currentOrganizationId } = useAuth();
    const [notes, , notesHelper] = useFetch(() => device ? DeviceNotes.getNotes(device?.id) : Promise.resolve(undefined), [device]);
    const [users, , userHelpers] = useFetch(() => Users.getUsers(currentOrganizationId), [currentOrganizationId]);
    const editDialog = useEditModelDialog(DeviceNote);
    const confirm = useConfirmDialog();

    const handleAdd = (existingNote?: DeviceNote | undefined) => {
        editDialog.show(async (theNote) => {
            if (theNote.id?.length)
                await DeviceNotes.updateNote(device?.id, theNote.id, theNote as DeviceNote);
            else
                await DeviceNotes.createNote(device?.id, theNote as DeviceNote);
            if (onChange && device)
                await onChange(device);
            notesHelper.refreshData();
        }, existingNote ? 'Edit Note' : 'Add Note', existingNote)
    }
    const handleDelete = (existingNote?: DeviceNote | undefined) => {
        confirm.show("Delete Note?", `Are you sure you want to delete this note ?`, "Delete", async () => {
            await DeviceNotes.deleteNote(device?.id, existingNote?.id);
            if (onChange && device)
                await onChange(device);
            notesHelper.refreshData();
        });
    }

    const mappedNotes = useMemo(() => {
        if (notesHelper.isLoading || (notes?.length ?? 0) < 1)
            return [];
        return notes?.map(n => {
            return {
                title: format(n.dateCreated, 'MMM dd yyyy'),
            };
        });
    }, [notes, notesHelper.isLoading])

    const getTitle = (note: DeviceNote) => {
        const userId = note.updatedById ?? note.createdById;
        const user = users?.find(u => u.id === userId);
        const userName = user?.firstName ? (user.firstName + ' ' + user.lastName) : 'Admin';
        const userTime = `${userName} at ${format(note.dateUpdated ?? note.dateCreated, 'HH:mm (zzz)')}`;
        return (note.updatedById ? `Updated by ` : `Created by `) + userTime;
    }

    const timelineTheme = {
        primary: "#c97a5d",
        secondary: "white",
        cardForeColor: "#c97a5d",
        titleColor: "#c97a5d",
        titleColorActive: "#c97a5d",
        titleBgColorActive: "white"
    };

    const customContent = useMemo(() => {
        if (notesHelper.isLoading || (notes?.length ?? 0) < 1)
            return [];
        return notes?.map(n => {
            return (<div className="w-full">
                <h3 className="flex flex-row-reverse mb-3 w-full">
                    <DropDownMenu toggleChild={<Icons.OverflowMenu className="w-7 h-7" />} >
                        <IconLink Icon={Icons.Edit} onClick={x => handleAdd(n)} >Edit</IconLink>
                        <IconLink Icon={Icons.Trash} onClick={x => handleDelete(n)}>Delete</IconLink>
                    </DropDownMenu>
                    <span className="font-semibold justify-self-start flex-1">{getTitle(n)}</span>
                </h3>
                <p>{n.note}</p>
            </div>)
        });
    }, [notes, users, notesHelper.isLoading]);

    const timelineProps = useMemo(() => ({
        items: mappedNotes,
        theme: timelineTheme,
        mode: "VERTICAL_ALTERNATING",
    }), [mappedNotes]);

    return (
        <>
            <AuthorizeView claim={CustomClaimValue.SuperAdmin}>
                <div className="flex flex-row-reverse m-5">
                    <IconLink Icon={Icons.PlusCircle} onClick={() => handleAdd()}>Add New</IconLink>
                </div>
                {!!customContent?.length && (<Chrono {...timelineProps}>{customContent}</Chrono>)}
            </AuthorizeView>

            {editDialog.renderDialog((model, helpers) => (
                <EditDeviceNote note={model} helpers={helpers} />
            ))}
            {confirm.renderDialog()}
        </>
    );
}
