export interface ITimeSpan {
	ticks: number;

	days: number;

	hours: number;

	milliseconds: number;

	microseconds: number;

	nanoseconds: number;

	minutes: number;

	seconds: number;

	totalDays: number;

	totalHours: number;

	totalMilliseconds: number;

	totalMicroseconds: number;

	totalNanoseconds: number;

	totalMinutes: number;

	totalSeconds: number;

	$type?: string | undefined;
}
export class TimeSpan {
	constructor(data?: ITimeSpan) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	ticks: number = 0;

	days: number = 0;

	hours: number = 0;

	milliseconds: number = 0;

	microseconds: number = 0;

	nanoseconds: number = 0;

	minutes: number = 0;

	seconds: number = 0;

	totalDays: number = 0;

	totalHours: number = 0;

	totalMilliseconds: number = 0;

	totalMicroseconds: number = 0;

	totalNanoseconds: number = 0;

	totalMinutes: number = 0;

	totalSeconds: number = 0;

	static $type: string = "System.TimeSpan, ";
	$type?: string | undefined = TimeSpan.$type;
}

