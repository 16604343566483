export enum TaskCommand {
	Start = 0,
	Stop = 1,
}

export const TaskCommandDisplayNames: { [key in keyof typeof TaskCommand]?: string} = {
	"Start": "Start",
	0 : "Start",
	"Stop": "Stop",
	1 : "Stop",
}

export const getTaskCommandDisplayName = (key: TaskCommand | (keyof typeof TaskCommand & string)) => {
	return TaskCommandDisplayNames[key];
}

