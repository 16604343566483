import { useAuth } from "@app/shared";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pages } from "../pages";
import { IconLink } from "./IconLink";
import { Icons } from "./Icons";
import { Modal } from "./Modal";
import { OrganizationDropdown } from "./OrganizationDropdown";

type ProfileIconProps = {
    className?: string;
}
export const ProfileIcon: React.FC<ProfileIconProps> = (props) => {
    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { currentOrganizationId, setCurrentOrganizationId, user } = useAuth();

    const handleLogOutOnClick = () => {
        navigate(Pages.Login.getUrl());
    };

    const handleEditProfileOnClick = () => {
        navigate(Pages.AccountDetails.getUrl());
    };
    const onClickOutside = () => {
        if (isLoading) { return };
        setShowProfileDialog(false);
    }
    const changeOrgs = async (newOrgId: string) => {
        setIsLoading(true);
        setCurrentOrganizationId(newOrgId);
        setIsLoading(false);
    };

    return (
        <div className={props.className}>
            <Icons.Profile className="h-10 w-10 text-muted-body-text hover:text-link-page-hover" onClick={() => setShowProfileDialog(true)} />
            <Modal show={showProfileDialog} onClickOutside={onClickOutside} className="">
                <div className="bg-background-page rounded-sm shadow-sm place-items-center flex flex-col">
                    <div className="flex flex-col p-5 place-items-center gap-2">
                        <Icons.Profile className="w-20 h-20" />
                        <h2>{user?.name}</h2>
                        <p>{user?.email}</p>
                        <div className="text-muted-body-text text-center flex flex-col place-items-center py-5">
                            Current Organization:<br />
                            <OrganizationDropdown onChange={changeOrgs} initOrgId={currentOrganizationId} />
                        </div>
                    </div>

                    <div className="flex flex-col place-items-center bg-background-top-nav w-full gap-2 p-2">
                        <IconLink Icon={Icons.Edit} onClick={handleEditProfileOnClick}>Edit Profile</IconLink>
                        <IconLink Icon={Icons.Logout} onClick={handleLogOutOnClick}>Log Out</IconLink>
                    </div>

                </div>
            </Modal>
        </div>
    );
}