import { Devices as DevicesApi, useAuth } from "@app/shared";
import { FC, useMemo } from "react";
import { useFetch } from "../../hooks";
import { DashboardLayout } from "../layouts";
import { DevicesTable, HiddenSearchBox, Icons } from "../shared";
import { getLastTargetsForDevices } from "@app/shared/src/api/TargetControllerClient.gen";

export const Devices: FC = (props) => {
    const { currentOrganizationId } = useAuth();
    const [devices, , devicesHelper] = useFetch(() => DevicesApi.getByOrganizationId(currentOrganizationId), [currentOrganizationId]);
    const [lastTargets, lastTargetError, targetsHelper] = useFetch(() => getLastTargetsForDevices(devices?.map(d => d.id!)), [devices]);
    const searchBox = Icons.Search;

    const onSearchChanged = (search?: string) => {

    };
    const showLoading = useMemo(() => {
        return !!!devices || !!targetsHelper.isLoading || (lastTargets?.length === 0 && !!lastTargetError?.length)
    }, [devices, lastTargetError, targetsHelper.isLoading]);
    const FilterOptions = () => {
        return (
            <div className="flex space-x-3 ml-3 hover:text-link-left-nav-hover cursor-pointer">
                <Icons.Filter className="h-6 w-6" /><div>Filter</div>
            </div>
        );
    };

    const SearchOption = () => {
        return (
            <HiddenSearchBox onSearchChanged={onSearchChanged} />
        );
    }

    return (
        <DashboardLayout centerChildren={"Devices"}>
            {devices?.length === 0 ? <h3>No Devices found</h3> : <DevicesTable devices={devices} isLoading={showLoading} lastTargets={lastTargets} onChange={() => devicesHelper.refreshData()} />}
        </DashboardLayout>
    );
}