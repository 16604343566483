import { PortCommand } from "./PortCommand.gen";

export interface IStreamPortCommandParams {
	portNumber: number;

	command: PortCommand;

	$type?: string | undefined;
}
export class StreamPortCommandParams {
	constructor(data?: IStreamPortCommandParams) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	portNumber: number = 0;

	command: PortCommand = 0;

	static $type: string = "CoreApi.Models.DeviceCommands.StreamPortCommandParams, ";
	$type?: string | undefined = StreamPortCommandParams.$type;
}

