import { format, parse } from "date-fns";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { TotalVolumeChart } from "./TotalVolumeDataLoader";
import { TotalVolumeByMonth } from "./TotalVolumeByMonth";
import { useContext } from "react";
import { ReportContext } from "./reportHelpers";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import { AggregationLevel } from "@app/shared";

const YearlyDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, updateReportConfig } = useContext(ReportContext);

    function handleClick(date: Date): void {//works 6/2024

        const startLocal = new Date(date.getFullYear(), 0, 1);
        const end = new Date(date.getFullYear() + 1, 0, 1);

        const cfg = {
            ...reportConfig,
            selectedReportId: TotalVolumeByMonth.key,
            startTime: zonedTimeToUtc(startLocal.toISOString(), reportConfig.chartTimezone),
            endTime: zonedTimeToUtc(end.toISOString(), reportConfig.chartTimezone)
        };
        updateReportConfig(cfg);
    }

    return (<TotalVolumeChart
        {...props}
        aggregationLevel={AggregationLevel.Year}
        onClick={handleClick}
        groupMapFunction={d => formatInTimeZone(d.periodStart!, reportConfig.chartTimezone, 'yyyy')}
        groupToDate={d => parse(d + '/01/01', 'yyyy/MM/dd', new Date())}
        tickLabeler={str => format(new Date(str), 'yyyy')}
        toolTipDateFormatter={str => format(new Date(str), 'yyyy')}
    />);
}

export const TotalVolumeByYear: IConfiguredTrafficReport = {
    name: "by Year",
    key: "tvy",
    description: "This report shows total traffic for the specified years",
    defaultChartType: 'bars',
    component: YearlyDataLoader,
    defaultRange: DateOptions.AllData,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};