import { PropsWithChildren } from "react";
import { ReactComponent as Logo } from "../../assets/images/Omnisight-White.svg";
import { NoAuthLayout } from "./NoAuthLayout";

type NoAuthAccountLayoutProps = {
    className?:string
} & PropsWithChildren;

export const NoAuthAccountLayout: React.FC<NoAuthAccountLayoutProps> = (props) => {
    return (
        <NoAuthLayout className={props.className}>

            <div className="max-w-3xl w-full mx-auto pt-5">
                <div>
                    <Logo height={50} width={200} className="p-0 mx-auto" />
                </div>
                <div className="w-full p-5 rounded-sm shadow border border-separators-page bg-background-page m-5 mx-auto">
                    {props.children}
                </div>
            </div>
        </NoAuthLayout>
    );
}