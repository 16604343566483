import { DetectionZone, Facility, ScreenLine, Vector2 } from "@app/shared";
import { ReactEventHandler, useEffect, useState } from "react";
import { DrawingContainer } from "./DrawingContainer";
import { Shape, SvgShape } from "./LayoutDesigner/SvgShape";

export type HomographyImageEditorProps = {
    editMode: 'aoi' | 'homo' | 'none',
    facility: Facility | undefined,
    srcImageUrl: string | undefined,
    editablePolygon?: DetectionZone | ScreenLine,
    editableAoi?: DetectionZone,
    onDimensionChange?: (newDimensions: { width: number, height: number }) => void,
    onMouseMove?: (point: [number, number] | undefined) => any;
    onShapeCompleted?: (shape: Shape) => void,
};

export const HomographyImageEditor: React.FC<HomographyImageEditorProps> = (props) => {

    const [srcImg, setSrcImg] = useState(null as HTMLImageElement | null)
    const [currentPosition, setCurrentPosition] = useState<Vector2>();
    const [polyWip, setPolyWip] = useState(props.editablePolygon);
    const [aoiPoly, setAoiPoly] = useState<DetectionZone | undefined>(props.editableAoi);

    const onSourceLoad: ReactEventHandler<HTMLImageElement> = (e) => {
        setSrcImg(e.currentTarget);
    };

    useEffect(() => {
        if ((srcImg?.naturalHeight ?? 0) > 0 && props.onDimensionChange) {
            const img = srcImg!;
            props.onDimensionChange({ width: img?.clientWidth ?? 0, height: img?.clientHeight ?? 0 });
            const resizeObserver = new ResizeObserver(entries =>
                props.onDimensionChange?.({ width: img?.clientWidth ?? 0, height: img?.clientHeight ?? 0 })
            )
            resizeObserver.observe(img);
            return () => {
                resizeObserver.disconnect();
            }
        }
    }, [srcImg]);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPolyWip(props.editablePolygon);
    }, [props.editablePolygon])
    useEffect(() => {
        setAoiPoly(props.editableAoi);
    }, [props.editableAoi])

    const handleCurrentSourcePointChange = (point: [number, number] | undefined) => {      
        setCurrentPosition(point ? new Vector2({ x: point[0], y: point[1] }) : undefined);
        props.onMouseMove?.(point);
    };

    const clampPointForShape = (shape: Shape, point: Vector2) => {
        if (!shape) { return point; }
        return shape.clampPointIfClosing(point, (props.facility?.backgroundImageWidth ?? 1000) * .007);
    };

    const handleOnClick = (point: [number, number] | undefined) => {
        if (!point || props.editMode==='none') return;
      
        var shapeToModify = (props.editMode === "aoi" ? aoiPoly : polyWip) ?? new DetectionZone();
        var position = new Vector2({ x: point[0], y: point[1] });
        position = clampPointForShape(shapeToModify!, position);
        shapeToModify.points?.push(position);

        if (props.editMode === "aoi")
            setAoiPoly(shapeToModify as DetectionZone);
        else {
            setPolyWip(shapeToModify);
        }

        if (shapeToModify.isComplete() && props.onShapeCompleted) {
            props.onShapeCompleted(shapeToModify);
        }
    };

    return (<div className="py-5">
        {(!props.srcImageUrl) && <h2 className="p-5">No image was found.  Please ensure the device is taking target pictures.</h2>}
        {props.srcImageUrl?.length &&
            <DrawingContainer
                imageSrc={props.srcImageUrl}
                onImageLoad={onSourceLoad}
                imageHeight={srcImg?.naturalHeight ?? 480}
                imageWidth={srcImg?.naturalWidth ?? 640}
                onCurrentPointChange={handleCurrentSourcePointChange}
                onClick={handleOnClick}
            >
                <rect width="100%" height="100%" stroke="gray" fill="gray" fillOpacity=".5" strokeWidth="0" mask="url(#clip)" />
                {currentPosition && props.editMode === 'homo' &&
                    <circle
                        style={{ fillOpacity: .7, strokeWidth: 2, fill: "blue", }}
                        r={6}
                        cx={currentPosition.x}
                        cy={currentPosition.y}
                    />
                }
                {polyWip &&
                    <SvgShape
                        shape={polyWip}
                        scaleRatio={1}
                        currentPosition={props.editMode === 'homo' &&
                            !(polyWip.isComplete()) ?
                            currentPosition
                            :
                            undefined} />
                }
                <mask id="clip">
                    <rect x="0" y="0" width="100%" height="100%" fill="white" />
                    {aoiPoly &&
                        <SvgShape
                            opacity={1}
                            color="black"
                            shape={aoiPoly}
                            scaleRatio={1}
                            currentPosition={
                                props.editMode === 'aoi' &&
                                    !(aoiPoly?.isComplete()) ?
                                    currentPosition
                                    :
                                    undefined} />
                    }
                </mask>
            </DrawingContainer>
        }
    </div>);
}