import { Int32, IInt32 } from "./System.Int32.gen";

export interface ICpuUsageInfo {
	numberOfCores: number;

	statusTimestamp: string | undefined;

	coreUsages: number[];

	$type?: string | undefined;
}
export class CpuUsageInfo {
	constructor(data?: ICpuUsageInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.statusTimestamp) { this.statusTimestamp = new Date(data.statusTimestamp); }
	}

	numberOfCores: number = 0;

	statusTimestamp: Date | undefined = new Date();

	coreUsages: number[] = [];

	static $type: string = "CoreApi.Models.View.Hardware.CpuUsageInfo, ";
	$type?: string | undefined = CpuUsageInfo.$type;
}

