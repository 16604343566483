import { RoleType } from "./RoleType.gen";
import { RoleValue } from "./RoleValue.gen";
import { CustomClaimValue } from "./CustomClaimValue.gen";

export interface IUserRoleClaim {
	userId: string | undefined;

	entityType: RoleType;

	entityId: string | undefined;

	role: RoleValue;

	claims: CustomClaimValue[];

	$type?: string | undefined;
}
export class UserRoleClaim {
	constructor(data?: IUserRoleClaim) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	userId: string | undefined;

	entityType: RoleType = 0;

	entityId: string | undefined;

	role: RoleValue = 0;

	claims: CustomClaimValue[] = [];

	static $type: string = "CoreApi.Models.View.UserRoleClaim, ";
	$type?: string | undefined = UserRoleClaim.$type;
}

