import { EndpointType } from "./EndpointType.gen";
import { TriggerType } from "./Shared.TriggerType.gen";
import { TargetTypes } from "./Shared.TargetTypes.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IClientEndpoint {
	targetFilterDescription: string | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	endpointType: EndpointType;

	trigger: TriggerType;

	targetFilter: TargetTypes | number ;

	endpointUrl: string;

	sharedSecretKey: string | undefined;

	customAuthHeader: string | undefined;

	enableHeartbeat: boolean;

	secondsBetweenHearbeat: number;

	$type?: string | undefined;
}
export class ClientEndpoint {
	constructor(data?: IClientEndpoint) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	targetFilterDescription: string | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	endpointType: EndpointType = 0;

	trigger: TriggerType = 0;

	targetFilter: TargetTypes | number  = 0;

	@IsNotEmpty()
	endpointUrl: string = "";

	sharedSecretKey: string | undefined;

	customAuthHeader: string | undefined;

	enableHeartbeat: boolean = false;

	secondsBetweenHearbeat: number = 0;

	static $type: string = "CoreApi.Models.ClientEndpoint, ";
	$type?: string | undefined = ClientEndpoint.$type;
}

