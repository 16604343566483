import { CpuTempInfo } from "@app/shared";
import { HardwareStatusChart, HardwareStatusChartProps, SeriesDetails } from "./HardwareStatusChart";

type CpuTemperatureChartProps = Omit<HardwareStatusChartProps<CpuTempInfo>, "series">;

const dataAccessors = (index: keyof CpuTempInfo) => {
    return {
        date: (data?: CpuTempInfo) => data?.statusTimestamp,
        xAccessor: (data?: CpuTempInfo) => data?.statusTimestamp,
        yAccessor: (data?: CpuTempInfo) => {
            return data?.[index] as number | undefined;
        }
    };
}

const charts: SeriesDetails<CpuTempInfo>[] = [
    {
        dataKey: "AO",
        accessors: dataAccessors("ao")
    },
    {
        dataKey: "GPU",
        accessors: dataAccessors("gpu"),
    },
    {
        dataKey: "Thermal",
        accessors: dataAccessors("thermal"),
    },
    {
        dataKey: "CPU",
        accessors: dataAccessors("cpu"),
    }
];


export const CpuTemperatureChart: React.FC<CpuTemperatureChartProps> = (props) => {
    return <HardwareStatusChart series={charts} yUnit="&deg;C" {...props} />
}
