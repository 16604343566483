export enum EndpointType {
	Json = 0,
}

export const EndpointTypeDisplayNames: { [key in keyof typeof EndpointType]?: string} = {
	"Json": "Json",
	0 : "Json",
}

export const getEndpointTypeDisplayName = (key: EndpointType | (keyof typeof EndpointType & string)) => {
	return EndpointTypeDisplayNames[key];
}

