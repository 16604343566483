export enum FirmwareVersionType {
	Alpha = 0,
	Beta = 1,
	ReleaseCandidate = 2,
	Release = 3,
}

export const FirmwareVersionTypeDisplayNames: { [key in keyof typeof FirmwareVersionType]?: string} = {
	"Alpha": "Alpha",
	0 : "Alpha",
	"Beta": "Beta",
	1 : "Beta",
	"ReleaseCandidate": "RC",
	2 : "RC",
	"Release": "Release",
	3 : "Release",
}

export const getFirmwareVersionTypeDisplayName = (key: FirmwareVersionType | (keyof typeof FirmwareVersionType & string)) => {
	return FirmwareVersionTypeDisplayNames[key];
}

