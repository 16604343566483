import { SensorUpdateCommandParams, ISensorUpdateCommandParams } from "./DeviceCommands.SensorUpdateCommandParams.gen";
import { Device, IDevice } from "./Device.gen";
import { DateTime, IDateTime } from "./System.DateTime.gen";
import { Boolean, IBoolean } from "./System.Boolean.gen";

export interface ISensorUpdateCommand {
	description: string | undefined;

	commandParamsJson: ISensorUpdateCommandParams | undefined;

	device: IDevice | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	dateCreated: string | undefined;

	dateSentToDevice: string | undefined;

	dateCompleted: string | undefined;

	deviceDidSucceed: boolean | undefined;

	deviceResponse: string | undefined;

	$type?: string | undefined;
}
export class SensorUpdateCommand {
	constructor(data?: ISensorUpdateCommand) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.commandParamsJson) { this.commandParamsJson = new SensorUpdateCommandParams(data.commandParamsJson); }
		if (data.device) { this.device = new Device(data.device); }
		if (data.dateCreated) { this.dateCreated = new Date(data.dateCreated); }
		if (data.dateSentToDevice) { this.dateSentToDevice = new Date(data.dateSentToDevice); }
		if (data.dateCompleted) { this.dateCompleted = new Date(data.dateCompleted); }
	}

	description: string | undefined;

	commandParamsJson: SensorUpdateCommandParams | undefined;

	device: Device | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	dateCreated: Date | undefined = new Date();

	dateSentToDevice: Date | undefined;

	dateCompleted: Date | undefined;

	deviceDidSucceed: boolean | undefined;

	deviceResponse: string | undefined;

	static $type: string = "CoreApi.Models.DeviceCommands.SensorUpdateCommand, ";
	$type?: string | undefined = SensorUpdateCommand.$type;
}

