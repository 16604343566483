import { getApiUrl, IFacilityOccupancy, ITarget, Target } from "@app/shared";
import { useSignalr } from "@known-as-bmf/react-signalr";
import { useEffect, useCallback } from "react";
import { Subscription } from "rxjs";

const OCCUPANCY_UPDATE_EVENT = 'ReceiveOccupancy';
const TARGET_UPDATE_EVENT = 'ReceiveTarget';
const TARGETS_UPDATE_EVENT = 'ReceiveTargets';
const WATCH_FACILITY_COMMAND = 'WatchFacility';
const WATCH_FACILITY_PARKING_COMMAND = 'WatchFacilityParking';

export function useLiveViewHub(facilityId: string | undefined, onTargetsReceived: (t: Target[]) => void, unsubscribe?: boolean) {
    const { send, on } = useSignalr(getApiUrl(`/hubs/liveview`));

    const handleTargetsReceived = useCallback((targets: ITarget[]) => {
        onTargetsReceived(targets.map(t => new Target(t)))
    }, [onTargetsReceived]);

    const handleTargetReceived = useCallback((target: ITarget) => {
        handleTargetsReceived([target]);
    }, [handleTargetsReceived]);


    useEffect(() => {
        if (facilityId) {
            send(WATCH_FACILITY_COMMAND, facilityId);
        }
    }, [facilityId, send]);

    useEffect(() => {
        let onTargetReceived: Subscription | undefined, onTargetsReceived: Subscription | undefined;

        if (!unsubscribe) {
            onTargetReceived = on<ITarget>(TARGET_UPDATE_EVENT).subscribe(handleTargetReceived);
            onTargetsReceived = on<ITarget[]>(TARGETS_UPDATE_EVENT).subscribe(handleTargetsReceived);
        }

        return () => {
            onTargetReceived?.unsubscribe();
            onTargetsReceived?.unsubscribe();
        }
    }, [on, unsubscribe, handleTargetReceived, handleTargetsReceived]);

}

export function useParkingViewHub(facilityId: string | undefined, onOccupancyReceived: (o: IFacilityOccupancy) => void, unsubscribe?: boolean) {
    const { send, on } = useSignalr(getApiUrl(`/hubs/parkingview`));

    const handleOccupancyReceived = useCallback((occupancy: IFacilityOccupancy) => {
        onOccupancyReceived(occupancy)
    }, [onOccupancyReceived]);

    useEffect(() => {
        if (facilityId) {
            send(WATCH_FACILITY_PARKING_COMMAND, facilityId);
        }
    }, [facilityId, send]);

    useEffect(() => {
        let occupancySubscription: Subscription | undefined;

        if (!unsubscribe) {
            occupancySubscription = on<IFacilityOccupancy>(OCCUPANCY_UPDATE_EVENT).subscribe(handleOccupancyReceived);
        }

        return () => {
            occupancySubscription?.unsubscribe();
        }
    }, [on, unsubscribe, handleOccupancyReceived]);

}