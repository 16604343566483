import { useEffect, useRef } from "react";


export type HeldKeyEvent = { keysDown: { [keyCode: string]: number } };
export type HeldKeyboardKeyHandler = (e: HeldKeyEvent) => void;

export const useHeldKeyboardKeys = (handler: HeldKeyboardKeyHandler, continuousMode: boolean = true, dependencies?: any[]) => {
    const keysDown = useRef({} as { [code: string]: number });
    let intervalId = useRef(null as null | NodeJS.Timeout);

    const getKeyMagnitude = (startTime: number) => {
        const now = Date.now();
        const delta = now - startTime;
        if (delta > 1000) {
            return 10;
        } else if (delta > 200) {
            return 5;
        }
        return 1;
    }

    const handleTick = () => {
        const event = { keysDown: {} } as HeldKeyEvent;
        Object.keys(keysDown.current).forEach(key => {
            event.keysDown[key] = getKeyMagnitude(keysDown.current[key]);
        });
        handler(event);
    };

    const localHandleDown = (e: KeyboardEvent) => {//get's called while held
        var current = keysDown.current[e.code];
        if (current) { return; }
        keysDown.current[e.code] = Date.now();
        if (!intervalId.current && continuousMode) {
            intervalId.current = setInterval(handleTick, 50);
        }
        handleTick();
    };

    const localHandleUp = (e: KeyboardEvent) => {
        delete keysDown.current[e.code];
        if (intervalId.current && Object.keys(keysDown.current).length === 0) {
            clearInterval(intervalId.current);
            intervalId.current = null;
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', localHandleDown);
        document.addEventListener('keyup', localHandleUp);
        return () => {
            document.removeEventListener('keydown', localHandleDown);
            document.removeEventListener('keyup', localHandleUp);
        };
    }, dependencies);// eslint-disable-line react-hooks/exhaustive-deps
}