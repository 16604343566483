import { CustomClaimValue } from "@app/shared";
import { FC } from "react";
import { ReactComponent as Logo } from "../../assets/images/Omnisight-White-O.svg";
import { Pages } from "../pages";
import { Icons, ProfileIcon } from "../shared";
import { AuthorizeView } from "../shared/AuthorizeView";
import { SidebarIcon } from "./SidebarIcon";

export const SidebarNav: FC = (props) => {
    return (
        <div className="w-16 bg-background-left-nav min-h-full h-full flex flex-col items-center space-y-2 overflow-y-auto overflow-x-hidden min-w-fit">
            <a href='/'><Logo height={36} className="fill-nav-logo-tint mt-2 mb-1"></Logo></a>
            {/* <div className="h-px w-12 bg-link-left-nav" /> */}
            <ul className="list-none w-full space-y-7 pt-6 flex-grow border-t">
                <SidebarIcon hover="Location Groups" to={Pages.ViewFacilityGroups.getUrl()} Icon={Icons.LayerGroup} />
                <SidebarIcon hover="Devices" to={Pages.Devices.getUrl()} Icon={Icons.NavRadar} />
                <SidebarIcon hover="Users" to={Pages.Users.getUrl()} Icon={Icons.Users} claim={CustomClaimValue.ManageUsers} />
                <AuthorizeView claim={CustomClaimValue.SuperAdmin}>
                    <SidebarIcon hover="Site Admin" to={Pages.Settings.getUrl()} Icon={Icons.NavSettings} />
                </AuthorizeView>
            </ul>
            <ul className="list-none w-full space-y-7 py-6 border-t ">
                <SidebarIcon hover="Profile"><ProfileIcon className="w-full flex justify-center" /></SidebarIcon>
            </ul>

        </div>
    );
}
