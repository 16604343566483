import { TargetType } from "./Shared.TargetType.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface ILocationUsageGrouping {
	deviceId: string;

	periodStart: string;

	inCount: number;

	outCount: number;

	targetType: TargetType | undefined;

	$type?: string | undefined;
}
export class LocationUsageGrouping {
	constructor(data?: ILocationUsageGrouping) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.periodStart = new Date(data.periodStart);
	}

	@IsNotEmpty()
	deviceId: string = "";

	@IsNotEmpty()
	periodStart: Date = new Date();

	inCount: number = 0;

	outCount: number = 0;

	targetType: TargetType | undefined;

	static $type: string = "CoreApi.Models.Reports.LocationUsageGrouping, ";
	$type?: string | undefined = LocationUsageGrouping.$type;
}

