import { Button, DetectionZone, Device, Devices, Facilities, IDetectionZone, Targets, Vector2, getApiUrl, rotateVector } from "@app/shared";
import { ReactEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getFacilityCoordsHelper, HomographyHelper, toastSuccess } from "../../helpers";
import { useFacilityCenter } from "../../helpers/mapHelpers";
import { HomographyImageEditor } from "./DeviceHomographyImageEditor";
import { DynamicFixedSizeContainer } from "./DynamicFixedSizeContainer";
import { Shape } from "./LayoutDesigner/SvgShape";
import { SkeletonGrid } from "./loading";
import { useFetch } from "../../hooks";
import { DetectionZoneMapShape, DeviceMapShape, OmniGoogleMap, OmniMapMethods } from "./maps";

type DeviceHomographyProps = {
    device: Device;
    onChange?: () => any;
};

type EditMode = "source" | "destination" | 'aoi' | 'none';

export const DeviceHomography: React.FC<DeviceHomographyProps> = ({ device, onChange }) => {
    const [facility, , facilityHelper] = useFetch(() => device ? Facilities.getById(device.facilityId) : Promise.resolve(undefined), [device]);
    const [sourcePoly, setSourcePoly] = useState<DetectionZone>();
    const [destPoly, setDestPoly] = useState<DetectionZone>();
    const [currentDimensions, setCurrentDimensions] = useState({ width: 640, height: 480 });
    const [destWip, setDestWip] = useState<DetectionZone | undefined>(undefined);
    const [aoiPoly, setAoiPoly] = useState<DetectionZone>();
    const map = useRef(null as null | OmniMapMethods);
    const [homographyHelper, setHomographyHelper] = useState<HomographyHelper>();
    const [editMatrixStage, setEditMatrixStage] = useState<EditMode | 'hasChanges' | 'hasAoiChanges'>('none');
    const [lastTarget, , lastTargetHelper] = useFetch(() => device ? Targets.getLastTargetWithPhotoForDevice(device.id) : Promise.resolve(undefined), [device]);
    const srImgUrl = useMemo(() => (lastTarget && !lastTargetHelper.isLoading) ? getApiUrl(`/api/organization/${device?.organizationId}/device/${device?.id}/target/${lastTarget.externalId}/photo`) : undefined, [lastTarget, lastTargetHelper.isLoading, device]);

    const coordsHelper = useMemo(() => { if (facility) { return getFacilityCoordsHelper(facility); } }, [facility]);
    const [centerCoords, centerIsLoading] = useFacilityCenter(facility);

    const initPolys = () => {
        if (!device || !facility) { return; }

        const srcPoly = new DetectionZone({ points: device.homographySourcePoints } as IDetectionZone);
        const destDz = new DetectionZone({ points: device.homographyDestinationPoints, id: 'DestHomography', } as IDetectionZone);

        //Flip Y axis, Rotate and then translate
        destDz.points = destDz.points?.map(p => rotateVector(new Vector2({ x: p.x, y: -1 * p.y }), (device?.headingDegrees)));
        destDz.points = destDz.points?.map(p => new Vector2({ x: p.x + device.positionX, y: p.y + device.positionY }));

        setSourcePoly(srcPoly);
        setDestPoly(destDz);

        setAoiPoly(new DetectionZone({ points: device?.cameraAoiPoints } as IDetectionZone));
        //For display, use the rotated and translated points, not the local radar points
        if (device?.homographyDestinationPoints?.length) {
            let source = srcPoly?.points?.flatMap((v) => { return [v.x, v.y] });
            source = source?.slice(0, 8);
            let dest = destDz.points?.flatMap((v) => { return [v.x, v.y] });
            dest = dest?.slice(0, 8);
            setHomographyHelper(new HomographyHelper(source ?? [], dest ?? []));
        }
    }

    useEffect(() => {
        initPolys();
    }, [device, facility]);//eslint-disable-line react-hooks/exhaustive-deps

    const destMouseCircle = useMemo(() => {
        return new google.maps.Circle({
            fillOpacity: 1.0,
            fillColor: 'red',
            strokeOpacity: 1.0,
            strokeColor: 'red',
            strokeWeight: 1.0,
            radius: 1,
            clickable: false,
        });
    }, []);

    const onSourceMouseMove = useCallback((point: [number, number] | undefined) => {
        if (!point || !homographyHelper || !device) {
            destMouseCircle?.setVisible(false);
            return;
        }

        const translated = homographyHelper.transform(point[0], point[1]);
        if (coordsHelper && map.current?.map) {
            destMouseCircle.setCenter(coordsHelper?.toLatLng(translated));
            destMouseCircle.setVisible(true);
            destMouseCircle.setMap(map.current.map);
        }

    }, [coordsHelper, destMouseCircle, device, homographyHelper]);


    const handleDestUpdate = (shape: DetectionZone) => {
        if (editMatrixStage !== "destination") { return; }
        if (shape.isComplete()) {
            setEditMatrixStage(curr => "hasChanges");
            setDestWip(curr => undefined);
            setDestPoly(curr => Object.assign(new DetectionZone(), { points: shape.points, id: shape.id }));
        } else { setDestWip(shape); }
    }
    const handleDestUpdateMemo = useCallback(handleDestUpdate, [editMatrixStage]);

    const handleSrcCompleted = (shape: Shape) => {
        if (editMatrixStage === 'source') {
            setEditMatrixStage("destination");
            setSourcePoly(shape as DetectionZone);
            setDestWip(Object.assign(new DetectionZone(), { id: 'Homography' }));
        }
        else {
            setEditMatrixStage("hasAoiChanges");
            setAoiPoly(shape as DetectionZone);
        }
    }

    const handleEdit: ReactEventHandler<HTMLButtonElement> = (e) => {
        setSourcePoly(undefined);
        setDestPoly(undefined);
        setEditMatrixStage("source");
    };

    const handleSave: ReactEventHandler<HTMLButtonElement> = async (e) => {
        if (!!!device || !facility) { return; }

        //Translate from device, un-rotate, and flip y axis to convert to radar coordinates
        var result = destPoly?.points?.map(p => new Vector2({ x: p.x - device.positionX, y: p.y - device.positionY }));
        result = result?.map(v => rotateVector(v, -1 * device!.headingDegrees));
        result = result?.map(v => new Vector2({ x: v.x, y: -1 * v.y }));

        await Devices.updateHomographyPoints(device?.id, { ...device, homographySourcePoints: sourcePoly?.points, homographyDestinationPoints: result } as Device);

        if (onChange) {
            await onChange();
        }
        toastSuccess("Saved", "Homography matrix has been saved!");
        setEditMatrixStage('none');
    };


    const handleStartEditAoiOnClick: ReactEventHandler<HTMLButtonElement> = (e) => {
        setAoiPoly(undefined);
        setEditMatrixStage("aoi");
    };

    const handleSaveAoiOnClick: ReactEventHandler<HTMLButtonElement> = async (e) => {
        await Devices.updateCameraAoiPoints(device?.id, { ...device, cameraAoiPoints: aoiPoly?.points } as Device);
        toastSuccess("Saved", "Area of Interest has been saved!");
        setEditMatrixStage('none');
    };

    const handleDefault: ReactEventHandler<HTMLButtonElement> = async (e) => {
        await Devices.resetHomographyPoints(device?.id);
        setEditMatrixStage('none');
        if (onChange) { onChange(); }
    };
    const handleCancel: ReactEventHandler<HTMLButtonElement> = async (e) => {
        initPolys();
        setDestWip(curr => undefined);
        setEditMatrixStage('none');
    };

    const handleSrcDimension = (dims: { width: number, height: number }) => {
        setCurrentDimensions(dims);
    };

    if (facilityHelper.isLoading || lastTargetHelper.isLoading) {
        return (
            <>
                <SkeletonGrid rows={1} cols={2} />
                <SkeletonGrid rows={1} cols={2} colClassNames="h-60 w-full" />
            </>
        );
    }
    if (!facilityHelper.isLoading && !coordsHelper) {
        <div className="text-center">
            <h1>Device Must belong to Location before configuring Homography</h1>
        </div>
    }

    return (
        facility ?
            <div className="grid grid-cols-2">
                <div className="text-center">
                    <h1>Source</h1>
                    <HomographyImageEditor
                        srcImageUrl={srImgUrl}
                        editableAoi={aoiPoly}
                        editablePolygon={sourcePoly}
                        onMouseMove={editMatrixStage === 'none' ? onSourceMouseMove : undefined}
                        facility={facility} editMode={editMatrixStage === 'source' ? 'homo' : (editMatrixStage === 'aoi' ? 'aoi' : 'none')}
                        onDimensionChange={handleSrcDimension}
                        onShapeCompleted={handleSrcCompleted} />

                    {editMatrixStage === "source" &&
                        <h2>Please select the source points</h2>
                    }
                </div>

                <div className="text-center">
                    <h1>Destination</h1>
                    <DynamicFixedSizeContainer getWidth={() => currentDimensions.width} getHeight={() => currentDimensions.height} className="mt-5 mx-3" margin={{ right: 16, left: 0, top: 0, bottom: 0 }} >
                        {({ width, height }) => {
                            return (centerCoords && !centerIsLoading) && (
                                <OmniGoogleMap ref={map} center={centerCoords} width={width} height={height} fitToPolygons={true} showPositionCircles={!!destWip} >
                                    <DeviceMapShape shape={device} facility={facility} />
                                    <DetectionZoneMapShape facility={facility} shape={editMatrixStage === 'destination' ? destWip! : destPoly!} onUpdate={handleDestUpdateMemo} isEditable={editMatrixStage === 'destination'} />
                                </OmniGoogleMap>
                            );
                        }}
                    </DynamicFixedSizeContainer>
                    {editMatrixStage === "destination" &&
                        <h2 className="col-start-2">Please select the destination points</h2>
                    }

                </div>
                {editMatrixStage === 'none' &&
                    <div className="flex col-span-2 me-auto mb-5">
                        <Button onClick={handleEdit}>Edit Homography Matrix</Button>
                        <Button onClick={handleStartEditAoiOnClick}>Edit Area of Interest</Button>
                    </div>
                }
                {editMatrixStage !== 'none' &&
                    <div className="col-span-2 ml-auto flex">
                        {editMatrixStage !== 'aoi' && <Button onClick={handleDefault} className="me-16">Use Default</Button>}
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button onClick={editMatrixStage === 'hasChanges' ? handleSave : handleSaveAoiOnClick} disabled={editMatrixStage !== 'hasChanges' && editMatrixStage !== 'hasAoiChanges'}>Save</Button>
                    </div>
                }
            </div >
            :
            <div>
                <p>Please add this device to a location if you want to configure the homography matrix</p>
            </div>
    );
}