import { DependencyList, useMemo } from "react";
import MiniSearch from 'minisearch';

export function useMiniSearch<T extends { id?: string }>(data: T[] | undefined, fuzzyTextFields?: Array<keyof T>, deps?: DependencyList) {

    const miniSearchFuzzy = useMemo(() => {
        if (!data?.length || !fuzzyTextFields?.length) {
            return undefined;
        }
        let miniSearch = new MiniSearch({
            fields: fuzzyTextFields as string[],
            storeFields: ['id'],
            searchOptions: {
                boost: { name: 2 },
                fuzzy: 0.2,
                prefix: true
            }
        });
        miniSearch.addAll(data as any)
        return miniSearch;
    }, [data, deps, fuzzyTextFields])

    const miniSearchStrict = useMemo(() => {
        if (!data?.length || !fuzzyTextFields?.length) {
            return undefined;
        }
        let miniSearch = new MiniSearch({
            fields: Object.keys(data[0]).filter(k => fuzzyTextFields.indexOf(k as keyof T) < 0),
            storeFields: ['id'],
            tokenize: (string, field) => [string],
            searchOptions: {
                boost: { name: 2 },
                prefix: true
            }
        });
        miniSearch.addAll(data as any)
        return miniSearch;
    }, [data, deps, fuzzyTextFields])

    type SearchResult = { $$score: number } & Partial<T>;
    const search = (query: string): SearchResult[] => {
        if (!data) {
            return [];
        }
        const fuzzResult = miniSearchFuzzy?.search(query);
        const result = miniSearchStrict?.search(query);
        const results = [...result ?? [], ...fuzzResult ?? []];
        return results.map(sr => ({ ...data.find(x => x.id === sr.id), $$score: sr.score } as SearchResult));
    }

    return {search};
}