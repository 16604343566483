import { MouseEventHandler, useRef, useState, useEffect } from "react";
import { Dialog, DialogProps } from "../components";

export function useConfirmDialog() {
    const [showDialog, setShowDialog] = useState(false);

    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [okButtonText, setOkButtonText] = useState("");

    const [errorMessage, setErrorMessage] = useState<string>();
    const onOkClickHandler = useRef<MouseEventHandler>();
    const onCloseHandler = useRef(() => { });

    useEffect(() => {
        if (!showDialog && onCloseHandler.current) {
            onCloseHandler.current();
        }
    }, [showDialog])

    const show = (_title: string, _text: string, _okButtonText: string, _okClickHandler?: MouseEventHandler, _onCloseHandler?: () => void) => {
        setTitle(_title);
        setText(_text);
        setOkButtonText(_okButtonText);

        setErrorMessage(undefined);
        setShowDialog(true);

        onOkClickHandler.current = _okClickHandler;
        onCloseHandler.current = _onCloseHandler ?? onCloseHandler.current;
    }
    const handleOkClick: MouseEventHandler = async (event) => {
        if (!onOkClickHandler.current)
            return;
        try {
            await onOkClickHandler.current(event);
            setShowDialog(false);
        } catch (e) {
            setErrorMessage("An Error has Ocurred: " + e);
        }
    }
    const renderDialog = (props?: Omit<DialogProps, "title" | "show" | "onOkClick" | "onCancelClick" | "okButtonClick" | "children">) => {
        return (
            <Dialog title={title}
                show={showDialog}
                onOkClick={onOkClickHandler.current ? handleOkClick : () => setShowDialog(false)}
                onCancelClick={onOkClickHandler.current ? () => setShowDialog(false) : undefined}
                okButtonText={okButtonText} {...props}>

                <div className="p-5">
                    {text}
                    {errorMessage &&
                        <p>{errorMessage}</p>
                    }
                </div>
            </Dialog>
        );
    }
    return { show, renderDialog };
}