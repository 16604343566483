import { FC } from "react";
import { TabbedDashLayout } from "../../layouts/TabbedDashLayout";
import { GlobalDevices } from "./GlobalDevices";
import { ManageFirmware } from "./ManageFirmware";

export const Settings: FC = (props) => {
    return (
        <TabbedDashLayout centerChildren="Site Admin" tabs={[
            { title: "Firmware", child: <ManageFirmware /> },
            { title: "Devices", child: <GlobalDevices /> }
        ]} />
    );
}