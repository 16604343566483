import { DetectionZone, DetectionZoneType, Device, Facility, ScreenLine, Spot } from "@app/shared";
import { DetectionZoneMapShape, DeviceMapShape, RectangleMapShape, ReferencePointMapShape, ScreenLineMapShape } from ".";



export type MapShapeTypes = DetectionZone | ScreenLine | Device | Spot;
export interface MapShapeProps<T extends MapShapeTypes> {
    facility: Facility
    shape: T | undefined,
    isEditable?: boolean,
    isSelectable?: boolean,
    isSelected?: boolean,
    onUpdate?: (shape: T) => void;
    onClick?: (e: google.maps.MapMouseEvent, shape: T) => void;
}
export const MapShape: React.FC<MapShapeProps<MapShapeTypes>> = ({ facility, shape, isEditable, isSelected, isSelectable, onUpdate, onClick }) => {
    if (!shape) return null;
    if (shape instanceof Device) return (<DeviceMapShape facility={facility} shape={shape} isEditable={isEditable} isSelected={isSelected} isSelectable={isSelectable} onUpdate={onUpdate} onClick={onClick} />);
    if (shape instanceof ScreenLine) return (<ScreenLineMapShape facility={facility} shape={shape} isEditable={isEditable} isSelected={isSelected} isSelectable={isSelectable} onUpdate={onUpdate} onClick={onClick} />);   
    if (shape instanceof DetectionZone && shape.zoneType === DetectionZoneType.ParkingSpot && !shape.isComplete()) return (<RectangleMapShape facility={facility} shape={shape} isSelectable={isSelectable} isEditable={isEditable} isSelected={isSelected} onUpdate={onUpdate} onClick={onClick} />);//TODO: wrong place for this logic
    if (shape instanceof DetectionZone) return (<DetectionZoneMapShape facility={facility} shape={shape} isSelectable={isSelectable} isEditable={isEditable} isSelected={isSelected} onUpdate={onUpdate} onClick={onClick} />);
    if (shape instanceof Spot) return (<ReferencePointMapShape facility={facility} shape={shape} isEditable={isEditable} isSelected={isSelected} isSelectable={isSelectable} onUpdate={onUpdate} onClick={onClick} />);
    throw new Error('Invalid shape for Map');
}