import cn from "classnames";
import { PropsWithChildren } from "react";
import { Spinner } from ".";
type LoadingIndicatorProps = {
    isLoading?: boolean;
    isModal?: boolean;
    message?: string;
};

export const LoadingIndicator: React.FC<PropsWithChildren<LoadingIndicatorProps>> = ({ isLoading = true, isModal = false, message = "Loading...", ...props }) => {
    return (
        <>
            {isLoading &&
                <div className={cn("flex gap-4 items-center place-content-center", { "modal-backdrop z-100": isModal })} >
                    <h1>{message}</h1>
                    <Spinner />
                </div>
            }
            {!isLoading && props.children}
        </>
    );
}