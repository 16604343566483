import { useContext, useMemo, useRef } from "react";
import { printElement } from "../../helpers/printHelper";
import { IconLink, Icons, PageContainer } from "../shared";
import { MaxHeightContainer } from "../shared/charts/MaxHeightContainer";
import { GroupedReportConfigurations } from "./ReportComponents";
import { ReportContext, useReportTitle } from "./reportHelpers";

export const PrintReportContainer: React.FC<{ dataSource: string }> = ({ dataSource }) => {
    const reportContainer = useRef(null);
    const { reportConfig, updateReportConfig } = useContext(ReportContext);
    if (!reportConfig) {
        throw new Error('must be used inside ReportContext');
    }
    const [title, titleRange] = useReportTitle(reportConfig);

    const titleElement = useMemo(() => {
        return title && (
            <h4><span className="mr-5">{title}</span>-
                {titleRange?.length && (<span className="ml-5">{titleRange}</span>)}
            </h4>);
    }, [title, titleRange]);

    const SelectedReport = useMemo(() => {
        const group = GroupedReportConfigurations.find(rc => rc.values.find(x => x.key === reportConfig.selectedReportId));
        return group?.values.find(x => x.key === reportConfig.selectedReportId);
    }, [reportConfig.selectedReportId]);
    const SelectedReportComponent = SelectedReport?.component;
    const downloadData = async () => {
        await SelectedReport!.downloadData!(reportConfig)
    };
    const toChart = (chartType: 'bars' | 'lines') => { updateReportConfig({ ...reportConfig, chartType: chartType }) };

    return (<div ref={reportContainer} >
        <PageContainer title={titleElement} titleRight={(<>
            {!(SelectedReport?.config?.disallowChartTypeToggling ?? false) && reportConfig.chartType !== 'other' && <><label className="mr-2">
                <input type="radio" name="chartType" value="bars" checked={reportConfig.chartType === 'bars'} onChange={e => toChart('bars')} className="mx-1" />
                Bars
            </label>
                <label className="mr-5">
                    <input type="radio" name="chartType" value="lines" checked={reportConfig.chartType === 'lines'} onChange={e => toChart('lines')} className="mx-1" />
                    Lines
                </label>
            </>}
            <button onClick={() => printElement(reportContainer, title, true)}><IconLink Icon={Icons.Calendar}>Print</IconLink></button>
            {SelectedReport?.downloadData && <button><IconLink onClick={downloadData} Icon={Icons.Download}>Download</IconLink></button>}
        </>)} >

            <MaxHeightContainer minHeight={300} margin={{ top: 0, bottom: 0, left: 0, right: -64 }}>{({ width, height }) => {
                return SelectedReportComponent ? (<SelectedReportComponent size={{ height: height, width: width }} title={dataSource} subTitle={title + ' ‎ ‏ ‎ ' + titleRange} />) : (<></>);
            }}</MaxHeightContainer>
        </PageContainer>
    </div>);
}
//TODO: move report type selection to separate component and report props should probably live in a context (need to learn more react)