import { CustomClaimValue, Device, Devices, DeviceStatus, DeviceStatusDisplayNames, getDeviceStatusDisplayName, HardwareStatuses, RadarConfiguration, SensorConfigType, toFixedMax } from "@app/shared";
import copy from "copy-to-clipboard";
import { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { useInterval } from "react-use";
import { DataInfoCard, DeviceCommandTable, EditDeviceDetails, LoadingIndicator, SkeletonGrid } from ".";
import { useFetch } from "../../hooks";
import { useEditModelDialog } from "../../hooks/useEditModelDialog";
import { AuthorizeView } from "./AuthorizeView";
import { CpuTemperatureChart, CpuUtilizationChart, PowerUsageChart, RamUsageChart } from "./charts";
import { DevicePortsTable } from "./DevicePortsTable";
import { IconLink } from "./IconLink";
import { Icons } from "./Icons";
import { Select, TextArea } from "./inputs";
import { LabeledText } from "./LabeledText";
import { PageContainer } from "./PageContainer";


type DeviceStatusViewProps = {
    device: Device | undefined;
    onChange?: (device: Device) => void;
};

export const DeviceStatusView: React.FC<DeviceStatusViewProps> = ({ device, onChange }) => {
    const [hoursToDisplay, setHoursToDisplay] = useState(1);
    //TODO: Maybe let the user choose this as well?
    const [groupByMinutes] = useState(5);
    const [commandsToShow, setCommandsToShow] = useState<number | "Pending">("Pending");
    const [portsToShow, setPortsToShow] = useState<number | "Open">("Open");
    const [deviceCommands, , deviceCommandHelper] = useFetch(
        () => device ?
            (Devices.getCommands(device?.id, commandsToShow === "Pending" ? 50 : commandsToShow as number, commandsToShow === "Pending"))
            : Promise.resolve(undefined),
        [commandsToShow, device]);
    const [devicePorts, , devicePortsHelper] = useFetch(
        () => device ?
            (Devices.getForwardedPorts(device?.id, portsToShow === "Open" ? 50 : portsToShow as number, portsToShow === "Open"))
            : Promise.resolve(undefined),
        [portsToShow, device]);
    const [statuses, , statusesHelper] = useFetch(() => device ? HardwareStatuses.getRecentForDevice(device?.id, 1) : Promise.resolve(undefined), [device]);
    const [cpuUsageHistory, cpuUsageError, cpuUseHelpers] = useFetch(() => device ? HardwareStatuses.getCpuUsageHistoryForDevice(device.id, hoursToDisplay, groupByMinutes) : Promise.resolve(undefined), [device, hoursToDisplay, groupByMinutes]);
    const [cpuTempHistory, cpuTempError, cpuTempHelpers] = useFetch(() => device ? HardwareStatuses.getCpuTempHistoryForDevice(device.id, hoursToDisplay, groupByMinutes) : Promise.resolve(undefined), [device, hoursToDisplay, groupByMinutes]);
    const [ramUsageHistory, ramError, ramHelpers] = useFetch(() => device ? HardwareStatuses.getRamUsageInfoForDevice(device.id, hoursToDisplay, groupByMinutes) : Promise.resolve(undefined), [device, hoursToDisplay, groupByMinutes]);
    const [powerUsageHistory, pwrError, pwrHelpers] = useFetch(() => device ? HardwareStatuses.getPowerUsageInfoForDevice(device.id, hoursToDisplay, groupByMinutes) : Promise.resolve(undefined), [device, hoursToDisplay, groupByMinutes]);
    const editDialog = useEditModelDialog(Device);
    const networkDialog = useEditModelDialog(Device);
    const radarDialog = useEditModelDialog(Device);

    //Interval to refresh the device commands 
    useInterval(() => {
        deviceCommandHelper.refreshData();
    }, 10000);

    const handleEditClick: MouseEventHandler = (e) => {
        editDialog.show(async (newDevice) => {
            await Devices.save(newDevice as Device);
            if (onChange)
                await onChange(newDevice as Device);
        }, "Edit Device", device);
    };
    const handleEditNetworkClick: MouseEventHandler = (e) => {
        networkDialog.show(async (newDevice) => {
            await Devices.save(newDevice as Device);
            if (onChange)
                await onChange(newDevice as Device);
        }, "Edit Device Network", device);
    };
    const handleEditRadarClick: MouseEventHandler = (e) => {
        radarDialog.show(async (newDevice) => {
            await Devices.save(newDevice as Device);
            if (onChange)
                await onChange(newDevice as Device);
        }, "Edit Device Radar", device);
    };

    const handleShowCommandsChanged: ChangeEventHandler<HTMLSelectElement> = (e) => {
        if (e.target.value === "Pending")
            setCommandsToShow("Pending");
        else
            setCommandsToShow(parseInt(e.target.value));
    }
    const handleShowPortsChanged: ChangeEventHandler<HTMLSelectElement> = (e) => {
        if (e.target.value === "Open")
            setPortsToShow("Open");
        else
            setPortsToShow(parseInt(e.target.value));
    }

    const getSecondsBetweenDates = (past: Date, future: Date) => {
        const ms = future.getTime() - past.getTime();
        return ms / 1000.0;
    }
    const getSecondsAsString = (seconds: number) => {
        const minutes = seconds / 60.0;
        const hours = minutes / 60.0;
        const days = hours / 24.0;

        if (days > 1) {
            return `${toFixedMax(days, 1)} Days`;
        } else if (hours > 1) {
            return `${toFixedMax(hours, 1)} Hours`;
        } else if (minutes > 1) {
            return `${toFixedMax(minutes, 1)} Mins`;
        } else {
            return `${toFixedMax(seconds, 1)} Secs`;
        }
    }

    const getAverage = (numbers: number[] | undefined) => {
        if (!numbers || numbers.length < 1)
            return undefined;

        const total = numbers.reduce((partialSum, val) => partialSum + val, 0);
        return total / numbers.length;
    }

    return (
        <>
            {((!statuses || statuses.length <= 0) && statusesHelper.isLoading) &&
                <>
                    <SkeletonGrid rows={1} cols={2} colClassNames="h-20 w-full"></SkeletonGrid>
                    <SkeletonGrid rows={1} cols={1} height={200} colClassNames="h-72 w-full"></SkeletonGrid>
                </>
            }
            {(statuses && statuses.length > 0) &&
                <>
                    <div className="md:flex gap-4 flex">
                        {device && device.status &&
                            <DataInfoCard Icon={Icons.NetworkWired} title="Status" hoverText={getStatusHoverText(device)}>{getDeviceStatusDisplayName(device.status)}</DataInfoCard>
                        }
                        {device && device.status !== DeviceStatus.LostConnection &&
                            <>
                                <DataInfoCard Icon={Icons.NetworkWired} title="CPU Usage">{getAverage(statuses[statuses.length - 1].processing?.coreUsages)?.toFixed(1)} %</DataInfoCard>
                                <DataInfoCard Icon={Icons.NetworkWired} title="Uptime">{getSecondsAsString(statuses[statuses.length - 1].uptime)}</DataInfoCard>
                            </>
                        }
                        {device && device.status === DeviceStatus.LostConnection &&
                            <DataInfoCard Icon={Icons.MessageArrows} title="Last Heartbeat">
                                {device.lastHeartbeat ? getSecondsAsString(getSecondsBetweenDates(device.lastHeartbeat, new Date())) : "Never"}
                            </DataInfoCard>
                        }
                    </div>
                    <PageContainer Icon={Icons.StatsDots} title="Hardware Status" titleRight={
                        <Select options={[
                            { value: "1", label: "1 Hour" },
                            { value: "4", label: "4 Hours" },
                            { value: "8", label: "8 Hours" },
                            { value: "12", label: "12 Hours" },
                            { value: "24", label: "1 Day" },
                            { value: "72", label: "3 Days" },
                            { value: "168", label: "1 Week" }
                        ]} value={hoursToDisplay} onChange={(e) => setHoursToDisplay(parseInt(e.target.value))} />
                    }>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3">
                            {(cpuUseHelpers.isLoading && !cpuUsageError?.length) ? <LoadingIndicator /> : <CpuUtilizationChart data={cpuUsageHistory} title="CPU Utilization" />}
                            {(cpuTempHelpers.isLoading && !cpuTempError?.length) ? <LoadingIndicator /> : <CpuTemperatureChart data={cpuTempHistory} title="Temperature" />}
                            {(ramHelpers.isLoading && !ramError?.length) ? <LoadingIndicator /> : <RamUsageChart data={ramUsageHistory} title="RAM Usage" />}
                            {(pwrHelpers.isLoading && !pwrError?.length) ? <LoadingIndicator /> : <PowerUsageChart data={powerUsageHistory} title="Power Usage" />}
                        </div>
                    </PageContainer>
                </>
            }
            <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
                <PageContainer Icon={Icons.Settings} title="Device Configuration" titleRight={<IconLink Icon={Icons.Edit} onClick={handleEditClick}>Edit</IconLink>}>
                    <div className="grid grid-cols-2 gap-3">
                        <LabeledText label="Device Key">{device?.deviceKey}<IconLink className="ml-3" Icon={Icons.Clipboard} onClick={() => copy(device?.deviceKey ?? "")} /></LabeledText>
                        <LabeledText label="Device Id">{device?.id}<IconLink className="ml-3" Icon={Icons.Clipboard} onClick={() => copy(device?.id ?? "")} /></LabeledText>
                        <LabeledText label="Firmware Version">{device?.firmwareVersion}</LabeledText>
                        <LabeledText label="Hardware Version">{device?.hardwareVersion}</LabeledText>
                        <LabeledText label="Desired Heading">{device?.headingDegrees}°</LabeledText>
                        <LabeledText label="Desired Tilt">{device?.desiredTiltDegrees}°&nbsp;
                            {(statuses && statuses.length > 0 && statuses[0].orientation && statuses[0].orientation.error === 0) &&
                                <span className="text-muted-body-text">(Actual: {statuses[0].orientation?.pitch * .0572958}°)</span>
                            }
                        </LabeledText>
                        <LabeledText label="Height">{device?.height}m</LabeledText>
                        <LabeledText label="Offset from Origin">({device?.positionX}m, {device?.positionY}m)</LabeledText>
                        <LabeledText label="Take Target Photos">{device?.takeTargetPhotos ? 'true' : 'false'}</LabeledText>
                        <LabeledText label="Always Stream Video">{device?.alwaysStreamVideo ? 'true' : 'false'}</LabeledText>
                    </div>
                </PageContainer>
                <PageContainer Icon={Icons.NetworkWired} title="Network Configuration" titleRight={<IconLink Icon={Icons.Edit} onClick={handleEditNetworkClick}>Edit</IconLink>}>
                    <div className="grid grid-cols-2 gap-3">
                        <LabeledText label="DHCP Enabled">{device?.enableDhcp ? "Yes" : "No"}</LabeledText>
                        <LabeledText label="MAC Address">{device?.macAddress}{device?.macAddress && <IconLink className="ml-3" Icon={Icons.Clipboard} onClick={() => copy(device?.macAddress ?? "")} />}</LabeledText>
                        <LabeledText className="col-start-1" label="IP Address">{device?.ipv4Address}{device?.ipv4Address && <IconLink className="ml-3" Icon={Icons.Clipboard} onClick={() => copy(device?.ipv4Address ?? "")} />}</LabeledText>
                        <LabeledText label="Gateway IPs">{device?.gatewayAddresses}</LabeledText>
                        <LabeledText label="DNS Servers">{device?.dnsServers}</LabeledText>
                        <LabeledText label="NTP Servers">{device?.ntpServers}</LabeledText>
                        <LabeledText label="Heartbeat Frequency">{device?.secondsBetweenHeartbeat}s</LabeledText>

                    </div>
                </PageContainer>
                {(device?.sensorConfig?.configType === SensorConfigType.Radar) && <AuthorizeView claim={CustomClaimValue.SuperAdmin}>
                    <PageContainer Icon={Icons.Satellite} title="Radar Configuration" titleRight={<IconLink Icon={Icons.Edit} onClick={handleEditRadarClick}>Edit</IconLink>}>
                        <div className="grid grid-cols-2 gap-3">
                            <LabeledText label="Enabled">{(device?.sensorConfig as RadarConfiguration)?.transmitterEnabled ? 'true' : 'false'}</LabeledText>
                            <LabeledText label="Calibrated">{((device?.sensorConfig as RadarConfiguration)?.calibrationConfig?.length ?? 0) > 0 ? 'true' : 'false'}</LabeledText>
                            <LabeledText label="Seconds to Transmit per Minute">{(device?.sensorConfig as RadarConfiguration)?.secondsToTransmitPerMinute}</LabeledText>
                            <LabeledText label="Minimum Seconds to Transmit">{(device?.sensorConfig as RadarConfiguration)?.minimumSecondsToTransmit}</LabeledText>
                            <LabeledText className="col-span-2" label="CHIRP Config"><TextArea disabled rows={10} label="CHIRP Config" value={(device?.sensorConfig as RadarConfiguration)?.chirpConfig} /></LabeledText>
                        </div>
                    </PageContainer></AuthorizeView>}
            </div>

            <PageContainer Icon={Icons.Message} title="Forwarded Ports" titleRight={
                <div className="flex justify-center items-center gap-3">
                    <Select options={
                        [
                            { "label": "Open", "value": "Open" },
                            { "label": "Last 50", "value": 50 },
                            { "label": "Last 100", "value": 100 }
                        ]}
                        value={portsToShow}
                        onChange={handleShowPortsChanged} />
                </div>
            }>
                <DevicePortsTable ports={devicePorts} isLoading={devicePortsHelper.isLoading} />
            </PageContainer>
            <PageContainer Icon={Icons.Message} title="Device Commands" titleRight={
                <div className="flex justify-center items-center gap-3">
                    <Select options={
                        [
                            { "label": "Pending", "value": "Pending" },
                            { "label": "Last 50", "value": 50 },
                            { "label": "Last 100", "value": 100 }
                        ]}
                        value={commandsToShow}
                        onChange={handleShowCommandsChanged} />
                </div>
            }>


                <DeviceCommandTable commands={deviceCommands} isLoading={deviceCommandHelper.isLoading} onCommandChanged={() => deviceCommandHelper.refreshData()} />
            </PageContainer>
            {editDialog.renderDialog((model, helpers) => (
                <EditDeviceDetails section="general" device={model} helpers={helpers} />
            ))}
            {networkDialog.renderDialog((model, helpers) => (
                <EditDeviceDetails section="network" device={model} helpers={helpers} />
            ))}
            {radarDialog.renderDialog((model, helpers) => (
                <EditDeviceDetails section="radar" device={model} helpers={helpers} />
            ))}
        </>
    );
}

export const getStatusHoverText = (d: Device | undefined) => {
    const status = DeviceStatusDisplayNames[d?.status ?? 0];
    if (d?.lastStatusChange) {
        const locale = navigator.languages?.[0] ?? 'en-US';
        const time = new Intl.DateTimeFormat(locale, { dateStyle: 'short', timeStyle: 'long', }).format(d.lastStatusChange);
        if (d.lastStatusChange > new Date(2020, 0, 1)) {
            return <><span className="font-semibold">{status}</span><span>{' as of ' + time}</span></>;
        }
    }
    return <span className="font-semibold">{status}</span>;
}
