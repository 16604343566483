import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Color, ColorChangeHandler, SketchPicker } from "react-color";
type ColorPickerProps = {
    onChange: (color: string) => void;
    value: string;
    disabled?: boolean;
};

export const ColorPicker: React.FC<ColorPickerProps> = (props) => {
    const [displayPicker, setDisplayPicker] = useState(false);
    const [color, setColor] = useState<Color>(props.value);
    const isMouseOver = useRef<boolean>();

    useEffect(() => {
        setColor(props.value)
    }, [props.value]);

    const handleClick: EventListener = (e: Event) => {

        if (!isMouseOver.current)
            setDisplayPicker(false);
        else
            e.preventDefault();
    }

    //When the display picker is showing, make sure we have an event handler to detect clicks (so we can close if they click outside)
    useEffect(() => {
        document.removeEventListener("mousedown", handleClick);
        if (displayPicker) {
            document.addEventListener("mousedown", handleClick);
        }
        return () => { document.removeEventListener("mousedown", handleClick) };
    }, [displayPicker]); //eslint-disable-line react-hooks/exhaustive-deps


    const handleOnClick = () => {
        if (props.disabled) { return; }
        setDisplayPicker((prev) => !prev);
    };
    const handleOnChange: ColorChangeHandler = (color) => {
        props.onChange(color.hex);
    };
    const onMouseEnter: MouseEventHandler<HTMLDivElement> = () => {
        isMouseOver.current = true;
    };
    const onMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
        isMouseOver.current = false;
    }
    const bgColor = props.disabled ? 'bg-[#EFEFEF]' : 'bg-background-page';

    return (
        <div>
            <div className={`p-3 border inline-block shadow cursor-pointer ${bgColor} rounded border-separators-page`}
                onClick={handleOnClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
                <div className="w-9 h-9 rounded" style={{ backgroundColor: color.toString() }} />
            </div>
            {displayPicker &&
                <div className="relative z-100">
                    <div className="inline-flex"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}>
                        <SketchPicker color={color} onChange={handleOnChange} />
                    </div>
                </div>
            }

        </div>
    );
}