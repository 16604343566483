import { PropsWithChildren } from "react";
import { IconType } from "react-icons";

type SimpleCardProps = {
    Icon: IconType;
    title: string;
} & PropsWithChildren;

export const SimpleCard: React.FC<SimpleCardProps> = (props) => {
    return (
        <>
            <div className="border border-separators-page p-5 flex gap-3 min-w-min flex-grow shadow-sm">
                <div className="text-center bg-background-page-highlight-gray rounded-full h-16 w-16 flex flex-col items-center">
                    <props.Icon className="h-10 w-10 mx-auto my-auto" />
                </div>
                <div className="flex flex-col flex-grow text-right gap-1">
                    <p className="uppercase text-lg whitespace-nowrap">{props.title}</p>
                    {props.children}
                </div>
            </div>
        </>
    );
}