/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post, del } from "./api_client";
import { FacilityGroup, IFacilityGroup, OptionValue, IOptionValue } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getById(id?: string): ApiResult<FacilityGroup> {
	return get<IFacilityGroup>(`/api/facilitygroup/${ id }`).then(result => result === undefined ? undefined :  new FacilityGroup(result));
}

export async function getByOrganizationId(orgId?: string): ApiResult<FacilityGroup[]> {
	return get<IFacilityGroup[]>(`/api/facilitygroup/for-organization/${ orgId }`).then(result => result === undefined ? undefined : result.map(o => new FacilityGroup(o)));
}

export async function save(facilityGroup: FacilityGroup): ApiResult<string> {
	return post<string>(`/api/facilitygroup`, facilityGroup);
}

export async function getAllFacilityGroupOptions(): ApiResult<OptionValue[]> {
	return get<IOptionValue[]>(`/api/facilitygroup/options`).then(result => result === undefined ? undefined : result.map(o => new OptionValue(o)));
}

export async function deleteGroup(id?: string): ApiResult<boolean> {
	return del<boolean>(`/api/facilitygroup/${ id }`);
}
