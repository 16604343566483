import { ClientEndpoint, ClientEndpoints, EndpointType, Facility, getEndpointTypeDisplayName, getTriggerTypeDisplayName, MaxServerIntValue, TriggerType } from "@app/shared";
import { useConfirmDialog, useFetch } from "../../../hooks";
import { useEditModelDialog } from "../../../hooks/useEditModelDialog";
import { CheckboxInput, EnumSelect, IconLink, Icons, Input, InputRow, NumericInput, PageContainer, SortableTable } from "../../shared";
import { DropDownMenu } from "../../shared/DropDownMenu";
import { TargetTypeFilterInput } from "./TargetTypeFilterInput";

type ClientEndpointTableProps = {
    facility: Facility;
};

export const ClientEndpointTable: React.FC<ClientEndpointTableProps> = (props) => {
    const [endpoints, , endpointHelpers] = useFetch(() => ClientEndpoints.getByFacilityId(props.facility.id));
    const endpointDialog = useEditModelDialog(ClientEndpoint);
    const confirmDialog = useConfirmDialog();

    const handleAddEndpointOnClick = () => {
        endpointDialog.show(async (toSave: Partial<ClientEndpoint>) => {
            await ClientEndpoints.update(toSave as ClientEndpoint);
            await endpointHelpers.refreshData();
        }, "Add Endpoint", { facilityId: props.facility.id, targetFilter: MaxServerIntValue } as Partial<ClientEndpoint>);
    };

    const handleEditEndpointOnClick = (toEdit: ClientEndpoint | undefined) => {
        if (!toEdit) return;

        endpointDialog.show(async (toSave: Partial<ClientEndpoint>) => {
            await ClientEndpoints.update(toSave as ClientEndpoint);
            await endpointHelpers.refreshData();
        }, "Edit Endpoint", toEdit);
    };

    const handleDeleteEndpointOnClick = (toDelete: ClientEndpoint | undefined) => {
        if (!toDelete) return;
        confirmDialog.show("Delete Endpoint?", "Are you sure you want to delete this endpoint?", "Delete",
            async () => {
                await ClientEndpoints.deleteEndpoint(toDelete.id);
                await endpointHelpers.refreshData();
            });
    };

    return (
        <>
            <PageContainer Icon={Icons.Api} title="Client Endpoints" titleRight={<IconLink Icon={Icons.PlusCircle} onClick={handleAddEndpointOnClick}>Add New</IconLink>}>
                <SortableTable columns={[
                    {
                        header: "Event",
                        dataKey: "trigger",
                        width: 150,
                        renderFunc: (e) => getTriggerTypeDisplayName(e!.trigger)
                    },
                    {
                        header: "Targets",
                        dataKey: "targetFilterDescription",
                        width: 250
                    },
                    {
                        header: "Type",
                        dataKey: "endpointType",
                        renderFunc: (e) => getEndpointTypeDisplayName(e!.endpointType),
                        width: 75,
                    },
                    {
                        header: "URL",
                        dataKey: "endpointUrl",
                        width: 300
                    },
                    {
                        header: "",
                        width: 25,
                        renderFunc: (e) => (
                            <DropDownMenu>
                                <IconLink onClick={() => handleEditEndpointOnClick(e)} Icon={Icons.Edit}>Edit</IconLink>
                                <IconLink onClick={() => handleDeleteEndpointOnClick(e)} Icon={Icons.Trash}>Delete</IconLink>
                            </DropDownMenu>
                        )
                    }
                ]}
                    data={endpoints} />
            </PageContainer>
            {endpointDialog.renderDialog((model, helpers) => (
                <>
                    <InputRow>
                        <EnumSelect label="Trigger" nameToDisplayConverter={getTriggerTypeDisplayName} enumType={TriggerType} {...helpers.bindingsFor("trigger")} />
                    </InputRow>
                    <InputRow>
                        <EnumSelect label="Endpoint Type" enumType={EndpointType} {...helpers.bindingsFor("endpointType")} />
                    </InputRow>
                    <InputRow>
                        <Input label="Endpoint URL" {...helpers.bindingsFor("endpointUrl")} />
                    </InputRow>
                    <InputRow>
                        <Input label="HMAC Secret" {...helpers.bindingsFor("sharedSecretKey")} />
                    </InputRow>
                    <InputRow>
                        <Input label="Custom Auth Header" {...helpers.bindingsFor("customAuthHeader")} />
                    </InputRow>
                    <InputRow>
                        <CheckboxInput label="Enable Heartbeat" checked={model.enableHeartbeat} onChange={(e) => helpers.updateModel("enableHeartbeat", e.target.checked)} />
                    </InputRow>
                    <InputRow>
                        <NumericInput label="Seconds Between Heartbeat" {...helpers.bindingsFor("secondsBetweenHearbeat")} />
                    </InputRow>
                    <InputRow>
                        <TargetTypeFilterInput label="Target Types" {...helpers.bindingsFor("targetFilter")} />
                    </InputRow>

                </>
            ))}
        </>
    );
}