import { Button, ButtonVariants } from "@app/shared";
import { FC, MouseEventHandler, PropsWithChildren, ReactNode, useState } from "react";
import { ErrorAlert } from ".";
import { Icons } from "..";
import { Modal } from "./Modal";


export type DialogProps = {
    show: boolean,
    title: string,
    disableButtons?: boolean,
    onOkClick?: MouseEventHandler,
    onCancelClick?: MouseEventHandler,
    closeOnOutsideClick?: boolean,
    showCancelButton?: boolean,
    okButtonText?: string,
    children: ReactNode,
    className?: string
};

export const Dialog: FC<PropsWithChildren<DialogProps>> = (props) => {
    var [disableButtons, setDisableButtons] = useState(false);
    const [onClickError, setOnClickError] = useState<string>();
    const onClickOutsideHandler = (props.closeOnOutsideClick ?? true) ? props.onCancelClick : undefined;
    const handleOkClick: MouseEventHandler = async (event) => {
        setOnClickError(undefined);
        setDisableButtons(true);
        if (props.onOkClick) {
            try {
                await props.onOkClick(event);
            } catch (e) {
                setOnClickError(e + "");
                console.log(e);
            }
        }
        //If the onOkClick hides the dialog, we may not have state to update anymore
        try {
            setDisableButtons(false);
        } catch { }
    }
    const handleClickOutside: MouseEventHandler = async (event) => {
        if (disableButtons || props.disableButtons)
            return;
        setOnClickError(undefined);
        setDisableButtons(true);
        if (onClickOutsideHandler)
            await onClickOutsideHandler(event);
        try {
            setDisableButtons(false);
        } catch { }
    }
    const handleCancelClick: MouseEventHandler = async (event) => {
        setOnClickError(undefined);
        setDisableButtons(true);
        if (props.onCancelClick) {
            await props.onCancelClick(event);
        }
        try {
            setDisableButtons(false);
        } catch { }
    }
    return (
        <Modal show={props.show} onClickOutside={handleClickOutside} className={props.className}>
            <div className={"flex flex-col z-50"}>
                <div className="flex py-5 px-5 border-b border-separators-page bg-background-dialog-title rounded-t text-bright-text ">
                    <div className="flex flex-initial">
                        <h1>{props.title}</h1>
                    </div>
                    <div className="flex flex-grow justify-end">
                        {(props.onCancelClick && !disableButtons && !props.disableButtons) &&
                            <div onClick={handleCancelClick} className="cursor-pointer text-muted-body-text hover:text-link-page">
                                <Icons.Close size={32} />
                            </div>
                        }
                    </div>
                </div>
                <div className="px-5 py-5 flex-grow">
                    <ErrorAlert errors={onClickError} />
                    {props.children}
                </div>
                <div className="flex justify-end py-3 pr-5 border-t border-separators-page bg-background-dialog-footer">
                    <Button onClick={handleOkClick} disabled={props.disableButtons || disableButtons}>{props.okButtonText ?? "OK"}</Button>
                    {
                        (props.onCancelClick && (props.showCancelButton ?? true)) &&
                        <Button onClick={handleCancelClick} disabled={props.disableButtons || disableButtons} variant={ButtonVariants.Secondary}>Cancel</Button>
                    }
                </div>
            </div>
        </Modal>
    );
}
