import { PropsWithChildren } from "react";

type LabeledTextProps = {
    label: string | React.ReactNode | React.ReactFragment;
    labelClassName?: string;
    className?: string;
    childClassName?: string;
};

export const LabeledText: React.FC<PropsWithChildren<LabeledTextProps>> = ({ label, children, labelClassName, className, childClassName }) => {

    return (
        <div className={"flex-row " + className}>
            <label className={labelClassName ?? " text-sm text-muted-body-text "}>{label}:</label>
            <div className={childClassName ?? "pl-2 py-2"}>
                {children}
            </div>
        </div>
    );

}