import { enumKeys } from "@app/shared";
import _ from "lodash";
import { PropsWithChildren } from "react";
import { Select, SelectProps } from "./";

type EnumSelectProps<T> = {
    enumType: T,
    keyFilter?: (key: string | number) => boolean,
    nameToDisplayConverter?: (key: string & keyof T) => string | undefined,
    value: (string | number | undefined),
    onChange: (val: (number | undefined)) => void
} & Omit<SelectProps, "onChange" | "value">;

export const EnumSelect = <T extends { [key: string]: number | string }>(props: PropsWithChildren<EnumSelectProps<T>>) => {
    const { enumType, children, nameToDisplayConverter, keyFilter, onChange, value, ...selectProps } = props;
    return (
        <Select
            value={value !== undefined ? value : ""}
            onChange={(e) => onChange(parseInt(e.target.value))}
            {...selectProps}
            options={enumKeys(enumType).filter((k) => !keyFilter || keyFilter(enumType[k])).map((key) => (
                { value: enumType[key], label: nameToDisplayConverter ? nameToDisplayConverter(key) : _.replace(key, /_/g, " ") }
            ))}
        >
            {children}
        </Select>
    );
}