export enum SensorType {
	IWR6843 = 0,
	AWR6843 = 1,
}

export const SensorTypeDisplayNames: { [key in keyof typeof SensorType]?: string} = {
	"IWR6843": "IWR6843",
	0 : "IWR6843",
	"AWR6843": "AWR6843",
	1 : "AWR6843",
}

export const getSensorTypeDisplayName = (key: SensorType | (keyof typeof SensorType & string)) => {
	return SensorTypeDisplayNames[key];
}

