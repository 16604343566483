import { AggregationLevel, Reports, TotalVolumeByPeriod, TrafficReport, TrafficReportConfiguration } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { TotalVolumeChart } from "./TotalVolumeDataLoader";
import { format, parse } from "date-fns";
import { useContext, useState } from "react";
import { ReportContext } from "./reportHelpers";
import { formatInTimeZone } from "date-fns-tz";

function getTitledData(zone: string, setTitle: React.Dispatch<React.SetStateAction<string>>) {
    return function getHourlyData(props: TrafficReportConfiguration) {
        return Reports.busiestDay(props).then(r => {

            const data = r?.map(x => ({
                ogDate: adjustDate(x),
                periodStart: adjustDate(x),
                count: x.count,
                targetType: x.targetType
            } as TotalVolumeByPeriod))
            if (r?.length) {
                setTitle(buildTitle(zone, adjustDate(r[0])!, props))
            }
            return data;
        });
    }
}

function buildTitle(zone: string, d: Date, props: TrafficReportConfiguration) {
    return 'Total Volume ' + formatInTimeZone(d, zone, 'MMMM d yyyy');
}
function adjustDate(x: TrafficReport): Date | undefined {
    return new Date(Date.UTC(x.year ?? 2020, x.month ? x.month - 1 : 0, x.day ?? 1, x.hour));
}

export const BusiestDayReportLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { searchParams, reportConfig } = useContext(ReportContext);
    const [title, setTitle] = useState('Busiest Day');
    return (<TotalVolumeChart
        {...props}
        aggregationLevel={AggregationLevel.Hour}
        dataSource={getTitledData(reportConfig.chartTimezone, setTitle)}
        groupMapFunction={d => formatInTimeZone(d.periodStart!, reportConfig.chartTimezone, 'HH')}
        groupToDate={d => parse(format(reportConfig.inclusiveStart!, 'yyyy/MM/dd') + '/' + d, 'yyyy/MM/dd/HH', new Date())}
        tickLabeler={str => format(new Date(str), 'H:mm')}
        toolTipDateFormatter={str => format(new Date(str), 'HH:mm')}
        title={title}
    />);
}

export const BusiestDayReport = {
    name: "Busiest Day Report",
    description: "This report shows the volume of the selected traffic types on the busiest day in the selected range",
    component: BusiestDayReportLoader,
    defaultChartType: 'bars',
    defaultRange: DateOptions.ThisYear,
    key: 'bdr',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true
    }
} as IConfiguredTrafficReport;