import cn from "classnames";
import { FC, MouseEventHandler, PropsWithChildren, ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { Icons } from ".";

export type DropDownMenuProps = {
    toggleChild?: ReactNode;
    dropDownClassNames?: string;
    isDisabled?: boolean;
    closeOnSelect?: boolean;
    className?: string;
    onShow?: () => void | any;
    onHide?: () => void | any;
};

export const DropDownMenuContainerId = "drop-down-wrapper";

export const DropDownMenu: FC<PropsWithChildren<DropDownMenuProps>> = ({ closeOnSelect = true, children, ...props }) => {
    const [showMenu, setShowMenu] = useState(false);
    const isMouseOver = useRef(false);
    const popperButtonRef = useRef(null);
    const popperDivRef = useRef(null);
    const popper = usePopper(popperButtonRef.current, popperDivRef.current, {
        modifiers: [{
            name: 'offset',
            options: {
                offset: [0, 10],
            }
        }]
    });

    const closeMenu = () => {
        try {
            if (props.onHide) {
                props.onHide();
            }
            setShowMenu(false);
        } catch { }
        document.removeEventListener('click', handleClick);
        document.removeEventListener('ddMenu:onShow', handleOnShowEvent);
    };

    const handleClick = () => {
        if (closeOnSelect || !isMouseOver.current)
            closeMenu();
    }
    const handleOnShowEvent = () => {
        //We are not registered for this until after we are shown.  If this event is received another drop down is being opened, so we should close
        closeMenu();
    }

    useEffect(() => {
        //Whenever the children change, we need to notify popper to reposition!
        if (popper && popper.forceUpdate && showMenu) {
            popper.forceUpdate();
        }

    }, [children]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('ddMenu:onShow', handleOnShowEvent);
        if (showMenu) {
            document.addEventListener('click', handleClick);
            document.addEventListener('ddMenu:onShow', handleOnShowEvent);
        }
        return () => {
            document.removeEventListener("click", handleClick);
            document.removeEventListener("ddMenu:onShow", handleOnShowEvent);
        };
    }, [showMenu]); //eslint-disable-line react-hooks/exhaustive-deps

    const handleIconClick: MouseEventHandler = (event) => {
        event.stopPropagation();

        if (props.isDisabled === true)
            return;

        if (showMenu) {
            closeMenu();
            return;
        }

        document.dispatchEvent(new Event('ddMenu:onShow'));

        if (props.onShow)
            props.onShow();
        setShowMenu(true);
    };

    const renderDropDownContents = () => {
        return (
            <div ref={popperDivRef} onClick={(e) => { e.stopPropagation(); handleClick(); }}
                style={{ ...popper.styles.popper, zIndex: 999 }}
                className={cn("flex flex-col border border-separators-page shadow-lg absolute bg-dialog-background overflow-y-auto z-100 rounded",
                    props.dropDownClassNames ?? " space-y-2 p-4 ",
                    showMenu ? " visible" : " invisible")}
                {...popper.attributes.popper} >
                {children}
            </div>
        );
    }

    var el = document.getElementById(DropDownMenuContainerId);
    if (!el) {
        console.warn("Unable to find container for drop downs.");
    }

    return (
        <div
            onMouseEnter={() => { isMouseOver.current = true; }}
            onMouseLeave={() => { isMouseOver.current = false; }}
            className={cn("h-full flex flex-row justify-items-center place-items-end", props.className)}
        >
            <div ref={popperButtonRef} onClick={handleIconClick} className="cursor-pointer text-link-page hover:text-link-page-hover my-auto">
                {props.toggleChild ??
                    <div className="flex flex-col place-items-center place-content-center justify-items-center pr-2">
                        <Icons.OverflowMenu className="my-auto" size={23} />
                    </div>
                }
            </div>
            {el && createPortal(renderDropDownContents(), el)}
            {!el && renderDropDownContents()}
        </div>
    );
}