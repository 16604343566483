import { Single, ISingle } from "./System.Single.gen";

export interface ICpuTempInfo {
	statusTimestamp: string | undefined;

	cpu: number | undefined;

	pll: number | undefined;

	wifi: number | undefined;

	pmic: number | undefined;

	gpu: number | undefined;

	thermal: number | undefined;

	ao: number | undefined;

	$type?: string | undefined;
}
export class CpuTempInfo {
	constructor(data?: ICpuTempInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.statusTimestamp) { this.statusTimestamp = new Date(data.statusTimestamp); }
	}

	statusTimestamp: Date | undefined = new Date();

	cpu: number | undefined;

	pll: number | undefined;

	wifi: number | undefined;

	pmic: number | undefined;

	gpu: number | undefined;

	thermal: number | undefined;

	ao: number | undefined;

	static $type: string = "CoreApi.Models.View.Hardware.CpuTempInfo, ";
	$type?: string | undefined = CpuTempInfo.$type;
}

