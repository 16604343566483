import { CustomClaimValue, DeviceNote } from "@app/shared";
import { ModelHelpers } from "../../hooks";
import { AuthorizeView } from "./AuthorizeView";
import { InputRow, TextArea } from "./inputs";

type EditDeviceNoteProps = {
    note: Partial<DeviceNote>;
    helpers: ModelHelpers<DeviceNote>;
};

export const EditDeviceNote: React.FC<EditDeviceNoteProps> = ({ note, helpers }) => {
    return (
        <>
            <AuthorizeView claim={CustomClaimValue.SuperAdmin}>
                <InputRow><TextArea label="Note" {...helpers.bindingsFor("note")} /></InputRow>
            </AuthorizeView>
        </>
    );
}