import { DetectionZone, DetectionZoneType, Device, Ruler, ScreenLine, Spot } from "@app/shared";
import { IconType } from "react-icons";
import { Icons } from "../Icons";
import { Shape } from "./SvgShape";

type ShapeType<T> = { new(): T };

export enum EditToolType { Spot, DetectionZone, ScreenLine, Ruler, Device, ParkingSpot };
export enum EditToolPurpose { Scaling, Layout };

export class EditTool<T extends {} & Shape>
{
    public toolPurpose: EditToolPurpose;
    public toolType: EditToolType;
    public name: string;
    public icon: IconType;
    public shape: ShapeType<T>;

    public static ReferencePoint = new EditTool(EditToolPurpose.Scaling, EditToolType.Spot, "Reference Point", Icons.MapMarker, Spot);
    public static DetectionZone = new EditTool(EditToolPurpose.Layout, EditToolType.DetectionZone, "Detection Zone", Icons.Plus, DetectionZone);
    public static ParkingSpot = new EditTool(EditToolPurpose.Layout, EditToolType.ParkingSpot, "Parking Spot", Icons.Plus, DetectionZone);
    public static ScreenLine = new EditTool(EditToolPurpose.Layout, EditToolType.ScreenLine, "Screen Line", Icons.Plus, ScreenLine);
    public static ReferenceDistance = new EditTool(EditToolPurpose.Scaling, EditToolType.Ruler, "Scale Reference", Icons.Ruler, Ruler);
    public static Device = new EditTool(EditToolPurpose.Layout, EditToolType.Device, "Device", Icons.Video, Device);

    public constructor(toolPurpose: EditToolPurpose, toolType: EditToolType, name: string, icon: IconType, shape: ShapeType<T>) {
        this.toolPurpose = toolPurpose;
        this.name = name;
        this.toolType = toolType;
        this.icon = icon;
        this.shape = shape;
    }

    public createShape(id: number) {
        var retVal = new this.shape();
        //Assign a temp ID so we can use it for keys/edits.  We will leave it up to our parent to make sure these do not collide!
        retVal.id = id.toString();
        retVal.name = `${this.name} ${id}`;
        if (this.toolType === EditToolType.DetectionZone) {//TODO: find a better home for this
            (retVal as DetectionZone).zoneType = DetectionZoneType.ParkingArea;
        }
        return retVal;
    }
};

export function findToolByShape(shape: Shape) {
    let tool = EditTools.find(et => (et.shape as unknown as Shape).$type === shape.$type);
    if (shape.$type === DetectionZone.$type) {
        if ((shape as DetectionZone).zoneType === DetectionZoneType.ParkingSpot) {
            return EditTool.ParkingSpot;
        } else {
            return EditTool.DetectionZone;
        }
    }
    return tool;
};

export const EditTools = [
    EditTool.ReferencePoint,
    EditTool.DetectionZone,
    EditTool.ScreenLine,
    EditTool.ParkingSpot,
    EditTool.ReferenceDistance,
    //EditTool.Device,
];
