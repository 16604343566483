import { addDays, format, parse } from "date-fns";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { TotalVolumeChart } from "./TotalVolumeDataLoader";
import { TotalVolumeByHour } from "./TotalVolumeByHour";
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { useContext } from "react";
import { ReportContext } from "./reportHelpers";
import { AggregationLevel } from "@app/shared";
import { SelectedReportConfiguration } from "./ReportComponents";

const ChartDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, updateReportConfig } = useContext(ReportContext);

    function handleClick(date: Date): void {

        const startLocal = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const end = addDays(new Date(startLocal), 1);


        const cfg = {
            ...reportConfig,
            selectedReportId: TotalVolumeByHour.key,
            inclusiveStart: zonedTimeToUtc(startLocal, reportConfig.chartTimezone),
            exclusiveEnd: zonedTimeToUtc(end, reportConfig.chartTimezone)
        } as SelectedReportConfiguration;
        
        updateReportConfig(cfg);
    }

    return (<TotalVolumeChart
        {...props}
        aggregationLevel={AggregationLevel.Day}
        groupMapFunction={d => formatInTimeZone(d.periodStart!, reportConfig.chartTimezone, 'yyyy/MM/dd')}
        groupToDate={d => parse(d, 'yyyy/MM/dd', new Date())}//dis one good
        tickLabeler={str => format(new Date(str), 'dd')}//we working in local dates here
        toolTipDateFormatter={str => format(new Date(str), 'MMM dd')}
        onClick={handleClick}
    />);
}


export const TotalVolumeByDay: IConfiguredTrafficReport = {
    name: "by Day",
    key: "tvd",
    description: "This report shows total traffic for the specified days",
    defaultChartType: 'lines',
    component: ChartDataLoader,
    defaultRange: DateOptions.ThisMonth,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};