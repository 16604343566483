export interface IRadarTemperatureInfo {
	id: string | undefined;

	timeStamp: number;

	rx0: number;

	rx1: number;

	rx2: number;

	rx3: number;

	tx0: number;

	tx1: number;

	tx2: number;

	pm: number;

	digital0: number;

	digital1: number;

	$type?: string | undefined;
}
export class RadarTemperatureInfo {
	constructor(data?: IRadarTemperatureInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	timeStamp: number = 0;

	rx0: number = 0;

	rx1: number = 0;

	rx2: number = 0;

	rx3: number = 0;

	tx0: number = 0;

	tx1: number = 0;

	tx2: number = 0;

	pm: number = 0;

	digital0: number = 0;

	digital1: number = 0;

	static $type: string = "CoreApi.Models.Hardware.RadarTemperatureInfo, ";
	$type?: string | undefined = RadarTemperatureInfo.$type;
}

