import { format, parse, startOfDay, subDays } from "date-fns";
import { DateOptions, TrafficReportProps } from "../../reports/IConfiguredTrafficReport";
import { TotalVolumeChart } from "../../reports/TotalVolumeDataLoader";
import { AllTargetTypes, ReportContext, stackedTooltipBuilder } from "../../reports/reportHelpers";
import { zonedTimeToUtc } from "date-fns-tz";
import { AggregationLevel, Device, Facility, TotalVolumeByPeriod, TrafficReportConfiguration, useAuth } from "@app/shared";
import { SelectedReportConfiguration } from "../../reports/ReportComponents";
import { useScreenSize } from '@visx/responsive';

export type TargetEvent = {
    date: Date | undefined;
    counts: { [key: string]: number };
    speeds: { [key: string]: number };
}
export type FacilityEventsChartProps = {
    device: Device,
    id?: string;
    title?: string;
    facility: Facility,
    data: Array<TargetEvent> | undefined;
    isLoading?: boolean;
} & ChartSizeProps;

export type ChartSizeProps = {
    width?: number;
    height?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
}


export function FacilityEventsChart<T extends object>({ facility, device, id, title, data = [], isLoading, width, height = 300, margin = { top: 10, right: 60, bottom: 50, left: 60 } }: FacilityEventsChartProps) {
    const { width: screenWidth } = useScreenSize({ debounceTime: 150 });
    const { numberFormatter } = useAuth();
    const start = startOfDay(subDays(new Date(), 13));
    const facilityTimezone = facility.localTimezoneId ?? 'UTC';

    const reportConfig = { selectedReportId: 'tvd', selectedDateId: DateOptions.Custom, context: { facility: facility, device: device, setReportConfig: () => null }, inclusiveStart: zonedTimeToUtc(start, facilityTimezone), exclusiveEnd: new Date(), facilityIds: undefined, chartTimezone: facilityTimezone, groupByTargetType: true, chartType: 'bars', deviceIds: [device.id], targetTypes: AllTargetTypes, timeZone: facilityTimezone } as TrafficReportConfiguration & SelectedReportConfiguration;
    const props = { margin: margin, size: { height: height, width: (screenWidth - margin.left - margin.right) } } as TrafficReportProps;

    const tipLabeler = stackedTooltipBuilder<TotalVolumeByPeriod>(numberFormatter, undefined, undefined, x => format(x.periodStart!, 'MMM-dd'), undefined, true);
    return (
        <ReportContext.Provider value={{ reportConfig: reportConfig, searchParams: reportConfig, updateReportConfig: x => { } }} >
            <TotalVolumeChart
                {...props}
                aggregationLevel={AggregationLevel.Day}
                groupMapFunction={d => format(d.periodStart!, 'yyyy/MM/dd')}
                groupToDate={d => parse(d, 'yyyy/MM/dd', new Date())}
                tickLabeler={str => format(new Date(str), 'MMM-dd')}
                toolTipDateFormatter={str => format(new Date(str), 'MMM dd YYYY')}
                toolTipLabeler={tipLabeler}
            />
        </ReportContext.Provider>);
}

