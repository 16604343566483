import { enumKeys, getTargetTypeDisplayName, TargetType } from "@app/shared";
import { FunctionComponent, useMemo } from "react";
import { MultiSelect, MultiSelectProps, SimpleOption } from "../shared/inputs/MultiSelect";

export type TargetTypeMultiSelectProps = {
    values: TargetType[],
    onChange: (values: TargetType[]) => void,
    options?: SimpleOption[]
} & Omit<Omit<Omit<MultiSelectProps, 'value'>, 'onChange'>, 'options'>;

const allOpt = { label: 'All', value: -1 };

export const TargetTypeMultiSelect: FunctionComponent<TargetTypeMultiSelectProps> = (props) => {

    const targetTypeOptions = useMemo(() => {
        const opts = [allOpt, ...enumKeys(TargetType).map(k => ({
            label: getTargetTypeDisplayName(k)!,
            value: TargetType[k] as number
        }))];
        opts.sort((a, b) => a.label.localeCompare(b.label));
        return opts;
    }, [props.options]);

    const ttValue = useMemo(() => {
        if (props.values?.length === (targetTypeOptions.length - 1)) {
            return [-1];
        }
        return targetTypeOptions.filter(x => props.values.includes(x.value as TargetType)).map(x => x.value);
    }, [props.values, targetTypeOptions]);

    const toValue = (vals: number[]) => {
        if (!vals?.length || vals[0] === -1) {
            props.onChange(targetTypeOptions.filter(x => x.value !== -1).map(x => x.value as number))
        } else {
            props.onChange(vals)
        }
    }
    return (
        <MultiSelect {...props} value={ttValue} options={targetTypeOptions} onChange={toValue as any} selectAllOption={allOpt} />
    );
};
