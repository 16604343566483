import { enumKeys, getTargetTypesDisplayName, MaxServerIntValue, TargetTypes } from "@app/shared";
import { InputLabel } from "../../shared";

type TargetTypeFilterInputProps = {
    value: number | string | undefined;
    onChange: (value: number) => any;
    label: string;
};

export const TargetTypeFilterInput: React.FC<TargetTypeFilterInputProps> = (props) => {
    return (
        <>
            <InputLabel>{props.label}</InputLabel>
            <div className="grid grid-cols-2 gap-2">
                <label>
                    <input
                        type="checkbox"
                        className="mx-2"
                        checked={props.value == MaxServerIntValue}
                        onChange={(e) => props.onChange(e.target.checked ? MaxServerIntValue : 0)}
                    />
                    All
                </label>
                {enumKeys(TargetTypes).filter(k => TargetTypes[k] !== TargetTypes.Unknown).map(k => (
                    <label key={k}>
                        <input
                            type="checkbox"
                            className="mx-2"
                            checked={props.value !== undefined && (+props.value & TargetTypes[k]) > 0}
                            onChange={(e) => props.onChange(e.target.checked ? (+(props.value ?? 0) | TargetTypes[k]) : (+(props.value ?? 0) & ~TargetTypes[k]))}
                        />
                        {getTargetTypesDisplayName(k)}
                    </label>
                ))}
            </div>
        </>
    );
}