import { ApiResponse, OmniUser, sessionValue, sleep } from "..";


const AUTH_OPTIONS: RequestInit = {
  credentials: 'include'
};
Object.freeze(AUTH_OPTIONS);

const DEFAULT_GET_OPTIONS: RequestInit = {
  ...AUTH_OPTIONS,
  method: 'GET',
  headers: {
    Accept: 'application/json',
  },
};
Object.freeze(DEFAULT_GET_OPTIONS);

const DEFAULT_POST_OPTIONS: RequestInit = {
  ...DEFAULT_GET_OPTIONS,
  headers: {
    ...DEFAULT_GET_OPTIONS.headers,
    'Content-Type': 'application/json',
  },
  method: 'POST',
};
Object.freeze(DEFAULT_POST_OPTIONS);

const DEFAULT_PUT_OPTIONS: RequestInit = {
  ...DEFAULT_GET_OPTIONS,
  headers: {
    ...DEFAULT_GET_OPTIONS.headers,
    'Content-Type': 'application/json',
  },
  method: 'PUT',
};
Object.freeze(DEFAULT_PUT_OPTIONS);

const DEFAULT_PATCH_OPTIONS: RequestInit = {
  ...DEFAULT_GET_OPTIONS,
  headers: {
    ...DEFAULT_GET_OPTIONS.headers,
    'Content-Type': 'application/json',
  },
  method: 'PATCH',
};
Object.freeze(DEFAULT_PATCH_OPTIONS);

const DEFAULT_DELETE_OPTIONS: RequestInit = {
  ...DEFAULT_GET_OPTIONS,
  method: 'DELETE'
};
Object.freeze(DEFAULT_DELETE_OPTIONS);

const RAW_POST_OPTIONS: RequestInit = {
  ...AUTH_OPTIONS,
  method: 'POST'
};
Object.freeze(RAW_POST_OPTIONS);

const REACT_APP_CORE_API_ROOT_URL = process.env.REACT_APP_CORE_API_ROOT_URL;

export type ApiResult<T> = Promise<T | undefined>;
export const ApiResult = Promise;

class OmniError extends Error {
  private _messages: string[];
  public get messages() { return this._messages; }

  constructor(messages: string[]) {
    const message = messages?.join("  ");
    super(message);

    this._messages = messages;
    this.name = 'OmniError';
  }
}

export class UserError extends OmniError {
  constructor(messages: string[]) {
    super(messages);
    this.name = 'UserError';
  }
}

export class ServerError extends OmniError {
  constructor(messages: string[]) {
    super(messages);
    this.name = 'ServerError';
  }
}

export class UnauthorizedError extends OmniError {
  constructor(messages: string[]) {
    super(messages);
    this.name = 'UnauthorizedError';
  }
}

export class TimeoutError extends OmniError {
  constructor() {
    super(['The server did not respond in a timely manner.']);
    this.name = 'TimeoutError';
  }
}

function isUnauthError(statusCode: number): boolean {
  return statusCode === 401;
}

function isUserError(statusCode: number): boolean {
  return statusCode >= 400 && statusCode < 500;
}

function isServerError(statusCode: number): boolean {
  return statusCode >= 500 && statusCode < 600;
}

function validateResponse(status?: number, errors?: string[]): void {
  if (!!!status || !!!errors) { throw new TimeoutError(); }
  if (isUnauthError(status)) { throw new UnauthorizedError(errors); }
  if (isUserError(status)) { throw new UserError(errors); }
  if (isServerError(status)) { throw new ServerError(errors); }
}

export async function getValidatedResponseData<T>(response: Response): ApiResult<T> {
  const responseText = await response.text();

  if (responseText.length === 0) {
    validateResponse(response.status, [response.statusText]);
    return;
  }

  const { status, error, data } = JSON.parse(responseText) as ApiResponse<T>;
  validateResponse(status, [error]);
  return data;
}

function addAuthForOptions(options: RequestInit): RequestInit {
  const { headers } = options;
  const [user] = sessionValue<OmniUser>('currentUser');

  if (options.method === 'GET') { return options; }

  const authenticatedHeaders = { ...headers, 'X-AUTH-TOKEN': user?.authToken || '' };
  return { ...options, headers: authenticatedHeaders };
}

async function rawfetch(path: string, options: RequestInit): Promise<Response> {
  const authenticatedOptions = addAuthForOptions(options);
  return fetch(getApiUrl(path), authenticatedOptions);
}

async function apiFetch<T>(path: string, options: RequestInit): ApiResult<T> {
  const getResponse = async () => {
    const authenticatedOptions = addAuthForOptions(options);
    const response = await fetchWithTimeout(getApiUrl(path), { ...authenticatedOptions, timeout: 30000 });
    return response;
  };

  return getValidatedResponseData<T>(await getResponse()).catch(async error => {
    if (error instanceof UnauthorizedError) {
      console.warn("Trying to re-authenticate the client...");
      document.dispatchEvent(new Event('auth:checkAuthState'));
      //Give it a few seconds to hopefully get our session refreshed
      //TODO: Maybe we remove this and add code to the AuthContextProvider that will keep our user updated and warn a user if internet connection is lost... 
      await sleep(3000);
      return getValidatedResponseData<T>(await getResponse());
    } else {
      throw error;
    }
  });
}
type RequestInitTimeout = RequestInit & { timeout: number };
async function fetchWithTimeout(url: string, options: RequestInitTimeout): Promise<Response> {
  const { timeout, ...fetchOptions } = options;

  // Create an AbortController instance
  const controller = new AbortController();

  // Set a timeout using setTimeout
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    // Perform the fetch with the signal option set to the AbortController's signal
    const response = await fetch(url, { ...fetchOptions, signal: controller.signal });

    // If the request was successful, clear the timeout
    clearTimeout(timeoutId);

    return response;
  } catch (error) {
    // Handle errors (e.g., network error, aborted request)
    clearTimeout(timeoutId);
    throw error;
  }
}
/*.catch(error => {
    console.log("What is this error: ", error);
    if (error instanceof UnauthorizedError) {
      console.warn("Trying to re-authenticate the client...");
      return fetch(input, init);
    } else {
      throw error;
    }
  });
  */
export function getApiUrl(path: string) {
  return [REACT_APP_CORE_API_ROOT_URL, path].join('');
}

export async function get<T>(path: string): ApiResult<T> {
  return apiFetch<T>(path, DEFAULT_GET_OPTIONS);
}

export async function del<T>(path: string): ApiResult<T> {
  return apiFetch<T>(path, DEFAULT_DELETE_OPTIONS);
}

export async function rawPost(path: string, body?: any, requestOptions?: RequestInit): Promise<Response> {
  const options = { ...RAW_POST_OPTIONS, body: body, ...requestOptions };
  return rawfetch(path, options);
}

export async function post<T>(path: string, body?: any): ApiResult<T> {
  const options = { ...DEFAULT_POST_OPTIONS, body: body ? JSON.stringify(body) : undefined };
  return apiFetch<T>(path, options);
}

export async function put<T>(path: string, body?: any): ApiResult<T> {
  const options = { ...DEFAULT_PUT_OPTIONS, body: body ? JSON.stringify(body) : undefined };
  return apiFetch<T>(path, options);
}

export async function patch<T>(path: string, body?: any): ApiResult<T> {
  const options = { ...DEFAULT_PATCH_OPTIONS, body: body ? JSON.stringify(body) : undefined };
  return apiFetch<T>(path, options);
}