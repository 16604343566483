import { Spot } from "@app/shared";
import { CircleMapShape, MapShapeProps } from "..";

export interface ReferencePointMapShapeProps extends MapShapeProps<Spot> {
    radius?: number,
    color?: string,
};

export const ReferencePointMapShape: React.FC<ReferencePointMapShapeProps> = ({ shape, color, radius, isSelected, onClick }) => {
    const isPositioned = typeof shape?.origin?.x === 'number';
    if (!isPositioned) { return null; }

    const coords = { lat: shape!.origin!.y, lng: shape!.origin!.x };

    return (<>
        <CircleMapShape position={new google.maps.LatLng(coords)} radius={radius ?? 5} color={color ?? 'red'} isSelected={isSelected} onClick={(e: google.maps.MapMouseEvent) => onClick?.(e, shape)} />
        <CircleMapShape position={new google.maps.LatLng(coords)} radius={1} color='black' />
    </>);
}