import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { AggregationLevel, getTargetTypeDisplayName, Reports } from "@app/shared";
import { useContext, useMemo } from "react";
import { ReportContext } from "./reportHelpers";
import { extLightThemeColors } from "../../styles/chartThemeColors";
import { ApexOptions } from "apexcharts";
import { useFetch } from "../../hooks";
import Chart from "react-apexcharts";
import useRenderingTrace from "../../hooks/useRenderingTrace";
import { formatInTimeZone } from "date-fns-tz";

export const HourlyDataLoader: React.FC<TrafficReportProps> = ({ size, subTitle, title }) => {
    const { searchParams, reportConfig } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => Reports.totalVolume(AggregationLevel.Hour, searchParams), [searchParams]);

    const stringDates = useMemo(() => {
        if (!data?.length) { return []; }
        let strings = data?.map(x => ({ ...x, periodStart: formatInTimeZone(x.periodStart!, reportConfig.chartTimezone, 'MMM d  H:mm') }));
        if (new Set(strings?.map(x => x.periodStart)).size <= 24) {
            strings = data?.map(x => ({ ...x, periodStart: formatInTimeZone(x.periodStart!, reportConfig.chartTimezone, 'H:mm') }));
        }
        return strings;
    }, [data, reportConfig.chartTimezone]);

    const xValues = useMemo(() => {
        if (!stringDates?.length) { return []; }
        return Array.from(new Set(stringDates.map(x => x.periodStart)));
    }, [stringDates]);

    const stacksInData = useMemo(() => {
        if (!data?.length) { return []; }
        return Array.from(new Set(data.map(x => x.targetType)));
    }, [data]);

    const series = useMemo(() => {
        const colorMap = createWrappedMap(stacksInData, extLightThemeColors);
        if (!reportConfig.groupByTargetType) {
            return [{
                name: '',
                color: extLightThemeColors[0],
                data: Object.values(stringDates?.reduce((acc, item) => {
                    //const j = item;
                    if (acc[item.periodStart] === undefined) {
                        acc[item.periodStart] = { periodStart: item.periodStart, count: 0 };
                    }
                    acc[item.periodStart].count += item.count;
                    return acc;
                }, {} as any as { [key: string]: { periodStart: string, count: number } }) ?? {}).map(x => x.count) ?? []
            }];
        }

        const r = [];
        for (const stackVal of stacksInData) {
            r.push({
                name: getTargetTypeDisplayName(stackVal),
                color: colorMap.get(stackVal),
                data: stringDates?.filter(x => x.targetType === stackVal).map(x => x.count) as Array<number> //data is positional so must be sorted by xKey
            });
        }
        return r;

    }, [stringDates, stacksInData, reportConfig.groupByTargetType]);

    var options = useMemo(() => ({
        chart: {
            type: 'bar',
            stacked: reportConfig.groupByTargetType,
            toolbar: {
                show: false
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: xValues,
            tickAmount: 24
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: reportConfig.groupByTargetType,
            position: 'top',
            horizontalAlign: 'left',
            floating: true,
            offsetY: -60,
            customLegendItems: stacksInData.map(getTargetTypeDisplayName),
            markers: {
                fillColors: extLightThemeColors
            }
        },
        title: {
            align: 'center',
            text: title
        },
        subtitle: {
            align: 'center',
            text: subTitle
        },
        tooltip: {
            x: {
                formatter(val, opts) {
                    const xValLabel = xValues[opts.dataPointIndex];
                    return xValLabel;
                },
            }
        }
    } as ApexOptions), [stacksInData, reportConfig.groupByTargetType, xValues, title, subTitle]);
    useRenderingTrace("total volume by hour", [title, subTitle, size, reportConfig, searchParams, data]);

    if (error && !isLoading) {
        return (<h2 className="mx-auto">{error}</h2>)
    }
    if (!series.length || isLoading) {
        return null;
    }

    return (
        <>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height={size.height ? size.height - 80 : undefined}
            />
        </>
    );
}


export const TotalVolumeByHour: IConfiguredTrafficReport = {
    name: "by Hour",
    key: "tvh",
    description: "This report shows total traffic for the specified hours",
    component: HourlyDataLoader,
    defaultChartType: 'lines',
    defaultRange: DateOptions.LastWeek,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};

function createWrappedMap<T, U>(array1: T[], array2: U[]): Map<T, U> {
    const map = new Map<T, U>();
    for (let i = 0; i < array1.length; i++) {
        const wrappedIndex = i % array2.length;
        map.set(array1[i], array2[wrappedIndex]);
    }
    return map;
}