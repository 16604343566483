import { TaskCommand } from "./TaskCommand.gen";

export interface IRecordDataCommandParams {
	command: TaskCommand;

	durationHours: number;

	$type?: string | undefined;
}
export class RecordDataCommandParams {
	constructor(data?: IRecordDataCommandParams) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	command: TaskCommand = 0;

	durationHours: number = 0;

	static $type: string = "CoreApi.Models.DeviceCommands.RecordDataCommandParams, ";
	$type?: string | undefined = RecordDataCommandParams.$type;
}

