import { TrafficReportProps } from "./IConfiguredTrafficReport";
import { OptionallySplitLineChart, SeriesDetails } from "./OptionallySplitLineChart";
import { BarStackMapped, OptionallyStackedBarChart } from "./OptionallyStackedBarChart";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { ReportContext, buildLabeler, buildStackedData, groupBy, stackedTooltipBuilder, tooltipBuilder } from "./reportHelpers";
import { useContext, useMemo } from "react";
import { useFetch } from "../../hooks";
import { AggregationLevel, ApiResult, Reports, TotalVolumeByPeriod, TrafficReportConfiguration, getTargetTypeDisplayName, useAuth } from "@app/shared";
import { ScaleOrdinal } from "d3-scale";
import { formatInTimeZone } from "date-fns-tz";

export interface TotalVolumeChartProps extends TrafficReportProps {
    aggregationLevel: AggregationLevel,
    groupMapFunction: (datum: TotalVolumeByPeriod) => string,
    groupToDate: (groupKey: string) => Date,
    dataSource?: (opts: TrafficReportConfiguration) => ApiResult<TotalVolumeByPeriod[]>,
    tickLabeler?: (str: string) => string,
    toolTipDateFormatter?: (str: string) => string,
    toolTipLabeler?: (datum: BarStackMapped<TotalVolumeByPeriod>, colorScale: ScaleOrdinal<string, any, any>, stackKey?: string | number | symbol) => JSX.Element,
    onClick?: (date: Date) => void
}

export const TotalVolumeChart: React.FC<TotalVolumeChartProps> = (props: TotalVolumeChartProps) => {
    const { searchParams, reportConfig } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const [data, error, { isLoading }] = useFetch(() => props.dataSource ? props.dataSource(searchParams) : Reports.totalVolume(props.aggregationLevel, searchParams), [searchParams]);

    // const groupedShiftedData = useMemo(() => {
    //     if (isLoading || !data?.length) return [];
    //     return data.map(x => ({ ...x, date: new Date(x.periodStart!) }));
    //     //return data.data;//.map(d => ({ ...d, date: d.date === undefined ? undefined : utcToZonedTime(d.date, requestParams.chartTimezone) }))
    // }, [data, isLoading]);

    const dataWithoutTTypes = useMemo(() => {
        if (isLoading || !data?.length) return [];

        const ttGroup = groupBy(data.map(d => ({ ...d, $$groupKey: props.groupMapFunction(d) })), '$$groupKey');
        return Object.keys(ttGroup).map(d => ({ periodStart: props.groupToDate(d), $type: data![0].$type, count: ttGroup[d].reduce((agg, cv) => agg + cv.count, 0) } as TotalVolumeByPeriod))
    }, [isLoading, data, props])

    const chartData = useMemo(() => {
        if (isLoading || !data?.length) return [];
        if (reportConfig.groupByTargetType) {
            const ttGroup = groupBy(data.map(d => ({ ...d, $$ttGroupKey: props.groupMapFunction(d) + '_' + d.targetType, $$groupKey: props.groupMapFunction(d) })), '$$ttGroupKey');
            const groupMems = Object.keys(ttGroup).map(d => ({ periodStart: props.groupToDate(ttGroup[d][0].$$groupKey), $type: data![0].$type, count: ttGroup[d].reduce((agg, cv) => agg + cv.count, 0), targetType: ttGroup[d][0].targetType } as TotalVolumeByPeriod)).filter(x => x.count > 0)
            const stacked = buildStackedData(groupMems, 'count', 'targetType').sort((a, b) => a.periodStart!.getTime() - b.periodStart!.getTime())
            return stacked;
        }
        return dataWithoutTTypes.sort((a, b) => a.periodStart!.getTime() - b.periodStart!.getTime()) as BarStackMapped<TotalVolumeByPeriod>[];
    }, [reportConfig.groupByTargetType, isLoading, dataWithoutTTypes, props, data])

    const Report = useMemo(() => {
        if (!data?.length || isLoading) {
            return null;
        }

        const ttWithData = chartData?.[0].$$BarStackMap ? Array.from(new Set(chartData.flatMap((d) => Object.keys(d.$$BarStackMap)))) : ['unknown'];
        const series: SeriesDetails<BarStackMapped<TotalVolumeByPeriod>>[] = ttWithData.map(tt => ({
            accessors: {
                xAccessor: dc => dc?.periodStart,
                yAccessor: dc => dc?.$$BarStackMap ? dc.$$BarStackMap[tt] : dc?.count
            },
            dataKey: tt + ''
        }));

        function handleClick(datum: any, stackKey?: string | number | symbol | undefined): void {
            const date = (reportConfig.chartType === 'lines' ? datum.date : datum.data.stack) as Date;//TODO: gross
            if (props?.onClick) { props.onClick(date) }
        }

        const chartProps = {
            ...props,
            data: chartData,
            xKey: "periodStart" as keyof TotalVolumeByPeriod,
            yKey: "count" as keyof TotalVolumeByPeriod,
            barStackKey: reportConfig.groupByTargetType ? "targetType" as keyof TotalVolumeByPeriod : undefined,
            lineSplitKey: reportConfig.groupByTargetType ? "targetType" as keyof TotalVolumeByPeriod : undefined,
            lineSplitLabel: getTargetTypeDisplayName as any,
            stackOrGroupLabel: getTargetTypeDisplayName as any,
            series: series as any,
            xTickCount: 24,
            xTickLabeler: buildLabeler(props.tickLabeler ? props.tickLabeler : str => formatInTimeZone(new Date(str), reportConfig.chartTimezone, 'PP')),
            toolTip: props.toolTipLabeler ? props.toolTipLabeler : reportConfig.groupByTargetType ? stackedTooltipBuilder(numberFormatter) : tooltipBuilder<TotalVolumeByPeriod>(props.toolTipDateFormatter ? d => props.toolTipDateFormatter!(d.periodStart!.toISOString()) : d => formatInTimeZone(d.periodStart!, reportConfig.chartTimezone, 'PP'), d => numberFormatter.format(d.count)),
            onClick: handleClick
        };

        return reportConfig.chartType === 'lines' ?
            (<OptionallySplitLineChart {...chartProps} />)
            : (<OptionallyStackedBarChart   {...chartProps} />)

    }, [data, reportConfig.groupByTargetType, reportConfig.chartTimezone, reportConfig.chartType, isLoading, props, chartData, numberFormatter]);

    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >{Report}</ReportLoadingErrorWrapper>);
}