import { Facility, getApiUrl, getTargetStatusDisplayName, Target, Vector2 } from "@app/shared";
import { getTargetTypeDisplayName } from "@app/shared/src/models/Shared.TargetType.gen";
import { formatInTimeZone } from "date-fns-tz";
import { useMemo } from 'react';
import { useFacilityCoordsHelper } from "../../../helpers";
import { DataInfoCard, Icons, PageContainer } from "../../shared";
import { DeviceMapShape, OmniGoogleMap, TargetMapShape } from "../../shared/maps";

type TargetDetailsDialogProps = {
    onZoomChange?: (isZoomed: boolean) => any;
    facility: Facility;
    target: Target | undefined;
};

export const TargetDetailsDialog: React.FC<TargetDetailsDialogProps> = ({ onZoomChange, facility, target: val }) => {
    const getDeviceNameForTarget = (target?: Target) => facility.devices?.find(d => d.id === target?.deviceId)?.name ?? "";
    const device = facility.devices?.find(d => d.id === val?.deviceId);
    const zone = facility.localTimezoneId ?? Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
    const help = useFacilityCoordsHelper(facility);
    const deviceCenter = useMemo(() => {
        if (device) {
            return help.toLatLng(new Vector2({ x: device.positionX, y: device.positionY }));
        }
    }, [facility, device])

    return (
        <>
            <PageContainer Icon={Icons.Route} title="Target Track">
                {device && <OmniGoogleMap center={deviceCenter!} fitToPolygons={true}>
                    <DeviceMapShape facility={facility} shape={device} />
                    <TargetMapShape facility={facility} shape={val} />
                </OmniGoogleMap>}

            </PageContainer>
            <PageContainer Icon={Icons.Photo} title="Target Photo">
                <div className="w-full" >
                    {val?.hasPhoto &&
                        <img src={getApiUrl(`/api/organization/${facility.organizationId}/device/${val.deviceId}/target/${val.externalId}/photo`)} className="mx-auto my-auto" alt="Target" />
                    }
                    {!!!val?.hasPhoto &&
                        <h3 className="mx-auto my-auto">Not Available</h3>
                    }
                </div>
            </PageContainer>
            <div className="space-y-3">
                <DataInfoCard title="Detection Device" Icon={Icons.Satellite}>
                    {getDeviceNameForTarget(val)}
                </DataInfoCard>

                <DataInfoCard title="Status Started" Icon={Icons.Clock}>
                    {val?.statusStarted ? formatInTimeZone(val?.statusStarted, zone, "MMM d, p z") : "None"}
                </DataInfoCard>

                <div className="grid md:grid-cols-2 gap-5">
                    <DataInfoCard title="Type" Icon={Icons.Truck}>
                        {val?.type ? getTargetTypeDisplayName(val!.type) : ""}
                    </DataInfoCard>
                    <DataInfoCard title="Status" Icon={Icons.Parking}>
                        {val?.status ? getTargetStatusDisplayName(val?.status) : ""}
                    </DataInfoCard>
                    <DataInfoCard title="Size" Icon={Icons.Ruler}>
                        {val?.maximumWidth?.toFixed(1)}, {val?.maximumHeight?.toFixed(1)} m
                    </DataInfoCard>
                    <DataInfoCard title="Count Adjustment" Icon={Icons.StopWatch}>
                        {val?.netFacilityCountChange}
                    </DataInfoCard>
                </div>
            </div>

            {/* <PageContainer Icon={Icons.History} title="Location History">
                <SortableTable columns={[
                    { header: "Date", width: 125, dataKey: "date", renderFunc: (th) => th?.date ? formatInTimeZone(th?.date, zone, "p") : "" },
                    { header: "Status", width: 125, dataKey: "status", renderFunc: (th) => th?.status ? getDeviceTargetStatusDisplayName(th?.status) : "" },
                    { header: "Type", width: 175, dataKey: "type", renderFunc: (th) => th?.type ? getTargetTypeDisplayName(th?.type) : "" },
                    { header: "Size (m)", width: 100, dataKey: "width", renderFunc: (th) => `(${th?.width.toFixed(2)}, ${th?.height.toFixed(2)})` },
                    { header: "Count Adj.", width: 100, dataKey: "netFacilityCountChange" }
                ]} data={val?.history} />
            </PageContainer> */}
        </>
    );
}