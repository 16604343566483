import { Button, ButtonVariants, IUserLogin, NewUser, useAuth, UserInvites, UserLogin } from "@app/shared";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Pages } from "..";
import { toastError } from "../../../helpers";
import { useFetch, useModel } from "../../../hooks";
import { NoAuthAccountLayout } from "../../layouts";
import { Icons, Input, InputRow, Link, PasswordInput } from "../../shared";

export const ViewInvite: React.FC = (props) => {
    const { id } = useParams();
    const [invite, , fetchHelpers] = useFetch(() => UserInvites.getUserInvite(id), [id]);
    const [newUser, userHelpers] = useModel(NewUser);
    const [userLogin, userLoginHelpers] = useModel(UserLogin);
    const auth = useAuth();
    const [useLogin, setUseLogin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (invite) {
            userHelpers.updateModel("emailAddress", invite.email);
            userHelpers.updateModel("firstName", invite.firstName);
            userHelpers.updateModel("lastName", invite.lastName);
            if (invite.userExists) {
                setUseLogin(invite.userExists);
                userLoginHelpers.updateModel('email', invite.email);
            }
        }
    }, [invite]) //eslint-disable-line react-hooks/exhaustive-deps

    const handleAcceptOnClick = () => {
        if (!auth.isAuthenticated) {
            //This handler should only be available when they are logged in... 
            return;
        }

        try {
            UserInvites.acceptInvite(id);
            navigate(Pages.ViewFacilityGroups.getUrl());
        } catch (e) {
            toastError("Unable to accept invite", e + "");
        }
    };

    const handleUseLoginOnClick = () => {
        setUseLogin(true);
    };

    const handleLoginOnClick = async () => {
        userLoginHelpers.validateWithAction(async () => await auth.logIn(userLogin as UserLogin));
    };

    const handleSignUpAcceptOnClick = () => {
        userHelpers.validateWithAction(async () => {
            await UserInvites.acceptInviteUser(id, newUser as NewUser);
            await auth.logIn(new UserLogin({ email: newUser.emailAddress!, password: newUser.password! } as IUserLogin));
            navigate(Pages.ViewFacilityGroups.getUrl());

        });
    }

    //If the invite is not found - notify the user
    if (!fetchHelpers.isLoading && !invite) {
        return <NoAuthAccountLayout>
            <p>This invite no longer exists.  Please check the URL or have someone from the organization send you a new invite!</p>
        </NoAuthAccountLayout>
    }

    return (
        <NoAuthAccountLayout>
            <div className="space-y-5">
                <div>
                    <h1>{invite?.organizationName}</h1>
                </div>

                {(!auth.isAuthenticated || auth.user?.email !== invite?.email) ?
                    <>
                        {!useLogin ? <>
                            <div>
                                <p>You have been invited to join {invite?.organizationName}. To accept the invitation, you will need an account.</p>
                            </div>
                            <div className={userHelpers.modelContainerClassNames}>
                                <userHelpers.ErrorSummary />
                                <InputRow>
                                    <Input label="Email (required)" {...userHelpers.bindingsFor("emailAddress")} />
                                </InputRow>
                                <InputRow>
                                    <Input label="First Name (required)" {...userHelpers.bindingsFor("firstName")} />
                                </InputRow>
                                <InputRow>
                                    <Input label="Last Name (required)" {...userHelpers.bindingsFor("lastName")} />
                                </InputRow>
                                <InputRow>
                                    <PasswordInput label="Password" {...userHelpers.bindingsFor("password")} />
                                </InputRow>
                                <InputRow>
                                    <PasswordInput label="Confirm Password" {...userHelpers.bindingsFor("confirmPassword")} />
                                </InputRow>
                                <InputRow>
                                    <Button className="mt-5" onClick={handleSignUpAcceptOnClick}>Sign Up &amp; Accept</Button>
                                </InputRow>
                            </div>
                            <hr />
                            <div>
                                <InputRow>
                                    <p className="text-muted">Already have an account?</p>
                                    <Link onClick={handleUseLoginOnClick}>Login</Link>
                                </InputRow>
                            </div>
                        </>
                            :
                            <div className={userLoginHelpers.modelContainerClassNames}>
                                <userLoginHelpers.ErrorSummary />
                                <InputRow>
                                    <Input label="Email (required)" {...userLoginHelpers.bindingsFor("email")} defaultValue={invite?.email} />
                                </InputRow>
                                <InputRow>
                                    <PasswordInput label="Password" {...userLoginHelpers.bindingsFor("password")} />
                                </InputRow>
                                <InputRow>
                                    <div className="flex mt-5">
                                        <Button onClick={handleLoginOnClick}>Login</Button>
                                        <Button variant={ButtonVariants.Secondary} onClick={() => setUseLogin(false)}>Cancel</Button>
                                    </div>
                                </InputRow>
                                <InputRow>
                                    <div className="mx-auto text-center mt-10">
                                        <NavLink state={{ email: invite?.email }} to={Pages.ForgotPassword.getUrl()} > Forgot your password?</NavLink>
                                    </div>
                                </InputRow>
                            </div>
                        }
                    </>
                    :
                    <>
                        <p>Welcome back <span className="font-semibold">{auth.user?.firstName ?? auth.user?.email}</span>, you have been invited to join {invite?.organizationName}.</p>

                        <div>
                            <InputRow>
                                <span className="text-muted-body-text">Current Account:</span>
                                <div className="max-w-md border border-separators-page p-3 shadow-sm rounded-sm flex mx-auto place-items-center">
                                    <div><Icons.Profile className="h-10 w-10 text-muted-body-text" /></div>
                                    <div className="flex flex-col ml-5">
                                        <div className="font-semibold">{auth.user?.name}</div>
                                        <div>{auth.user?.email}</div>
                                    </div>
                                </div>

                            </InputRow>
                        </div>
                        <div className="mx-auto text-center">
                            <Button className="mx-auto" onClick={handleAcceptOnClick}>Accept Invitation</Button>
                            <Link onClick={() => { auth.logOut() }}>Use a different account</Link>
                        </div>
                    </>
                }
            </div >
        </NoAuthAccountLayout >
    );
}