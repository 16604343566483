import { Device, IDevice } from "./Device.gen";
import { DateTime, IDateTime } from "./System.DateTime.gen";
import { Boolean, IBoolean } from "./System.Boolean.gen";

export interface INetworkConfigCommand {
	device: IDevice | undefined;

	description: string | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	dateCreated: string | undefined;

	dateSentToDevice: string | undefined;

	dateCompleted: string | undefined;

	deviceDidSucceed: boolean | undefined;

	deviceResponse: string | undefined;

	$type?: string | undefined;
}
export class NetworkConfigCommand {
	constructor(data?: INetworkConfigCommand) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.device) { this.device = new Device(data.device); }
		if (data.dateCreated) { this.dateCreated = new Date(data.dateCreated); }
		if (data.dateSentToDevice) { this.dateSentToDevice = new Date(data.dateSentToDevice); }
		if (data.dateCompleted) { this.dateCompleted = new Date(data.dateCompleted); }
	}

	device: Device | undefined;

	description: string | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	dateCreated: Date | undefined = new Date();

	dateSentToDevice: Date | undefined;

	dateCompleted: Date | undefined;

	deviceDidSucceed: boolean | undefined;

	deviceResponse: string | undefined;

	static $type: string = "CoreApi.Models.DeviceCommands.NetworkConfigCommand, ";
	$type?: string | undefined = NetworkConfigCommand.$type;
}

