import { Device, DeviceCommand, DeviceCommands, Devices, DeviceStatus, Firmwares, FirmwareType, FirmwareVersionType, LastTargetEntity } from "@app/shared";
import { format } from 'date-fns-tz';
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditDeviceCommand, getStatusHoverText, SearchInput } from ".";
import { toastError, toastSuccess } from "../../helpers";
import { useConfirmDialog, useDialog, useFetch } from "../../hooks";
import { useEditModelDialog } from "../../hooks/useEditModelDialog";
import { useMiniSearch } from "../../hooks/useMiniSearch";
import { Pages } from "../pages";
import { DeviceProvisionDialog } from "./DeviceProvisionDialog";
import { DropDownMenu } from "./DropDownMenu";
import { DeviceCommandTypes } from "./EditDeviceCommand/DeviceCommandEditor";
import { EditDeviceDetails } from "./EditDeviceDetails";
import { HoverTooltip } from "./HoverTooltip";
import { IconLink } from "./IconLink";
import { Icons } from "./Icons";
import { Spinner } from "./loading";
import { SortableTable, SortableTableHeaderType } from "./SortableTable";
import { StatusDot } from "./StatusDot";

type DevicesTableProps = {
    devices: Device[] | undefined;
    lastTargets?: Array<LastTargetEntity>,
    isLoading?: boolean;
    facilityId?: string;
    onChange?: () => void;
}
type TableDataType = Device & { lastUpdate: Date | undefined };

export const DevicesTable: React.FC<DevicesTableProps> = ({ devices, isLoading, onChange, lastTargets, facilityId }) => {
    //TODO: Maybe put this in facility settings?
    const minFirmwareVersionType = FirmwareVersionType.Release;
    const navigate = useNavigate();
    const deviceDialog = useEditModelDialog(Device);
    const confirmDialog = useConfirmDialog();
    const sendCommandDialog = useDialog<Device>();
    const [commandToSend, setCommandToSend] = useState<DeviceCommandTypes>();
    const [showAddDialog, setShowAddDialog] = useState(false);
    const commandToSendRef = useRef(commandToSend);
    const [latestFirmwares] = useFetch(() => Firmwares.getLatest(minFirmwareVersionType));
    const [searchParams, setSearchParams] = useSearchParams();

    //Keep a ref to the commandToSend for our callback
    useEffect(() => {
        commandToSendRef.current = commandToSend;
    }, [commandToSend]);


    const handleAddOnClick: MouseEventHandler = () => {
        setShowAddDialog(true);
    };

    const needsUpdate = (device: Device) => {
        const latestForDevice = latestFirmwares?.find(f => f.firmwareType === FirmwareType.Device && f.deviceType === device.type);
        const latestForSensor = latestFirmwares?.find(f => f.firmwareType === FirmwareType.Sensor && f.sensorType === device.sensorType);
        return ((latestForDevice && latestForDevice.firmwareVersion !== device.firmwareVersion) || (latestForSensor && latestForSensor.firmwareVersion !== device.sensorFirmwareVersion));
    };

    const deviceDocs = useMemo(() => {
        return devices?.map(d => ({ ...d, notes: d.notes?.reduce((acc, current) => acc + ' ' + current.note, '') }));
    }, [devices]);
    const fullTextFields: Array<keyof Device> = ['description', 'facilityName', 'name', 'notes', 'statusMessage']
    const miniSearch = useMiniSearch(deviceDocs, fullTextFields, [deviceDocs]);

    const handleEditOnClick = (device: Device) => {
        deviceDialog.show(
            async (device) => {
                await Devices.save(device as Device);
                if (onChange) await onChange();
            },
            "Edit Device",
            device
        );
    };
    const handleUpgradeOnClick = async (device: Device) => {
        await Devices.updateDevice(device.id, FirmwareVersionType.Release);
        if (onChange) await onChange();
    };
    const handleDeleteOnClick = async (device: Device) => {
        confirmDialog.show("Delete Device?", `Are you sure you want to delete this device [${device.name}]?`, "Delete", async () => {
            await Devices.deleteDevice(device.id);
            if (onChange) await onChange();
        });
    };
    const handleOnRowClick = (device: Device) => {
        navigate(Pages.DeviceDetails.getUrl({ id: device.id! }));
    };
    const handleSendCommandClick = (device: Device) => {
        setCommandToSend(undefined);
        sendCommandDialog.show("Send Device Command", "Send", device, async () => {
            try {
                await DeviceCommands.sendCommand(commandToSendRef.current as DeviceCommand);
                toastSuccess("Command Sent", "A new command is pending for " + device.name);
                if (onChange) await onChange();
            } catch (e) {
                toastError("Unable to send command", e + "");
            }
        });
    };
    const statusConvert = (deviceStatus: DeviceStatus | undefined) => {
        if (deviceStatus === undefined || deviceStatus === DeviceStatus.BadConfig || deviceStatus === DeviceStatus.BadData || deviceStatus === DeviceStatus.Unknown) {
            return 'warn';
        }
        if (deviceStatus === DeviceStatus.Healthy) {
            return 'ok';
        }
        return 'error';
    };

    const columns: SortableTableHeaderType<TableDataType>[] = [
        {
            header: "",
            renderFunc: d => (<HoverTooltip hoverable={<StatusDot status={statusConvert(d?.status)} />}>{getStatusHoverText(d)}</HoverTooltip>),
            width: 25

        },
        {
            header: "Name",
            dataKey: "name",
            width: 200
        },
        {
            header: "Location",
            renderFunc: d => d?.facilityName ?? "Unassigned",
            dataKey: "facilityName",
            width: 180
        },
        {
            header: "IP Address",
            dataKey: "ipv4Address",
            width: 140
        },
        {
            header: "Version",
            dataKey: "firmwareVersion",
            width: 100,
            renderFunc: d => (
                <div className="flex flex-row items-center gap-x-2">
                    {d?.firmwareVersion} {needsUpdate(d!) && <HoverTooltip hoverable={<Icons.Warning className="text-error-icon" />}>This device needs a firmware update</HoverTooltip>}
                </div>
            ),

        },
        {
            header: <>
                {facilityId &&
                    <IconLink iconSize={20} Icon={Icons.PlusCircle} onClick={handleAddOnClick} />
                }
            </>
            ,
            width: 20,
            renderFunc: device => (
                (isLoading || device?.hasPendingDeviceCommands) ?
                    <Spinner /> :
                    <DropDownMenu>
                        {needsUpdate(device!) &&
                            <IconLink Icon={Icons.Upgrade} onClick={() => handleUpgradeOnClick(device!)}>Upgrade</IconLink>
                        }
                        <IconLink Icon={Icons.Edit} onClick={() => handleEditOnClick(device!)} >Edit</IconLink>
                        <IconLink Icon={Icons.Send} onClick={() => handleSendCommandClick(device!)}>Send Command</IconLink>
                        <IconLink Icon={Icons.Trash} onClick={() => handleDeleteOnClick(device!)} >Delete</IconLink>
                    </DropDownMenu>),
        }
    ];

    if (lastTargets?.length) {
        columns.splice(3, 0, {
            header: "Last Target",
            dataKey: "lastUpdate",
            renderFunc: d => {
                const lastUpdate = lastTargets.find(lt => lt.deviceId === d?.id!)?.lastUpdate;
                const locale = navigator.language || navigator.languages[0];
                const zone = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
                if (lastUpdate) {
                    return (<HoverTooltip placement="right" hoverable={format(lastUpdate, 'MMM dd HH:mm', { timeZone: zone })}>
                        <span>{lastUpdate.toLocaleDateString() + ' ' + lastUpdate.toLocaleTimeString()}</span>
                    </HoverTooltip>);
                } return "Unknown";
            },
            width: 180
        });
    }
    const devicesWithLastTarget = useMemo(() => {
        return devices?.map(d => ({ ...d, lastUpdate: lastTargets?.find(lt => lt.deviceId === d.id)?.lastUpdate }))
    }, [devices, lastTargets]);

    const filteredData = useMemo(() => {
        if (!miniSearch || !searchParams.get("q")?.length) {
            return devicesWithLastTarget;
        }
        const searchResult = miniSearch.search(searchParams.get("q")!);
        return searchResult.map(sr => devicesWithLastTarget?.find(x => x.id === sr.id) ?? {} as any);

    }, [searchParams, devicesWithLastTarget])
    const handleSearch = (terms: string | undefined | null) => {
        setSearchParams(existing => {
            if (terms) {
                existing.set("q", terms);
            } else {
                existing.delete("q");
            }
            return existing;
        })
    }
    return (
        <>
            <div className=" flex justify-end m-5">
                <SearchInput onSearch={handleSearch} initialSearch={searchParams.get("q")} />
            </div>
            <SortableTable
                isLoading={isLoading}
                onRowClick={handleOnRowClick}
                initialSortKey={"name"}
                columns={columns}
                data={filteredData}
            />
            {searchParams.get("q") && <div className="m-3 flex justify-center font-semibold text-brand-primary"><h3 className="mx-auto">{`${filteredData?.length} results from query: '${searchParams.get('q')}'`}</h3></div>}

            {facilityId &&
                <DeviceProvisionDialog
                    facilityId={facilityId}
                    show={showAddDialog}
                    onCancel={() => setShowAddDialog(false)}
                    onFinished={() => {
                        setShowAddDialog(false);
                        if (onChange) onChange();
                    }}
                />
            }
            {deviceDialog.renderDialog((model, helpers) => (
                <EditDeviceDetails section="all" device={model} helpers={helpers} />
            ))}
            {sendCommandDialog.renderDialog((device) => (
                <EditDeviceCommand command={commandToSend} deviceId={device?.id ?? ""} onChange={(command) => setCommandToSend(command)} />
            ), { closeOnOutsideClick: false })}
            {confirmDialog.renderDialog()}
        </>
    );
}