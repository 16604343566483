import { DateTime, IDateTime } from "./System.DateTime.gen";
import { Guid, IGuid } from "./System.Guid.gen";
import { String, IString } from "./System.String.gen";

export interface INewUserInvite {
	organizationName: string | undefined;

	id: string | undefined;

	dateCreated: string | undefined;

	dateLastUpdated: string | undefined;

	dateSent: string | undefined;

	organizationId: string | undefined;

	facilityId: string | undefined;

	userExists: boolean;

	email: string | undefined;

	firstName: string | undefined;

	lastName: string | undefined;

	name: string | undefined;

	roles: string[] | undefined;

	$type?: string | undefined;
}
export class NewUserInvite {
	constructor(data?: INewUserInvite) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.dateCreated) { this.dateCreated = new Date(data.dateCreated); }
		if (data.dateLastUpdated) { this.dateLastUpdated = new Date(data.dateLastUpdated); }
		if (data.dateSent) { this.dateSent = new Date(data.dateSent); }
	}

	organizationName: string | undefined;

	id: string | undefined;

	dateCreated: Date | undefined;

	dateLastUpdated: Date | undefined;

	dateSent: Date | undefined;

	organizationId: string | undefined;

	facilityId: string | undefined;

	userExists: boolean = false;

	email: string | undefined;

	firstName: string | undefined;

	lastName: string | undefined;

	name: string | undefined;

	roles: string[] | undefined;

	static $type: string = "CoreApi.Models.NewUserInvite, ";
	$type?: string | undefined = NewUserInvite.$type;
}

