import { OptionValue, IOptionValue } from "./View.OptionValue.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IOptionValueGroup {
	label: string | undefined;

	options: IOptionValue[];

	$type?: string | undefined;
}
export class OptionValueGroup {
	constructor(data?: IOptionValueGroup) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.options = data.options ? data.options.map((o: any) => new OptionValue(o)) : [];
	}

	label: string | undefined;

	@IsNotEmpty()
	options: OptionValue[] = [];

	static $type: string = "CoreApi.Models.View.OptionValueGroup, ";
	$type?: string | undefined = OptionValueGroup.$type;
}

