import { ApiResponse, DeviceType, Firmware, Firmwares, FirmwareType, getApiUrl, getDeviceTypeDisplayName, getFirmwareTypeDisplayName, getFirmwareVersionTypeDisplayName, rawPost, SensorType, UserError } from "@app/shared";
import { format } from "date-fns";
import { ChangeEventHandler, useRef } from "react";
import { useConfirmDialog, useDialog, useFetch } from "../../../hooks";
import { useEditModelDialog } from "../../../hooks/useEditModelDialog";
import { EnumSelect, IconLink, Icons, Input, InputLabel, InputRow, LabeledText, PageContainer, SortableTable } from "../../shared";
import { DropDownMenu } from "../../shared/DropDownMenu";

type FirmwareTableProps = {
    firmwareType: FirmwareType;
};

export const FirmwareTable: React.FC<FirmwareTableProps> = (props) => {
    const [deviceFirmwareCount] = useFetch(() => Firmwares.getFirmwareDeviceCount());
    const [deviceFirmware, , deviceFirmwareHelpers] = useFetch(() => Firmwares.getAll(props.firmwareType));

    const firmwareDialog = useEditModelDialog(Firmware);
    const confirm = useConfirmDialog();
    const firmwareFile = useRef<File>();
    const firmwareDetails = useDialog<Firmware>();

    const handleViewOnClick = (firmware?: Firmware) => {
        if (!firmware) return;
        firmwareDetails.show("Firmware Details", "Ok", firmware, () => { });
    };

    const handleDeleteOnClick = (firmware?: Firmware) => {
        if (!firmware) return;
        confirm.show("Delete Firmware", `Are you sure you want to delete this firmware [${firmware.firmwareVersion}]`, "Delete",async  () => {
            await Firmwares.deleteFirmware(firmware.id);
            deviceFirmwareHelpers.refreshData();
        });
    };

    const handleAddOnClick = () => {
        firmwareFile.current = undefined;

        firmwareDialog.show(async (toSave) => {
            if (firmwareFile.current === undefined) {
                throw new UserError(["You must add a firmware file!"]);
            }
            if (props.firmwareType === FirmwareType.Device || props.firmwareType === FirmwareType.Sensor) {
                const formData = new FormData();
                formData.append("firmware", JSON.stringify(toSave));
                formData.append("file", firmwareFile.current);

                let closureError = '';
                await rawPost(`/api/firmware/${props.firmwareType === FirmwareType.Sensor ? 'sensor' : 'device'}-firmware`, formData)
                    .catch(e => { throw e; })
                    .then(async r => {
                        if (r.ok) {
                            deviceFirmwareHelpers.refreshData();
                            return;
                        }
                        const resp = await r.json() as ApiResponse;
                        closureError = resp.error;
                        firmwareDialog.modelHelpers.setSummaryError([resp.error]);
                    });

                if (closureError?.length) return true;//errorMsg has not been updated yet
            } else {
                throw new UserError(["not implemented..."]);
            }
        }, "Add Firmware", { firmwareType: props.firmwareType });
    };

    const handleDownloadOnClick = async (firmware?: Firmware) => {
        if (!firmware) return;
        // const url = getApiUrl(`/api/firmware/download/${firmware.id}`);
        // const fileName = firmware?.firmwareType === FirmwareType.Device ? `MegaRadarFirmware.v${firmware.firmwareVersion}.zip` : `SensorFirmware.v${firmware.firmwareVersion}.zip`;        
        const fileUri = getApiUrl(`/api/firmware/download/${firmware.id}`);
        window.location.assign(fileUri);
    };

    const handleFirmwareFileOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.target.files?.[0];
        firmwareFile.current = file;
    };

    const getDeviceCount = (f?: Firmware) => {
        if (!f) return undefined;

        return deviceFirmwareCount?.find(dc => dc.firmwareVersion === f?.firmwareVersion)?.deviceCount ?? 0;
    };

    return (
        <>
            <PageContainer title={getFirmwareTypeDisplayName(props.firmwareType) + " Firmware"} titleRight={<IconLink Icon={Icons.PlusCircle} onClick={handleAddOnClick}>Add New</IconLink>}>
                <SortableTable
                    onRowClick={(f) => handleViewOnClick(f)}
                    columns={[
                        {
                            header: "Device Type",
                            dataKey: "deviceType",
                            width: 100,
                            renderFunc: (f => getDeviceTypeDisplayName(f!.deviceType))
                        },
                        {
                            header: "Created",
                            dataKey: "dateCreated",
                            width: 100,
                            renderFunc: (f => f?.dateCreated ? format(f.dateCreated, "MM-dd-yyyy") : "")
                        },
                        {
                            header: "Version",
                            dataKey: "firmwareVersion",
                            width: 100,
                            renderFunc: (f => `${f?.firmwareVersion} (${getFirmwareVersionTypeDisplayName(f!.firmwareVersionType)})`)
                        },
                        {
                            header: "Devices",
                            width: 75,
                            renderFunc: (f => getDeviceCount(f))
                        },
                        {
                            header: "",
                            width: 25,
                            renderFunc: (f) => (
                                <DropDownMenu>
                                    <IconLink Icon={Icons.OpenModal} onClick={() => handleViewOnClick(f)}>Details</IconLink>
                                    <IconLink Icon={Icons.Download} onClick={() => handleDownloadOnClick(f)} >Download</IconLink>
                                    <IconLink Icon={Icons.Trash} onClick={() => handleDeleteOnClick(f)}>Delete</IconLink>
                                </DropDownMenu>
                            )
                        }

                    ]}
                    data={deviceFirmware} />
            </PageContainer>
            {firmwareDialog.renderDialog((model, helpers) => (
                <>
                    {props.firmwareType === FirmwareType.Device ?
                        <InputRow>
                            <EnumSelect label="Device Type" enumType={DeviceType} {...helpers.bindingsFor("deviceType")} />
                        </InputRow>
                        :
                        <>
                            <InputRow>
                                <EnumSelect label="Sensor Type" enumType={SensorType} {...helpers.bindingsFor("sensorType")} />
                            </InputRow>
                            <InputRow>
                                <Input label="Firmware Version" {...helpers.bindingsFor("firmwareVersion")} />
                            </InputRow>
                        </>
                    }
                    <InputRow>
                        <InputLabel>Firmware File</InputLabel><br />
                        <input type="file" name="firmware" onChange={handleFirmwareFileOnChange} />
                        {firmwareFile.current &&
                            <div>
                                <p>Size: {firmwareFile.current.size / 1000}K</p>
                            </div>
                        }
                    </InputRow>
                </>
            ))}
            {confirm.renderDialog()}
            {firmwareDetails.renderDialog(f => (
                <div className="grid grid-cols-2">
                    <LabeledText label="Firmware Type">
                        {f && getFirmwareTypeDisplayName(f.firmwareType)}
                    </LabeledText>
                    <LabeledText label="Device Type">
                        {f && getDeviceTypeDisplayName(f.deviceType)}
                    </LabeledText>
                    <LabeledText label="Firmware Version">
                        {f?.firmwareVersion}
                    </LabeledText>
                    <LabeledText label="Version Type">
                        {f && getFirmwareVersionTypeDisplayName(f.firmwareVersionType)}
                    </LabeledText>
                    <LabeledText label="Filename">
                        {f?.firmwareFileName}
                    </LabeledText>
                    <LabeledText label="Hash">
                        {f?.firmwareHash}
                    </LabeledText>
                    <LabeledText label="Date Created">
                        {f?.dateCreated && format(f.dateCreated, "MM-dd-yyyy p")}
                    </LabeledText>
                    <LabeledText label="Device Count">
                        {getDeviceCount(f)}
                    </LabeledText>

                </div>
            ), { closeOnOutsideClick: true, showCancelButton: false })}
        </>
    );
}