import { DailyAverageByMonth, Reports, getTargetTypeDisplayName, useAuth } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { BarStackMapped, OptionallyStackedBarChart } from "./OptionallyStackedBarChart";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { useContext, useMemo } from "react";
import { TotalVolumeByDay } from "./TotalVolumeByDay";
import { useFetch } from "../../hooks";
import { ReportContext, buildStackedData, getZonedDateAsUTC, groupByKeys, monthLabeler, stackedTooltipBuilder, tooltipBuilder } from "./reportHelpers";
import { OptionallySplitLineChart, SeriesDetails } from "./OptionallySplitLineChart";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const MonthlyDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    const [data, error, { isLoading }] = useFetch(() => Reports.getAverageDailyVolumes(searchParams), [searchParams]);


    function handleClick(datum: any, stackKey?: string | number | symbol | undefined): void {
        const rParams = { ...reportConfig };

        rParams.selectedReportId = TotalVolumeByDay.key;
        if (reportConfig.inclusiveStart) {
            const zonedDate = utcToZonedTime(reportConfig.inclusiveStart, reportConfig.chartTimezone);
            const year = zonedDate.getFullYear();
            const month = (reportConfig.chartType === 'lines' ? datum.month : datum.data.stack);
            rParams.inclusiveStart = getZonedDateAsUTC(year, month, 1, reportConfig.chartTimezone);
            rParams.exclusiveEnd = getZonedDateAsUTC(year + (month === 12 ? 1 : 0), (month + 1) % 12, 1, reportConfig.chartTimezone);
        }
        reportConfig.context.setReportConfig(rParams);
    }
    const sortedData = useMemo(() => {
        if (!data?.monthlyAverages?.length) return [];

        let cData = groupByKeys(data.monthlyAverages, ['month', 'targetType'], 'averageDailyVolume').filter(x => x.averageDailyVolume > 0);//[...data];
        if (reportConfig.groupByTargetType)
            cData = buildStackedData(cData, "averageDailyVolume", "targetType");
        return cData?.sort((x, y) => x.month - y.month)
    }, [data, reportConfig.groupByTargetType]);

    const ttWithData = useMemo(() => Array.from(new Set(data?.monthlyAverages?.map((d) => d.targetType))), [data]);


    const seriesTT: SeriesDetails<BarStackMapped<DailyAverageByMonth>>[] = ttWithData.map(tt => {
        return {
            accessors: {
                xAccessor: dc => dc?.month,
                yAccessor: dc => dc?.$$BarStackMap[tt + '']
            },
            dataKey: tt + ''
        }
    });
    const series: SeriesDetails<BarStackMapped<DailyAverageByMonth>>[] = ttWithData.map(tt => {
        return {
            accessors: {
                xAccessor: dc => dc?.month,
                yAccessor: dc => dc?.averageDailyVolume
            },
            dataKey: tt + ''
        }
    });

    const chartProps = {
        ...props,
        toolTip: reportConfig.groupByTargetType ? stackedTooltipBuilder<DailyAverageByMonth>(numberFormatter, (d, tt) => getTargetTypeDisplayName(tt as any)!, undefined, x => {

            return 'Average Daily Volume: ' + format(new Date(2020, x.month - 1, 1), 'MMMM')
        }, d => `Based on data from ${d.numDaysIncluded} days`) : tooltipBuilder<DailyAverageByMonth>(x => { return format(new Date(2020, x.month - 1, 1), 'MMMM') }, v => numberFormatter.format(Math.round(v.averageDailyVolume)).toString(), 'Monthly Average Daily Volume', d => `Based on data from ${d.numDaysIncluded} days`),
        data: sortedData as BarStackMapped<DailyAverageByMonth>[],
        series: reportConfig.groupByTargetType ? seriesTT : series as any,
        onClick: handleClick,
        xTickCount: 12,
        xTickLabeler: monthLabeler,
        barStackKey: reportConfig.groupByTargetType ? "targetType" as keyof DailyAverageByMonth : undefined,
        stackOrGroupLabel: reportConfig.groupByTargetType ? getTargetTypeDisplayName as any : undefined,
        lineSplitKey: reportConfig.groupByTargetType ? "targetType" as keyof DailyAverageByMonth : undefined,
        lineSplitLabel: getTargetTypeDisplayName as any,
        xKey: (reportConfig.chartType === 'bars' ? "month" : "year") as keyof DailyAverageByMonth,
        yKey: "averageDailyVolume" as keyof DailyAverageByMonth
    };

    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.monthlyAverages?.length} isLoading={isLoading} size={props.size} >{
        reportConfig.chartType === 'bars' ?
            <OptionallyStackedBarChart<DailyAverageByMonth> {...chartProps} />
            : <OptionallySplitLineChart<DailyAverageByMonth> {...chartProps} />}
    </ReportLoadingErrorWrapper>);
}

export const AverageDailyVolumeByMonth: IConfiguredTrafficReport = {
    name: "by Month",
    key: "advm",
    description: "This report shows average daily traffic for the specified period",
    component: MonthlyDataLoader,
    defaultRange: DateOptions.AllData,
    defaultChartType: 'bars',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};