import { Double, IDouble } from "./System.Double.gen";
import { Vector2, IVector2 } from "./System.Numerics.Vector2.gen";

export interface IBackgroundImage {
	imageName: string | undefined;

	imageWidth: number | undefined;

	imageHeight: number | undefined;

	name: string | undefined;

	points: IVector2[] | undefined;

	$type?: string | undefined;
}
export class BackgroundImage {
	constructor(data?: IBackgroundImage) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.points = data.points ? data.points.map((p: any) => new Vector2(p)) : [];
	}

	imageName: string | undefined;

	imageWidth: number | undefined;

	imageHeight: number | undefined;

	name: string | undefined;

	points: Vector2[] | undefined = [];

	static $type: string = "Shared.Geometry.BackgroundImage, ";
	$type?: string | undefined = BackgroundImage.$type;
}

