export enum PortCommand {
	Open = 0,
	Close = 1,
}

export const PortCommandDisplayNames: { [key in keyof typeof PortCommand]?: string} = {
	"Open": "Open",
	0 : "Open",
	"Close": "Close",
	1 : "Close",
}

export const getPortCommandDisplayName = (key: PortCommand | (keyof typeof PortCommand & string)) => {
	return PortCommandDisplayNames[key];
}

