export enum TargetTypes {
	Unknown = 0,
	SemiTrailerTruck = 1,
	PassengerTruck = 2,
	Car = 4,
	Motorcycle = 8,
	Bike = 16,
	Pedestrian = 32,
	Camper = 64,
	Bus = 128,
	Trailer = 256,
	Boat = 512,
	UtilityVehicle = 1024,
	Bobtail = 2048,
	Wheel = 4096,
}

export const TargetTypesDisplayNames: { [key in keyof typeof TargetTypes]?: string} = {
	"Unknown": "Unknown",
	0 : "Unknown",
	"SemiTrailerTruck": "SemiTrailerTruck",
	1 : "SemiTrailerTruck",
	"PassengerTruck": "PassengerTruck",
	2 : "PassengerTruck",
	"Car": "Car",
	4 : "Car",
	"Motorcycle": "Motorcycle",
	8 : "Motorcycle",
	"Bike": "Bike",
	16 : "Bike",
	"Pedestrian": "Pedestrian",
	32 : "Pedestrian",
	"Camper": "Camper",
	64 : "Camper",
	"Bus": "Bus",
	128 : "Bus",
	"Trailer": "Trailer",
	256 : "Trailer",
	"Boat": "Boat",
	512 : "Boat",
	"UtilityVehicle": "UtilityVehicle",
	1024 : "UtilityVehicle",
	"Bobtail": "Bobtail",
	2048 : "Bobtail",
	"Wheel": "Wheel",
	4096 : "Wheel",
}

export const getTargetTypesDisplayName = (key: TargetTypes | number |  (keyof typeof TargetTypes & string)) => {
	if (isNaN(+key)) return TargetTypesDisplayNames[key];
	return Object.keys(TargetTypesDisplayNames).filter(v => parseInt(v) >= 0 && (parseInt(v) & Number(key)) > 0).map(v => TargetTypesDisplayNames[v as (keyof typeof TargetTypes)]).join(', ');
}

