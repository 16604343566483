import { DetectionZone, Device, Facilities, Facility, ScreenLine, Spot, Vector2 } from "@app/shared";
import { useState, useEffect, createContext } from 'react';
import { MapShapeTypes } from "../components/shared/maps";
import { ICoordsHelper } from "./coordsHelpers";

export type Coordinates = {
    lat: number;
    lng: number;
};
export function useFacilityCenter(facility: Facility | undefined | string): [coords: google.maps.LatLng | undefined, isLoading: boolean] {
    const [center, setCenter] = useState<google.maps.LatLng | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            let currentFacility = facility;
            if (!currentFacility) {
                setCenter(undefined);
                setIsLoading(false);
                return;
            }
            if (typeof currentFacility === 'string') {
                const f = await Facilities.getById(currentFacility);
                if (!f) {
                    return;
                }
                currentFacility = f;
            }
            if (typeof currentFacility.originReferencePointLatLng?.origin?.x === 'number') {
                setCenter(new google.maps.LatLng({ lng: currentFacility.originReferencePointLatLng.origin.x, lat: currentFacility.originReferencePointLatLng?.origin?.y }));
                setIsLoading(false);
                return;
            }
            setIsLoading(true);
            try {
                const sAdd = [currentFacility?.address1, currentFacility?.address2, currentFacility?.city, currentFacility?.state, currentFacility?.zip].join(' ');

                if (sAdd.length > 5) {
                    const result = await getCoordinates(sAdd, process.env.REACT_APP_GOOGLE_PLACES_API_KEY as string);
                    if (result && result.lat > 0) {
                        setCenter(new google.maps.LatLng(result));
                        return;
                    }
                    const latLng = convertDMSStringToDecimalDegrees(sAdd);
                    if (latLng) {
                        setCenter(new google.maps.LatLng(latLng));
                        return;
                    }
                }
                if (currentFacility.name.length > 0) {
                    const result = await getCoordinates(currentFacility.name, process.env.REACT_APP_GOOGLE_PLACES_API_KEY as string);
                    if (result && result.lat > 0) {
                        setCenter(new google.maps.LatLng(result));
                        return;
                    }
                }
                const omniCenter = await getCoordinates(OmnisightUSAAddress, process.env.REACT_APP_GOOGLE_PLACES_API_KEY as string);
                if (omniCenter && omniCenter.lat > 0) {
                    setCenter(new google.maps.LatLng(omniCenter));
                    return;
                }
            } finally {
                setIsLoading(false);
            }
        };//fetchData
        fetchData();
    }, [facility]);

    return [center, isLoading];
}

export function isValidLongitude(x: number) { return x > -180 && x < 180; }
export function isValidLatitude(y: number) { return y > -90 && y < 90; }

function convertDMSStringToDecimalDegrees(dmsString: string) {
    // Regular expression to extract degrees, minutes, and seconds
    const dmsRegex = /(\d+)\s*°\s*(\d+)['’]\s*(\d+(?:\.\d+)?)?\s*["”]\s*([nsNS])\s*(\d+)\s*°\s*(\d+)['’]\s*(\d+(?:\.\d+)?)?\s*["”]\s*([ewEW])/;

    // Match the provided string with the regular expression
    const matches = dmsString.match(dmsRegex);

    if (!matches) {
        return undefined;
    }

    // Parse degrees, minutes, and seconds as numbers
    const latDegrees = parseFloat(matches[1]);
    const latMinutes = parseFloat(matches[2]);
    const latSeconds = parseFloat(matches[3] ?? 0); // Treat as 0 if the fractional part is missing
    const latDirection = matches[4].toUpperCase(); // Case-insensitive conversion

    const lonDegrees = parseFloat(matches[5]);
    const lonMinutes = parseFloat(matches[6]);
    const lonSeconds = parseFloat(matches[7] ?? 0); // Treat as 0 if the fractional part is missing
    const lonDirection = matches[8].toUpperCase(); // Case-insensitive conversion

    // Calculate decimal degrees for latitude and longitude
    let decimalLat = latDegrees + (latMinutes / 60) + (latSeconds / 3600);
    let decimalLon = lonDegrees + (lonMinutes / 60) + (lonSeconds / 3600);

    // Adjust latitude and longitude based on direction (N/S and E/W)
    if (latDirection === "S") {
        decimalLat *= -1; // Southern hemisphere is negative
    }

    if (lonDirection === "W") {
        decimalLon *= -1; // Western hemisphere is negative
    }

    return { lat: decimalLat, lng: decimalLon };
}


export const OmnisightUSAAddress = '295 Seven Farms Drive Suite C-289 Daniel Island, SC 29492';

async function getCoordinates(address: string, key: string) {
    try {
        const resp = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + '&key=' + key);

        if (!resp.ok) {
            throw new Error("Failed to fetch data from the Google");
        }

        const data = await resp.json();

        if (["OK", "ZERO_RESULTS"].indexOf(data.status) === -1) {
            throw new Error("Google returned an error: " + data.status);
        }
        if (data.results?.length) {
            const result = data.results[0].geometry.location as google.maps.LatLngLiteral;
            return result;
        } return undefined;
    } catch (error) {
        console.error(error);
        throw error; // propagate up
    }
}



export type MapObjArray = Array<google.maps.Polygon | google.maps.Polyline | google.maps.Circle | google.maps.Marker>;
export const MapContext = createContext<[google.maps.Map | undefined, React.Dispatch<React.SetStateAction<google.maps.LatLngBounds>>]>([undefined, () => new google.maps.LatLngBounds()]);
export const CLOSESHAPETHRESHOLD = 2;//meters

export function circle(color: string) {
    return {
        path: 0,//as google.maps.SymbolPath.Circle,
        fillOpacity: 1.0,
        fillColor: color,
        strokeOpacity: 1.0,
        strokeColor: color,
        strokeWeight: 1.0,
        scale: 5,
    };
}




export function animateCircle(path: google.maps.LatLng[], map?: google.maps.Map, color?: string) {

    const vertexCircles = path.map(pos => {
        return new google.maps.Marker({
            icon: circle(color ?? "#5900ff"),
            position: pos,
            map: map,
            clickable: false,
            animation: google.maps.Animation.DROP
        });
    });
    setTimeout(() => {
        vertexCircles.forEach(c => {
            c.setVisible(false);
            c.setMap(null);
        });
    }, 1000);
}




function calculateCentroid(path: google.maps.LatLng[]): google.maps.LatLngLiteral {
    //const path = polyline.getPath().getArray();
    let latSum = 0;
    let lngSum = 0;
    for (const point of path) {
        latSum += point.lat();
        lngSum += point.lng();
    }
    const latAvg = latSum / path.length;
    const lngAvg = lngSum / path.length;
    return { lat: latAvg, lng: lngAvg };
}

export function rotatePoint(point: google.maps.LatLngLiteral, angle: number, pivot: google.maps.LatLngLiteral): google.maps.LatLngLiteral {
    const radians = angle * (Math.PI / 180);
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const x = point.lat - pivot.lat;
    const y = point.lng - pivot.lng;
    const newX = x * cos - y * sin + pivot.lat;
    const newY = x * sin + y * cos + pivot.lng;
    return { lat: newX, lng: newY };
}

// Function to rotate a polyline by a specified angle around a pivot point
export function rotatePoly(poly: google.maps.Polygon | google.maps.Polyline, angle: number, center: google.maps.LatLngLiteral): google.maps.LatLngLiteral[] {
    return rotatePath(poly.getPath(), angle, center);
}

export function rotatePath(path: google.maps.MVCArray<google.maps.LatLng>, angle: number, center: google.maps.LatLngLiteral): google.maps.LatLngLiteral[] {
    const rotatedPath: google.maps.LatLngLiteral[] = [];
    path.forEach((point) => {
        const rotatedPoint = rotatePoint(point.toJSON(), angle, center);
        rotatedPath.push(rotatedPoint);
    });
    return rotatedPath;
}

export function scalePoly(poly: google.maps.Polygon | google.maps.Polyline, center: google.maps.LatLngLiteral, scaleFactor: number): google.maps.LatLngLiteral[] {
    return scalePath(poly.getPath(), center, scaleFactor);
}

export function scalePath(path: google.maps.MVCArray<google.maps.LatLng>, center: google.maps.LatLngLiteral, scaleFactor: number): google.maps.LatLngLiteral[] {
    if (Math.abs(scaleFactor) > 10) {
        throw new Error('cannot scale polygon');
    }
    const temperedScale = 1 + (scaleFactor / 500);

    const newPath: google.maps.LatLngLiteral[] = [];

    // Iterate over each vertex of the path
    path.forEach((vertex) => {
        // Scale the coordinates of each vertex
        const newLat = center.lat + (temperedScale * (vertex.lat() - center.lat));
        const newLng = center.lng + (temperedScale * (vertex.lng() - center.lng));

        newPath.push({ lat: newLat, lng: newLng });
    });
    return newPath;
}
export function getShapeCenter(shape: MapShapeTypes, helper: ICoordsHelper) {
    if (shape.$type === Device.$type) {
        const s = shape as Device;
        return new google.maps.LatLng(helper.toLatLng(new Vector2({ y: s.positionY, x: s.positionX })));
    } else if (shape.$type === DetectionZone.$type || shape.$type === ScreenLine.$type) {
        const bounds = new google.maps.LatLngBounds();
        const s = shape as DetectionZone | ScreenLine;
        s.points?.map(helper.toLatLng).forEach(pt => bounds.extend(pt));
        return bounds.getCenter();
    } else if (shape.$type === Spot.$type) {
        const s = shape as Spot;
        if (!s.origin) {
            throw new Error('Spot must have origin');
        }
        return new google.maps.LatLng(helper.toLatLng(new Vector2({ y: s.origin.y, x: s.origin.x })));
    } else {
        throw new Error('shape type not supported')
    }
}

export function createVertexCircle(pos: google.maps.LatLng, parentMap: google.maps.Map, isClickable: boolean, color?: string) {
    const circle = new google.maps.Circle({
        center: pos,
        map: parentMap,
        clickable: isClickable,
        draggable: isClickable,
        radius: 3,
        fillColor: color ?? "#5900ff",
        fillOpacity: 1,
        zIndex: 11,
        visible: true,
        strokeColor: color ?? "#5900ff"
    });
    return circle
}

export const scaleAndRotatePaths = (op: 'scale' | 'rotate' | 'groupRotate', poly: google.maps.Polygon | google.maps.Polyline | undefined, magnitude: number, vertexCircles?: google.maps.Circle[][], pathCenters?: google.maps.LatLngLiteral[]) => {
    if (!poly) { return; }
    const isLine = !('getPaths' in poly);

    let currentPaths = [];
    if (isLine) {
        currentPaths = [poly.getPath()];
    } else {
        currentPaths = poly.getPaths().getArray();
    }

    const groupBounds = new google.maps.LatLngBounds();
    if (pathCenters) {
        pathCenters = [];
    }
    if (!pathCenters?.length) {
        pathCenters = currentPaths.map(x => {
            const subPolyCenter = calculateCentroid(x.getArray());
            groupBounds.extend(subPolyCenter);
            return subPolyCenter;
        });
    }
    if (!pathCenters.length) {
        return;
    }
    const newPaths = currentPaths.map((path, idx) => {
        let newPath = [];
        const pathCenter = pathCenters?.[idx]!;

        if (op === 'scale') {//scaling
            newPath = scalePath(path, pathCenter, magnitude);
            return newPath;
        } else {//rotating
            if (op === 'rotate') {
                newPath = rotatePath(path, magnitude, pathCenter);
            } else {
                newPath = rotatePath(path, magnitude, groupBounds.getCenter().toJSON());
            }
            return newPath;
        }
    });
    if (isLine) {
        poly.setPath(newPaths[0]);
    } else {
        poly.setPaths(newPaths);
    }
    if (vertexCircles && newPaths.length) {
        vertexCircles.forEach((poly, pIdx) => {
            poly.forEach((c, idx) => c.setCenter(newPaths[pIdx][idx]));
        });
    }
}

const polygonDefaults = {
    visible: true,
    strokeColor: "hsl(59.05882352941176, 100%, 50%)",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "hsl(59.05882352941176, 100%, 50%)",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    zIndex: 10
} as google.maps.PolygonOptions;

export function createGooglePolygon(paths: Array<Array<Vector2>>, coordsHelper: ICoordsHelper, parentMap?: google.maps.Map, options?: google.maps.PolygonOptions): [google.maps.Polygon, google.maps.LatLng | undefined] {
    const bounds = new google.maps.LatLngBounds();
    const polyCoords = paths.map(path => {
        const pts = path.map(pt => {
            const c = coordsHelper.toLatLng(pt);
            bounds.extend(c);
            return c;
        });
        while (pts?.length && pts[0].lat() === pts[pts.length - 1].lat() && pts[0].lng() === pts[pts.length - 1].lng()) {
            pts?.pop();//pop off last coords as google closes polys automatically
        }
        return pts;
    });

    // Construct the polygon.
    const poly = new google.maps.Polygon({
        ...polygonDefaults,
        ...options,
        map: parentMap,
        paths: polyCoords,
        fillColor: options?.fillColor ?? polygonDefaults.fillColor,
        strokeColor: options?.strokeColor ?? polygonDefaults.strokeColor
    });
    poly.setVisible(true);
    return [poly, bounds.getCenter()];
};

const polylineDefaults = {
    strokeColor: "red",
    strokeOpacity: 1.0,
    strokeWeight: 4,
    clickable: false,
    zIndex: 15
} as google.maps.PolylineOptions;

export function createGooglePolyline(path: Array<Vector2>, coordsHelper: ICoordsHelper, parentMap?: google.maps.Map, options?: google.maps.PolylineOptions): [google.maps.Polyline, google.maps.LatLng | undefined] {
    const bounds = new google.maps.LatLngBounds();
    const polyCoords = path.map(pt => {
        const c = coordsHelper.toLatLng(pt);
        bounds.extend(c);
        return c;
    });

    while (polyCoords?.length && polyCoords[0].lat() === polyCoords[polyCoords.length - 1].lat() && polyCoords[0].lng() === polyCoords[polyCoords.length - 1].lng()) {
        polyCoords?.pop();//pop off last coords as google closes polys automatically
    }

    const linePath = new google.maps.Polyline({
        ...polylineDefaults,
        ...options,
        path: polyCoords,
        strokeColor: options?.strokeColor ?? polylineDefaults.strokeColor,
        map: parentMap
    });
    return [linePath, bounds.getCenter()];
}