import { Ruler, Vector2 } from "../models";

declare module "../models/Shared.Geometry.Ruler.gen" {
    interface Ruler {
        clampPointIfClosing(point: Vector2, threshold: number): Vector2;
        isComplete(): boolean;
    }
}

Ruler.prototype.isComplete = function () {
    return (this.points?.length === 2);
}
Ruler.prototype.clampPointIfClosing = function (point: Vector2, threshold: number) {
    return point;
}