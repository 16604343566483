import { Devices, Facility } from "@app/shared";
import { useFetch } from "../../../hooks";
import { DevicesTable } from "../../shared";

type FacilityDevicesProps = {
    facility: Facility;
    onChange: () => any;
};
export const FacilityDevices: React.FC<FacilityDevicesProps> = ({ facility, onChange }) => {
    const [devices, , helpers] = useFetch(() => Devices.getByFacilityId(facility.id))

    return (
        <DevicesTable devices={devices} isLoading={helpers.isLoading} facilityId={facility.id} onChange={() => {
            helpers.refreshData();
            if (onChange) onChange();
        }} />
    );
}