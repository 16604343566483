import { Spot, Vector2 } from "../models";

declare module "../models/Shared.Geometry.Spot.gen" {
    interface Spot {
        clampPointIfClosing(point: Vector2, threshold: number): Vector2;
        isComplete(): boolean;
    }
}

Spot.prototype.isComplete = function () {
    if (this.points?.length === 1) {
        this.origin = this.points![0];
        return true;
    }
    return false;
}
Spot.prototype.clampPointIfClosing = function (point: Vector2, threshold: number) {
    return point;
}