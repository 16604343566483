import { Button, ButtonVariants, IUserLogin, NewUserWithOrganization, useAuth, UserLogin, Users } from "@app/shared";
import { useNavigate } from "react-router-dom";
import { Pages } from "..";
import { useModel } from "../../../hooks";
import { NoAuthAccountLayout } from "../../layouts";
import { Input, InputRow, PasswordInput } from "../../shared";

export const CreateAccount: React.FC = (props) => {
    const [user, helpers] = useModel(NewUserWithOrganization);
    const auth = useAuth();
    const navigate = useNavigate();

    const handleCreateOnClick = async () => {
        helpers.validateWithAction(async () => {
            await Users.createUser(user as NewUserWithOrganization);
            try {
                await auth.logIn(new UserLogin({ email: user.emailAddress, password: user.password } as IUserLogin))
            } catch (e) { console.error("Error logging in... continuing to navigate to the view facilities page", e); }
            navigate(Pages.ViewFacilityGroups.getUrl());
        });
    };

    const handleCancelOnClick = () => {
        navigate(Pages.Login.getUrl());
    };

    return (
        <NoAuthAccountLayout>
            <div className={helpers.modelContainerClassNames}>
                <h2>New User Details</h2>
                <p>Please provide the following details about you:</p>
                <helpers.ErrorSummary />
                <InputRow>
                    <Input label="First Name" {...helpers.bindingsFor("firstName")} />
                    <Input label="Last Name" {...helpers.bindingsFor("lastName")} />
                    <Input label="Email" {...helpers.bindingsFor("emailAddress")} />
                    <PasswordInput label="Password" {...helpers.bindingsFor("password")} />
                    <PasswordInput label="Confirm Password" {...helpers.bindingsFor("confirmPassword")} />
                </InputRow>
                <h2 className="pt-5">Organization Details</h2>
                <p>If your organization already has an account, you may want to consider having someone send you an invite to the organization.</p>
                <InputRow>
                    <Input label="Organization Name" {...helpers.bindingsFor("organizationName")} />
                    <Input label="Phone" {...helpers.bindingsFor("organizationPhoneNumber")} />
                    <Input label="Address" {...helpers.bindingsFor("organizationAddress")} />
                    <Input label="Address Line 2" {...helpers.bindingsFor("organizationAddress2")} />
                    <Input label="City" {...helpers.bindingsFor("organizationCity")} />
                    <Input label="State" {...helpers.bindingsFor("organizationState")} />
                    <Input label="Zip" {...helpers.bindingsFor("organizationZipcode")} />
                </InputRow>
                <div className="flex pt-5 gap-5 place-items-center justify-center">
                    <Button onClick={handleCreateOnClick}>Create</Button>
                    <Button variant={ButtonVariants.Secondary} onClick={handleCancelOnClick}>Cancel</Button>
                </div>
            </div>
        </NoAuthAccountLayout>
    );
}