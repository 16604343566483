import { Device, Facility, TrafficReportConfiguration } from "@app/shared";
import { AnnualVolumeComparisonReport } from "./AnnualVolumeComparison";
import { AverageDailyVolumeByMonth } from "./AverageDailyVolumeByMonth";
import { AverageDailyVolumeByYear } from "./AverageDailyVolumeByYear";
import { AverageVelocityMapReport } from "./AverageVelocityMap";
import { BusiestDayReport } from "./BusiestDayReport";
import { DesignDayVolumesReport } from "./DesignDayVolumes";
import { FacilityUsageByDay } from "./FacilityUsageByDay";
import { FacilityUsageByHour } from "./FacilityUsageByHour";
import { FacilityUsageByMonth } from "./FacilityUsageByMonth";
import { FacilityUsageByYear } from "./FacilityUsageByYear";
import { DateOptions } from "./IConfiguredTrafficReport";
import { MonthlyComparisonReport } from "./MonthlyVolumeComparison";
import { TargetVelocitiesReport } from "./TargetVelocities";
import { TotalVolumeByDay } from "./TotalVolumeByDay";
import { TotalVolumeByHour } from "./TotalVolumeByHour";
import { TotalVolumeByMonth } from "./TotalVolumeByMonth";
import { TotalVolumeByYear } from "./TotalVolumeByYear";
import { WeeklyVolumeComparisonReport } from "./WeeklyVolumeComparison";

export const GroupedReportConfigurations = [
    {
        label: 'Average Daily Volume',
        includeLabelInTitle: true,
        values: [
            AverageDailyVolumeByYear,
            AverageDailyVolumeByMonth
        ]
    },
    {
        label: 'Total Volume',
        includeLabelInTitle: true,
        values: [
            TotalVolumeByYear,
            TotalVolumeByMonth,
            TotalVolumeByDay,
            TotalVolumeByHour
        ]
    },
    {
        label: 'Location Utilization',
        includeLabelInTitle: true,
        values: [
            FacilityUsageByYear,
            FacilityUsageByMonth,
            FacilityUsageByDay,
            FacilityUsageByHour
        ]
    },
    {
        label: 'Comparisons',
        includeLabelInTitle: false,
        values: [
            AnnualVolumeComparisonReport,
            MonthlyComparisonReport,
            WeeklyVolumeComparisonReport
        ]
    },
    {
        label: 'Other',
        includeLabelInTitle: false,
        values: [
            BusiestDayReport,
            DesignDayVolumesReport,
            AverageVelocityMapReport,
            TargetVelocitiesReport
        ]
    }
]


export type SelectedReportConfiguration = Omit<Omit<TrafficReportConfiguration, 'inclusiveStart'>, 'exclusiveEnd'> & {
    selectedReportId: string,
    selectedDateId: DateOptions,
    chartType: 'bars' | 'lines' | 'other',
    chartTimezone: string,
    inclusiveStart?: Date,
    exclusiveEnd?: Date,
    context: {
        facility?: Facility,
        device?: Device
        setReportConfig: (cfg: SelectedReportConfiguration) => void
    }
};

export const DefaultReport = GetReport('advy');//Average Daily Volume Annually

export function GetReport(reportKey: string) {
    const group = GroupedReportConfigurations.find(rc => rc.values.find(x => x.key === reportKey));
    return group?.values.find(x => x.key === reportKey)!;
}