import { SensorConfigType } from "./Shared.SensorConfigType.gen";

export interface ISensorConfiguration {
	id: string | undefined;

	deviceId: string | undefined;

	configType: SensorConfigType;

	$type?: string | undefined;
}
export class SensorConfiguration {
	constructor(data?: ISensorConfiguration) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	deviceId: string | undefined;

	configType: SensorConfigType = 0;

	static $type: string = "CoreApi.Models.SensorConfiguration, ";
	$type?: string | undefined = SensorConfiguration.$type;
}

