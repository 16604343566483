import { TimeSpan, ITimeSpan } from "./System.TimeSpan.gen";
import { DayOfWeek } from "./System.DayOfWeek.gen";
import { DateTimeKind } from "./System.DateTimeKind.gen";

export interface IDateTime {
	date: string | undefined;

	day: number;

	dayOfWeek: DayOfWeek;

	dayOfYear: number;

	hour: number;

	kind: DateTimeKind;

	millisecond: number;

	microsecond: number;

	nanosecond: number;

	minute: number;

	month: number;

	second: number;

	ticks: number;

	timeOfDay: ITimeSpan | undefined;

	year: number;

	$type?: string | undefined;
}
export class DateTime {
	constructor(data?: IDateTime) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.date) { this.date = new Date(data.date); }
		if (data.timeOfDay) { this.timeOfDay = new TimeSpan(data.timeOfDay); }
	}

	date: Date | undefined = new Date();

	day: number = 0;

	dayOfWeek: DayOfWeek = 0;

	dayOfYear: number = 0;

	hour: number = 0;

	kind: DateTimeKind = 0;

	millisecond: number = 0;

	microsecond: number = 0;

	nanosecond: number = 0;

	minute: number = 0;

	month: number = 0;

	second: number = 0;

	ticks: number = 0;

	timeOfDay: TimeSpan | undefined;

	year: number = 0;

	static $type: string = "System.DateTime, ";
	$type?: string | undefined = DateTime.$type;
}

