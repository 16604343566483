/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post } from "./api_client";
import { OmniUser, IOmniUser, OptionValueString, IOptionValueString, UserRoleClaim, IUserRoleClaim, NewUserWithOrganization, INewUserWithOrganization, AcceptedTerms, IAcceptedTerms } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getUsers(orgId?: string, facId?: string): ApiResult<OmniUser[]> {
		const queryParams = [orgId !== undefined ? `orgId=${orgId}` : undefined,
			facId !== undefined ? `facId=${facId}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IOmniUser[]>(`/api/user/${query}`).then(result => result === undefined ? undefined : result.map(o => new OmniUser(o)));
}

export async function saveUserOrganizationRoles(userId?: string, orgId?: string, roleNames?: string[]): ApiResult<undefined> {
	return post(`/api/user/${ userId }/save-roles-for-organization/${ orgId }`, roleNames);
}

export async function saveUserFacilityRoles(userId?: string, facId?: string, roleNames?: string[]): ApiResult<undefined> {
	return post(`/api/user/${ userId }/save-roles-for-facility/${ facId }`, roleNames);
}

export async function getAllRoles(): ApiResult<OptionValueString[]> {
	return get<IOptionValueString[]>(`/api/user/role-options`).then(result => result === undefined ? undefined : result.map(o => new OptionValueString(o)));
}

export async function getUser(userId?: string): ApiResult<OmniUser> {
	return get<IOmniUser>(`/api/user/${ userId }`).then(result => result === undefined ? undefined :  new OmniUser(result));
}

export async function getCurrentUserRoleClaims(): ApiResult<UserRoleClaim[]> {
	return get<IUserRoleClaim[]>(`/api/user/user-role-claims`).then(result => result === undefined ? undefined : result.map(o => new UserRoleClaim(o)));
}

export async function setLastViewedOrganizationId(orgId?: string): ApiResult<undefined> {
	return post(`/api/user/last-viewed-orgid`, orgId);
}

export async function createUser(newUser: NewUserWithOrganization): ApiResult<string> {
	return post<string>(`/api/user`, newUser);
}

export async function acceptTerms(userId?: string, acceptedTerms?: AcceptedTerms): ApiResult<undefined> {
	return post(`/api/user/${ userId }/accepted-terms`, acceptedTerms);
}
