import { ApiResult, DailyAverageByYear, DesignDayVolumes, Reports, getTargetTypeDisplayName, useAuth } from "@app/shared";
import { useFetch } from "../../hooks";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { AverageDailyVolumeByYearLoader } from "./AverageDailyVolumeByYear";
import { ReportContext, buildLabeler, stackedTooltipBuilder, tooltipBuilder } from "./reportHelpers";
import { format } from "date-fns";
import { useContext, useMemo } from "react";
import useRenderingTrace from "../../hooks/useRenderingTrace";

async function GetDesignDayVolumeByYear(result: ApiResult<DesignDayVolumes>) { // probably shouldn't consider incomplete months based on data range
    const volumes = await result;
    const monthlyTotals = [] as Array<{ year: number, month: number, total: number }>;
    //strip out all but the largest month per year from the data set
    volumes?.monthlyAverages?.forEach(datum => {
        const month = monthlyTotals.find(x => x.month === datum.month && x.year === datum.year);
        if (!!!month) {
            monthlyTotals.push({ year: datum.year, month: datum.month, total: datum.averageDailyVolume });
        } else {
            month.total += datum.averageDailyVolume;
        }
    });
    const filteredMonthlyTotals = monthlyTotals.reduce((acc: Array<{ year: number, month: number, total: number }>, curr: { year: number, month: number, total: number }) => {
        const existingEntry = acc.find(entry => entry.year === curr.year);

        if (!existingEntry) {
            // If no entry exists for the current year, add it to the accumulator
            acc.push(curr);
        } else if (curr.total > existingEntry.total) {
            // If the current entry has a larger total, replace the existing entry
            Object.assign(existingEntry, curr);
        }

        return acc;
    }, []);

    const annuals = volumes?.monthlyAverages
        ?.filter(x => !!filteredMonthlyTotals.find(y => y.month === x.month && y.year === x.year))
        .map(x => {
            const dabY = new DailyAverageByYear({ averageDailyVolume: x.averageDailyVolume, numDaysIncluded: -1, targetType: x.targetType, year: x.year });
            (dabY as any).month = x.month;
            return dabY;
        });
    return { ...volumes, annualAverages: annuals } as DesignDayVolumes;
}



const ChartDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const { numberFormatter } = useAuth();
    useRenderingTrace('DDV DataLoader', [props]);
    const [data, isLoading] = useFetch(() => GetDesignDayVolumeByYear(Reports.getAverageDailyVolumes(searchParams)), [searchParams]);
    //TODO: Should avoid loading data here by using the monthly loader instead of the yearly so we don't lose month as data passes through components

    const tickLabeler = buildLabeler((str) => {
        const year = parseInt(str);
        const datum = data?.annualAverages?.find(x => x.year === year);
        if (datum) {
            return format(new Date(year, (datum as any).month - 1, 1), 'MMMM yyyy')
        }
        return str;
    });
    const toolTip = useMemo(() => {
        if (!data?.annualAverages?.length)
            return undefined;
        if (reportConfig.groupByTargetType) {
            return stackedTooltipBuilder<DailyAverageByYear>(numberFormatter, (d, tt) => getTargetTypeDisplayName(tt as any)!, undefined, 'Design Day Volume');
        }
        return tooltipBuilder<DailyAverageByYear>(x => {
            const datum = data?.annualAverages?.find(y => y.year === x.year);
            return format(new Date(datum!.year, (datum as any)!.month - 1, 1), 'MMMM yyyy')
        }, v => numberFormatter.format(v.averageDailyVolume), 'Design Day Volume')
    }, [reportConfig.groupByTargetType, data, numberFormatter])

    return (<AverageDailyVolumeByYearLoader {...props}
        dataToChart={x => GetDesignDayVolumeByYear(Reports.getAverageDailyVolumes(x))}
        xTickLabeler={tickLabeler}
        toolTip={toolTip}
    />);
}


export const DesignDayVolumesReport = {
    name: "Annual Design Day Volume Report",
    description: "This report shows the month with the maximum average daily volume for every year",
    component: ChartDataLoader,
    defaultChartType: 'bars',
    defaultRange: DateOptions.AllData,
    key: 'add',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true
    }
} as IConfiguredTrafficReport;