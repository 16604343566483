export enum CustomClaimValue {
	EditDetails = 0,
	EditSiteLayout = 1,
	ManageSettings = 2,
	ManageUsers = 3,
	Delete = 4,
	SuperAdmin = 5,
}

export const CustomClaimValueDisplayNames: { [key in keyof typeof CustomClaimValue]?: string} = {
	"EditDetails": "EditDetails",
	0 : "EditDetails",
	"EditSiteLayout": "EditSiteLayout",
	1 : "EditSiteLayout",
	"ManageSettings": "ManageSettings",
	2 : "ManageSettings",
	"ManageUsers": "ManageUsers",
	3 : "ManageUsers",
	"Delete": "Delete",
	4 : "Delete",
	"SuperAdmin": "SuperAdmin",
	5 : "SuperAdmin",
}

export const getCustomClaimValueDisplayName = (key: CustomClaimValue | (keyof typeof CustomClaimValue & string)) => {
	return CustomClaimValueDisplayNames[key];
}

