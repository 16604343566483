export enum TargetStatus {
	Unknown = 0,
	Moving = 1,
	Parked = 2,
	Fueling = 3,
	Stopped = 4,
	Inside = 5,
	New = 6,
	Lost = 7,
	Offsite = 8,
}

export const TargetStatusDisplayNames: { [key in keyof typeof TargetStatus]?: string} = {
	"Unknown": "Unknown",
	0 : "Unknown",
	"Moving": "Moving",
	1 : "Moving",
	"Parked": "Parked",
	2 : "Parked",
	"Fueling": "Fueling",
	3 : "Fueling",
	"Stopped": "Stopped",
	4 : "Stopped",
	"Inside": "Inside",
	5 : "Inside",
	"New": "New",
	6 : "New",
	"Lost": "Lost",
	7 : "Lost",
	"Offsite": "Offsite",
	8 : "Offsite",
}

export const getTargetStatusDisplayName = (key: TargetStatus | (keyof typeof TargetStatus & string)) => {
	return TargetStatusDisplayNames[key];
}

