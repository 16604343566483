export enum AggregationLevel {
	Hour = 0,
	Day = 1,
	Month = 2,
	Year = 3,
}

export const AggregationLevelDisplayNames: { [key in keyof typeof AggregationLevel]?: string} = {
	"Hour": "Hour",
	0 : "Hour",
	"Day": "Day",
	1 : "Day",
	"Month": "Month",
	2 : "Month",
	"Year": "Year",
	3 : "Year",
}

export const getAggregationLevelDisplayName = (key: AggregationLevel | (keyof typeof AggregationLevel & string)) => {
	return AggregationLevelDisplayNames[key];
}

