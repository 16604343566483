export interface IRamInfo {
	id: string | undefined;

	maxRam: number;

	usedRam: number;

	maxSwap: number;

	usedSwap: number;

	maxIram: number;

	usedIram: number;

	emcUsage: number;

	emcFrequency: number;

	$type?: string | undefined;
}
export class RamInfo {
	constructor(data?: IRamInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	maxRam: number = 0;

	usedRam: number = 0;

	maxSwap: number = 0;

	usedSwap: number = 0;

	maxIram: number = 0;

	usedIram: number = 0;

	emcUsage: number = 0;

	emcFrequency: number = 0;

	static $type: string = "CoreApi.Models.Hardware.RamInfo, ";
	$type?: string | undefined = RamInfo.$type;
}

