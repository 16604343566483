import cn from "classnames";
import React, { PropsWithChildren } from 'react';

export type ContainerProps = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLTableElement>, HTMLTableElement>;
export type HeaderColProps = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;
export type HeaderRowProps = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;
export type RowProps = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;
export type ColProps = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;
export type BodyProps = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;
export type HeaderProps = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;


type TableType = {
    Container: React.FC<PropsWithChildren<ContainerProps>>,
    Body: React.FC<PropsWithChildren<BodyProps>>,
    Header: React.FC<PropsWithChildren<HeaderProps>>,
    HeaderRow: React.FC<PropsWithChildren<HeaderRowProps>>,
    HeaderCol: React.FC<PropsWithChildren<HeaderColProps>>,
    Row: React.FC<PropsWithChildren<RowProps>>,
    Col: React.FC<PropsWithChildren<ColProps>>
};

export const Table: TableType = {
    Container: ({ children, className = "w-full", ...props }) => {
        return (
            <table className={"" + className} {...props}>
                {children}
            </table>
        );
    },
    Body: ({ children, className = "", ...props }) => {
        return (
            <tbody className={className}>
                {children}
            </tbody>
        );
    },
    Header: ({ children, className = "", ...props }) => {
        return (
            <thead className={className} {...props}>
                {children}
            </thead >
        );
    },
    HeaderRow: ({ children, className = "", ...props }) => {
        return (
            <tr className={cn("border-b border-separators-page", className)} {...props}>
                {children}
            </tr>
        );
    },
    HeaderCol: ({ children, className = "pr-3", ...props }) => {
        return (
            <th className={className} {...props}>
                {children}
            </th>
        );
    },
    Row: ({ children, className = "", ...props }) => {
        return (
            <tr className={cn("border-b border-separators-page py-3", className)} {...props}>
                {children}
            </tr>
        );
    },
    Col: ({ children, className = "pr-3", ...props }) => {
        return (
            <td className={"" + className} {...props}>
                {children}
            </td>
        );
    }
}
