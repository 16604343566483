import { MutableRefObject } from "react";

export function printElement(ele: MutableRefObject<Element | null>, title?: string, isLandscape = false) {
    if (!ele || !ele.current) {
        return;
    }
    var printContents = ele.current.children[0].children[1].innerHTML;//TODO: 
    const w = window.open();
    if (w) {
        w.document.write(wrapHtmlPage(printContents, title, isLandscape));
        w.print();
        w.close();
    }
}


function wrapHtmlPage(s: string, title?: string, isLandscape = false) {
    return `        
        <!DOCTYPE html>
        <html>
        <head>
        <title>${title?.length ? title : 'Omnisight Report'}</title>
        <style type="text/css" media="print">
            @page { size: ${isLandscape ? 'landscape' : 'portrait'}; }
            </style>
        </head>
        <body>${s}</body>
        </html>
    `;
}