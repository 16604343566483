import { Button } from "@app/shared";
import { useNavigate } from "react-router-dom";
import { NoAuthAccountLayout } from "../layouts";
import { Icons } from "../shared";

export const PageNotFound: React.FC = (props) => {
    const navigate = useNavigate();
    const handleBackOnClick = () => {
        navigate(-1);
    }
    return (
        <NoAuthAccountLayout>
            <div className="flex place-items-center gap-5">
                <Icons.Page404 size={100} />
                <div>
                    <h2>Page Not Found (404)</h2>
                    <p className="pt-5">The page you are looking for does not exist.  If this continues to happen please contact support.</p>
                </div>
            </div>
            <div className="flex place-items-center justify-center">
                <Button onClick={handleBackOnClick}>Go Back</Button>
            </div>
        </NoAuthAccountLayout>
    );
}