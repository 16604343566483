import { Int32, IInt32 } from "./System.Int32.gen";
import { TaskCommand } from "./TaskCommand.gen";

export interface ISensorStreamDataCommandParams {
	command: TaskCommand;

	tcpStreamIp: string | undefined;

	tcpStreamPort: number | undefined;

	$type?: string | undefined;
}
export class SensorStreamDataCommandParams {
	constructor(data?: ISensorStreamDataCommandParams) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	command: TaskCommand = 0;

	tcpStreamIp: string | undefined;

	tcpStreamPort: number | undefined;

	static $type: string = "CoreApi.Models.DeviceCommands.SensorStreamDataCommandParams, ";
	$type?: string | undefined = SensorStreamDataCommandParams.$type;
}

