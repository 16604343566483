import { RoleTypes } from "./RoleTypes.gen";

export interface IUserRole {
	types: RoleTypes | number ;

	name: string | undefined;

	$type?: string | undefined;
}
export class UserRole {
	constructor(data?: IUserRole) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	types: RoleTypes | number  = 0;

	name: string | undefined;

	static $type: string = "CoreApi.Models.UserRole, ";
	$type?: string | undefined = UserRole.$type;
}

