import { AxisScaleOutput } from "@visx/axis";
import { ScaleConfig } from "@visx/scale";
import { Text } from "@visx/text";
import { Axis, Grid, LineSeries, Tooltip, XYChart } from "@visx/xychart";
import React from "react";

export type HardwareStatusChartProps<T> = {
    id?: string;
    title?: string;
    data: T[] | undefined;
    series: SeriesDetails<T>[];
    isLoading?: boolean;
    yUnit?: string;
    domain?: [number, number];
} & ChartSizeProps;

export type ChartSizeProps = {
    width?: number;
    height?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
}

export type SeriesAccessors<T> = {
    date: (data?: T) => Date | undefined;
    xAccessor: (data?: T) => Date | undefined;
    yAccessor: (data?: T) => number | undefined;
}

export type SeriesDetails<T> = {
    dataKey: string;
    accessors: SeriesAccessors<T>;
    opacity?: number;
}

type YScaleConfig = ScaleConfig<AxisScaleOutput, any, any>;

export function HardwareStatusChart<T extends object>({ id, title, data, series, isLoading, width, height = 300, domain, margin = { top: 10, right: 25, bottom: 50, left: 50 }, yUnit }: HardwareStatusChartProps<T>) {
    var yScale = { type: 'linear', nice: true } as YScaleConfig;
    if (domain) {
        yScale.domain = domain;
    }
    return (<>
        <div className="w-full">
            <h2 className="text-center w-full pb-0 mb-0">{title}</h2>
            <XYChart height={height} xScale={{ type: 'time' }} yScale={yScale} margin={margin}>
                <Axis orientation="bottom" numTicks={5} tickComponent={({ formattedValue, ...tickProps }) => {
                    return (<>
                        {formattedValue?.split("|").map((str, ind) => <Text key={str} {...tickProps} dy={ind > 0 ? 15 : 0}>{str}</Text>)}
                    </>);
                }} />
                <Axis orientation="left" numTicks={5} />
                <Grid columns={false} numTicks={5} />

                {(series && data) && series.map(c =>
                    <LineSeries key={c.dataKey} dataKey={c.dataKey} data={data} {...(c.accessors as any)} opacity={c.opacity} />
                )}

                <Tooltip<T>
                    snapTooltipToDatumX
                    snapTooltipToDatumY
                    showVerticalCrosshair
                    showSeriesGlyphs
                    renderTooltip={({ tooltipData, colorScale }) => (
                        <div>
                            {(tooltipData?.nearestDatum?.datum && series[0].accessors.date(tooltipData?.nearestDatum?.datum)?.toDateString()) || "No Date"}
                            <br />
                            {(tooltipData?.nearestDatum?.datum && series[0].accessors.date(tooltipData?.nearestDatum?.datum)?.toTimeString()) || "No Time"}
                            <br /><br />
                            {Object.keys(tooltipData?.datumByKey ?? {}).map(val => (
                                <React.Fragment key={val}>
                                    {(series.find(c => c.dataKey === val)!.accessors.yAccessor(tooltipData?.nearestDatum?.datum))?.toFixed(0) ?? "(No Data)"}{yUnit}&nbsp;&nbsp;
                                    <span style={{ color: colorScale ? colorScale(val ?? "") : "" }}>
                                        ({val})
                                    </span>
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                />
            </XYChart>
        </div>
    </>
    );
}