import { Organizations } from "@app/shared";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks";
import { Pages } from "../pages";
import { DropDownMenu } from "./DropDownMenu";
import { Icons } from "./Icons";
import { Link } from "./Link";

type OrganizationDropdownProps = {
    onChange: (newOrgId: string) => void;
    initOrgId: string | undefined;
};
export const OrganizationDropdown: React.FC<OrganizationDropdownProps> = (props) => {
    const [allOrgs] = useFetch(Organizations.getAllOptions);
    const navigate = useNavigate();


    const handleOnClick = (newOrgId: string) => {
        props.onChange(newOrgId);
    };

    const handleAddOnClick = () => {
        navigate(Pages.AddOrganization.getUrl());
    };

    return (
        <>
            {(allOrgs?.length ?? 0) > 0 &&
                <DropDownMenu dropDownClassNames="max-h-96 space-y-2 p-4" toggleChild={<div className="flex items-center space-x-2"><div>{allOrgs?.find(o => o.value === props.initOrgId)?.label ?? ""}</div><Icons.CaretDown /></div>}>
                    {allOrgs?.map(g => (
                        <Link key={g.value} onClick={() => handleOnClick(g.value!)}>{g.label}</Link>
                    ))}
                    <Link onClick={handleAddOnClick} className="border-t border-separators-page pt-3">+ Add New Organization</Link>
                </DropDownMenu>
            }
        </>
    );
}