import { FunctionComponent } from "react";
import { ErrorSummaryProps } from "../../hooks";


function buildErrorList(errors: string[]) {
    return (
        <ul>
            {errors.map((error, i) => <li key={i}>{error}</li>)}
        </ul>
    );
}

export const ErrorAlert: FunctionComponent<ErrorSummaryProps> = ({ errors }) => {
    if (typeof (errors) === 'undefined') {
        return null;
    }

    if (Array.isArray(errors)) {
        if (errors.length === 0) { return null; }
        if (errors.length === 1) { errors = errors[0]; }
    }

    const children = (typeof (errors) === 'string') ? errors : buildErrorList(errors);
    return (
        <div className="w-full rounded border border-separators-alert bg-background-alert text-separators-alert p-2 px-4">
            {children}
        </div>
    );
}