import { DateTime, IDateTime } from "./System.DateTime.gen";
import { Guid, IGuid } from "./System.Guid.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IDeviceNote {
	id: string | undefined;

	deviceId: string | undefined;

	note: string;

	dateCreated: string;

	dateUpdated: string | undefined;

	isDeleted: boolean;

	createdById: string | undefined;

	updatedById: string | undefined;

	$type?: string | undefined;
}
export class DeviceNote {
	constructor(data?: IDeviceNote) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.dateCreated = new Date(data.dateCreated);
		if (data.dateUpdated) { this.dateUpdated = new Date(data.dateUpdated); }
	}

	id: string | undefined;

	deviceId: string | undefined;

	@IsNotEmpty()
	note: string = "";

	@IsNotEmpty()
	dateCreated: Date = new Date();

	dateUpdated: Date | undefined;

	isDeleted: boolean = false;

	createdById: string | undefined;

	updatedById: string | undefined;

	static $type: string = "CoreApi.Models.DeviceNote, ";
	$type?: string | undefined = DeviceNote.$type;
}

