/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, post, del } from "./api_client";
import { ClientEndpoint, IClientEndpoint } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getByFacilityId(facilityId?: string): ApiResult<ClientEndpoint[]> {
	return get<IClientEndpoint[]>(`/api/clientendpoint/for-facility/${ facilityId }`).then(result => result === undefined ? undefined : result.map(o => new ClientEndpoint(o)));
}

export async function update(endPoint: ClientEndpoint): ApiResult<string> {
	return post<string>(`/api/clientendpoint`, endPoint);
}

export async function deleteEndpoint(id?: string): ApiResult<undefined> {
	return del(`/api/clientendpoint/${ id }`);
}
