import { PropsWithChildren } from "react";

type HeaderWithSeparatorProps = {
    disabled?: boolean;
} & PropsWithChildren;

export const HeaderWithSeparator: React.FC<HeaderWithSeparatorProps> = (props) => {
    return (
        <h4 className={`border-b border-separators-page font-semibold my-3 text-lg ${props.disabled ? 'text-muted-body-text' : ''}`}>{props.children}</h4>
    );
}