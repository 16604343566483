export interface IPowerInfo {
	id: string | undefined;

	total: number;

	totalAverage: number;

	gpu: number;

	gpuAverage: number;

	cpu: number;

	cpuAverage: number;

	$type?: string | undefined;
}
export class PowerInfo {
	constructor(data?: IPowerInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	total: number = 0;

	totalAverage: number = 0;

	gpu: number = 0;

	gpuAverage: number = 0;

	cpu: number = 0;

	cpuAverage: number = 0;

	static $type: string = "CoreApi.Models.Hardware.PowerInfo, ";
	$type?: string | undefined = PowerInfo.$type;
}

