import { Guid, IGuid } from "./System.Guid.gen";

export interface IOptionValue {
	label: string | undefined;

	value: string | undefined;

}
export class OptionValue {
	constructor(data?: IOptionValue) {
		if (!data) return;
		Object.assign(this, data);
		if ((this as any).$type) { delete (this as any).$type; };
	}

	label: string | undefined;

	value: string | undefined;

}

