import { StreamPortCommandParams, IStreamPortCommandParams } from "./DeviceCommands.StreamPortCommandParams.gen";
import { Device, IDevice } from "./Device.gen";
import { DateTime, IDateTime } from "./System.DateTime.gen";
import { Boolean, IBoolean } from "./System.Boolean.gen";

export interface IStreamPortCommand {
	description: string | undefined;

	commandParamsJson: IStreamPortCommandParams | undefined;

	device: IDevice | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	dateCreated: string | undefined;

	dateSentToDevice: string | undefined;

	dateCompleted: string | undefined;

	deviceDidSucceed: boolean | undefined;

	deviceResponse: string | undefined;

	$type?: string | undefined;
}
export class StreamPortCommand {
	constructor(data?: IStreamPortCommand) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.commandParamsJson) { this.commandParamsJson = new StreamPortCommandParams(data.commandParamsJson); }
		if (data.device) { this.device = new Device(data.device); }
		if (data.dateCreated) { this.dateCreated = new Date(data.dateCreated); }
		if (data.dateSentToDevice) { this.dateSentToDevice = new Date(data.dateSentToDevice); }
		if (data.dateCompleted) { this.dateCompleted = new Date(data.dateCompleted); }
	}

	description: string | undefined;

	commandParamsJson: StreamPortCommandParams | undefined;

	device: Device | undefined;

	id: string | undefined;

	deviceId: string | undefined;

	dateCreated: Date | undefined = new Date();

	dateSentToDevice: Date | undefined;

	dateCompleted: Date | undefined;

	deviceDidSucceed: boolean | undefined;

	deviceResponse: string | undefined;

	static $type: string = "CoreApi.Models.DeviceCommands.StreamPortCommand, ";
	$type?: string | undefined = StreamPortCommand.$type;
}

