import { CustomClaimValue, Devices, useAuth } from "@app/shared";
import { useState } from "react";
import { Dialog, ErrorAlert, UnassociatedDeviceDropdown } from ".";
import { Input, InputRow } from "./inputs";


type DeviceProvisionDialogProps = {
    show: boolean;
    onCancel: () => void;
    onFinished: () => void;
    facilityId: string;
};

export const DeviceProvisionDialog: React.FC<DeviceProvisionDialogProps> = (props) => {
    const user = useAuth();
    const isSuperAdmin = user.userClaims?.find(uc => uc.claims.find(c => c === CustomClaimValue.SuperAdmin));
    const [deviceKey, setDeviceKey] = useState<string>();
    const [deviceId, setDeviceId] = useState<string>();
    const [error, setError] = useState<string>();

    const handleOnOkClick = async () => {
        var idToAssociate;

        if (isSuperAdmin) {
            idToAssociate = deviceId;
        } else {
            //Lookup the device
            var device = await Devices.getUnassociatedDevice(deviceKey);
            if (!device) {
                setError(`Unable to find device with key ${deviceKey}`);
            } else {
                idToAssociate = device.id;

            }
        }
        await Devices.associateDevice(idToAssociate, props.facilityId);
        await props.onFinished();
    };

    return (
        <Dialog show={props.show} title={"Add Device"} onCancelClick={props.onCancel} onOkClick={handleOnOkClick}>
            {isSuperAdmin &&
                <>
                    <p>Select a device to associate with this facility.</p>
                    <ErrorAlert errors={error} />
                    <InputRow>
                        <UnassociatedDeviceDropdown onChange={(e) => setDeviceId(e.target.value)} />
                    </InputRow>
                </>
            }
            {!isSuperAdmin &&
                <>
                    <p>
                        Please enter the device code from the back of the device
                    </p>
                    <ErrorAlert errors={error} />
                    <InputRow>
                        <Input label="Device Key" value={deviceKey} onChange={(e) => setDeviceKey(e.target.value)} />
                    </InputRow>
                </>
            }
        </Dialog>
    );
}