import { DeviceCommand, DeviceCommands } from "@app/shared";
import { format } from "date-fns";
import { IconLink, Icons, LabeledText, SortableTable, Spinner, StatusDot } from ".";
import { toastError, toastSuccess } from "../../helpers";
import { useDialog } from "../../hooks";
import { DropDownMenu } from "./DropDownMenu";

type DeviceCommandTableProps = {
    commands: DeviceCommand[] | undefined;
    isLoading?: boolean;
    onCommandChanged?: (command: DeviceCommand) => void;
};

export const DeviceCommandTable: React.FC<DeviceCommandTableProps> = ({ commands, isLoading, onCommandChanged }) => {
    const detailsDialog = useDialog<DeviceCommand>();

    const handleRowClick = (command: DeviceCommand) => {
        detailsDialog.show("Device Command Details", "OK", command);
    };

    const handleResendCommandOnClick = async (command: DeviceCommand) => {
        try {
            await DeviceCommands.resendCommand(command.id);
        } catch (e) {
            toastError("Unable to resend", e + "");
        }
        if (onCommandChanged)
            onCommandChanged(command);
    };

    const handleDeleteCommandOnClick = async (command: DeviceCommand) => {
        try {
            await DeviceCommands.deleteById(command.id);
            toastSuccess("Deleted", "Command was deleted!");
        } catch (e) {
            toastError("Unable to delete", e + "");
        }
        if (onCommandChanged)
            onCommandChanged(command);
    };

    return (
        <>
            <SortableTable
                isLoading={isLoading}
                onRowClick={handleRowClick}
                initialSortDir="desc"
                initialSortKey="dateCreated"
                emptyValue={<div className="py-10">No device commands were found</div>}
                columns={[
                    {
                        header: "",
                        renderFunc: (dc) => (!(dc?.dateSentToDevice) ?
                            <div className="h-2 w-[18px] mx-auto"><Spinner /></div> :
                            <StatusDot status={(dc?.deviceDidSucceed) ? 'ok' : (dc?.deviceDidSucceed === undefined ? undefined : 'error')} />),
                        width: 25

                    },
                    {
                        header: "Command",
                        width: 200,
                        dataKey: "description"
                    },
                    {
                        header: "Created",
                        dataKey: "dateCreated",
                        renderFunc: dc => dc?.dateCreated?.toString(),
                        width: 200
                    },
                    {
                        header: "",
                        width: 20,
                        renderFunc: deviceCommand => (
                            <DropDownMenu>
                                <IconLink Icon={Icons.Send} onClick={() => handleResendCommandOnClick(deviceCommand!)}>Re-send</IconLink>
                                <IconLink Icon={Icons.Trash} onClick={() => handleDeleteCommandOnClick(deviceCommand!)} >Delete</IconLink>
                            </DropDownMenu>
                        )
                    }

                ]}
                data={commands}
            />
            {detailsDialog.renderDialog((value) => (
                <div className="grid grid-cols-2">
                    <LabeledText label="Date Created">
                        {value?.dateCreated && format(value.dateCreated, "MM-dd-yyyy p")}
                    </LabeledText>
                    <LabeledText label="Date Sent to Device">
                        {value?.dateSentToDevice && format(value?.dateSentToDevice, "MM-dd-yyyy p")}
                    </LabeledText>
                    <LabeledText label="Date Completed">
                        {value?.dateCompleted && format(value?.dateCompleted, "MM-dd-yyyy p")}
                    </LabeledText>
                    {value && (value as any).commandParamsJson &&
                        <LabeledText label="Command Params JSON" className="col-start-1 col-span-2">
                            {JSON.stringify((value as any).commandParamsJson)}
                        </LabeledText>
                    }
                    <LabeledText label="Device Response JSON" className="col-start-1 col-span-2">
                        {value?.deviceResponse}
                    </LabeledText>
                </div>
            ))}
        </>
    );
}