import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { IconType } from "react-icons";

export type IconLinkProps = {
    Icon: IconType;
    iconSize?: number;
    iconClassName?: string;
    rotation?: number;

} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const IconLink: FunctionComponent<IconLinkProps> = (props) => {
    const { Icon, children, className, iconClassName, iconSize, ...rest } = props;
    const transform = props.rotation ? { transform: `rotate(${props.rotation}deg)` } : undefined;
    return (
        <div {...rest} className={"cursor-pointer text-link-page hover:text-link-page-hover inline-flex h-full " + className}>
            <div className="self-center text-center align-middle pr-2">
                <Icon className={(iconClassName ?? "") + " my-auto"} size={iconSize} style={transform} />
            </div>
            {children &&
                <div className="self-center">
                    {children}
                </div>
            }
        </div>
    );
}