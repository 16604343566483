import { TargetType } from "./Shared.TargetType.gen";

export interface IZoneOccupancy {
	id: string | undefined;

	facilityId: string | undefined;

	deviceId: string | undefined;

	detectionZoneId: string | undefined;

	dateDetected: string | undefined;

	isOccupied: boolean;

	isOccupiedConfidence: number;

	targetType: TargetType;

	targetTypeConfidence: number;

	$type?: string | undefined;
}
export class ZoneOccupancy {
	constructor(data?: IZoneOccupancy) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.dateDetected) { this.dateDetected = new Date(data.dateDetected); }
	}

	id: string | undefined;

	facilityId: string | undefined;

	deviceId: string | undefined;

	detectionZoneId: string | undefined;

	dateDetected: Date | undefined = new Date();

	isOccupied: boolean = false;

	isOccupiedConfidence: number = 0;

	targetType: TargetType = 0;

	targetTypeConfidence: number = 0;

	static $type: string = ".ZoneOccupancy, ";
	$type?: string | undefined = ZoneOccupancy.$type;
}

