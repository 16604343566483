import { FC } from "react";

export const GooglePlacesScript = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`;

export type PlaceSuggestionsProps = {
    onClick: (placeId: string) => any | void,
    suggestions: google.maps.places.AutocompletePrediction[]
};

interface Address {
    address1?: string | undefined;
    address2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    country?: string | undefined;
}
export const getAddressDescription = (a: Address) => `${a.address1}, ${a.address2}, ${a.city}, ${a.state} ${a.zip}`

export const parseDetailsIntoAddress = (details: google.maps.places.PlaceResult) => {
    let address1 = "";
    let postcode = "";
    var city;
    var state;
    var country;

    // place.address_components are documented at http://goo.gle/3l5i5Mr
    for (const component of details.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address1 = `${component.long_name} ${address1}`;
                break;
            }
            case "route": {
                address1 += component.short_name;
                break;
            }
            case "postal_code": {
                postcode = `${component.long_name}${postcode}`;
                break;
            }
            case "postal_code_suffix": {
                postcode = `${postcode}-${component.long_name}`;
                break;
            }
            case "locality":
                city = component.long_name;
                break;
            case "administrative_area_level_1": {
                state = component.short_name;
                break;
            }
            case "country":
                country = component.short_name;
                break;
        }
    }

    return { "street": address1, "city": city, "state": state, "zipCode": postcode, "country": country };
}

export const PlaceSuggestions: FC<PlaceSuggestionsProps> = (props) => {
    const renderPlaceSuggestions = () =>
        props.suggestions.map((suggestion) => {

            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={() => onClick(place_id)} className="w-full p-2 cursor-pointer flex">
                    <div className="flex-grow">
                        <strong>{main_text}</strong> <small>{secondary_text}</small>
                    </div>
                    <div className="mx-2 text-page-link">
                        select
                    </div>
                </li>
            );
        });

    function onClick(placeId: string) {
        props.onClick(placeId);
    }

    return (
        <ul className="divide-y divide-page divide-opacity-30 border-page-border border mb-3">
            {renderPlaceSuggestions()}
        </ul>
    );
}        