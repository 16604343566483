export enum DeviceStatus {
	Unknown = 0,
	Healthy = 1,
	RadarDown = 2,
	BadConfig = 4,
	BadData = 8,
	LostConnection = 999,
}

export const DeviceStatusDisplayNames: { [key in keyof typeof DeviceStatus]?: string} = {
	"Unknown": "Unknown",
	0 : "Unknown",
	"Healthy": "Healthy",
	1 : "Healthy",
	"RadarDown": "Radar Down",
	2 : "Radar Down",
	"BadConfig": "Bad Configuration",
	4 : "Bad Configuration",
	"BadData": "Bad Data",
	8 : "Bad Data",
	"LostConnection": "Lost Connection",
	999 : "Lost Connection",
}

export const getDeviceStatusDisplayName = (key: DeviceStatus | (keyof typeof DeviceStatus & string)) => {
	return DeviceStatusDisplayNames[key];
}

