import { Button, PasswordReset, Sessions } from "@app/shared";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { Pages } from "..";
import { useModel } from "../../../hooks";
import { NoAuthAccountLayout } from "../../layouts";
import { InputRow, PasswordInput } from "../../shared";

export const ResetPassword: FC = (props) => {
    const { id } = useParams();
    const token = useSearchParam("token");
    const [passwordReset, pwResetHelpers] = useModel(PasswordReset, { resetToken: token ?? undefined, userId: id ?? undefined });
    const navigate = useNavigate();

    const handleSetPasswordOnClick = () => {
        pwResetHelpers.validateWithAction(async () => {
            await Sessions.resetPassword(passwordReset as PasswordReset);
            navigate(Pages.ViewFacilityGroups.getUrl());
        });
    };

    return (
        <NoAuthAccountLayout>

            <h2>Reset Account Password</h2>
            <div className={pwResetHelpers.modelContainerClassNames}>
                <InputRow>
                    <pwResetHelpers.ErrorSummary />
                </InputRow>
                <InputRow>
                    <PasswordInput label="Password" {...pwResetHelpers.bindingsFor("password")} />
                </InputRow>
                <InputRow>
                    <PasswordInput label="Confirm Password" {...pwResetHelpers.bindingsFor("passwordConfirmation")} />
                </InputRow>
            </div>
            <div className="flex place-items-center justify-center gap-5">
                <Button onClick={handleSetPasswordOnClick}>Set Password</Button>
            </div>
        </NoAuthAccountLayout>
    );
}