/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsEmail, IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IUserLogin {
	email: string;

	password: string;

	rememberMe: boolean;

	$type?: string | undefined;
}
export class UserLogin {
	constructor(data?: IUserLogin) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	@IsNotEmpty()
	@IsEmail({}, {
	  "message": "The $property field is not a valid e-mail address."
	})
	email: string = "";

	@IsNotEmpty()
	password: string = "";

	rememberMe: boolean = false;

	static $type: string = "CoreApi.Models.View.UserLogin, ";
	$type?: string | undefined = UserLogin.$type;
}

