import { FC, InputHTMLAttributes, useRef } from "react";
import { CustomInputProps, ValidatedInputGroup } from ".";

type CheckboxInputProps = {
    label?: string
} & Omit<InputHTMLAttributes<HTMLInputElement>, "label" | "type"> & CustomInputProps;

export const CheckboxInput: FC<CheckboxInputProps> = (props) => {
    const { fieldName, validationError, validateModel, className, label, ...inputProps } = props;

    const inputEle = useRef<HTMLInputElement>(null);
    function onLabelClick(e: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
        if (Object && inputEle.current) {
            // This will not update the React component state
            const descriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'checked')!;
            descriptor.set?.call(inputEle.current, !inputEle.current.checked);
            inputEle.current.dispatchEvent(new Event('click', { bubbles: true }));
        }
    }

    return (
        <ValidatedInputGroup fieldName={fieldName} validate={validateModel} validationError={validationError}>
            {({ handleBlur }) =>
                <div className="flex flex-row place-items-center gap-2">
                    <input
                        ref={inputEle}
                        className={className ? className : "border-0 shadow-none w-5 h-10"}
                        type="checkbox"
                        onBlur={handleBlur}                     
                        {...inputProps}
                    />
                    {props.label && <label onClick={onLabelClick}>{props.label}</label>}
                </div>
            }
        </ValidatedInputGroup>
    );
}
