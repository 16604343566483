export interface IAdoptCommandParams {
	id: string | undefined;

	fid: string | undefined;

	$type?: string | undefined;
}
export class AdoptCommandParams {
	constructor(data?: IAdoptCommandParams) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	id: string | undefined;

	fid: string | undefined;

	static $type: string = "CoreApi.Models.DeviceCommands.AdoptCommandParams, ";
	$type?: string | undefined = AdoptCommandParams.$type;
}

