import { Int32, IInt32 } from "./System.Int32.gen";

export interface IPowerUsageInfo {
	statusTimestamp: string | undefined;

	total: number | undefined;

	gpu: number | undefined;

	cpu: number | undefined;

	$type?: string | undefined;
}
export class PowerUsageInfo {
	constructor(data?: IPowerUsageInfo) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.statusTimestamp) { this.statusTimestamp = new Date(data.statusTimestamp); }
	}

	statusTimestamp: Date | undefined = new Date();

	total: number | undefined;

	gpu: number | undefined;

	cpu: number | undefined;

	static $type: string = "CoreApi.Models.View.Hardware.PowerUsageInfo, ";
	$type?: string | undefined = PowerUsageInfo.$type;
}

