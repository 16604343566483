import { DetectionZone, Facility, ScreenLine } from "@app/shared";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ICoordsHelper, MapContext, MapObjArray, createGooglePolygon, useFacilityCoordsHelper } from "../../../../helpers";

export type GroupMapShapeProps = { facility: Facility, color?: string, shapes: Array<DetectionZone | ScreenLine>, isSelected?: boolean, isEditable?: boolean, onClick?: (shapes: Array<DetectionZone | ScreenLine>) => void };
export const GroupMapShape: React.FC<GroupMapShapeProps> = ({ facility, shapes, isEditable, onClick, color }) => {
    const [parentMap, setBounds] = useContext(MapContext);
    const mapObjects = useRef([] as MapObjArray);
    const listeners = useRef([] as Array<google.maps.MapsEventListener>);

    const [zonePoly, setZonePoly] = useState<google.maps.Polygon>();
    const originalCenter = useRef<google.maps.LatLng>();
    const coordsHelper = useFacilityCoordsHelper(facility);

    useEffect(() => {//create/update google poly for shape      
        if (parentMap && shapes?.length) {
            const [poly, center] = initPoly(shapes, parentMap, coordsHelper, color);
            originalCenter.current = center;
            setZonePoly(poly);
            setBounds(curr => {
                poly?.getPath().getArray().forEach(pt => curr.extend(pt));
                return curr;
            });
            return () => {
                mapObjects.current?.forEach(mo => { mo?.setVisible(false); mo.setMap(null); });
                mapObjects.current = [];
                poly?.setMap(null); poly.setVisible(false);
                setZonePoly(undefined);
            }
        }
    }, [parentMap, mapObjects, shapes, color, coordsHelper, setBounds]);

    const handleMove = useCallback((e: google.maps.MapMouseEvent) => {

        if (zonePoly && originalCenter.current && e.latLng) {
            const xDelta = e.latLng.lng() - originalCenter.current.lng();
            const yDelta = e.latLng.lat() - originalCenter.current.lat();

            const newPaths = shapes.map(shape => shape.points?.map(coordsHelper.toLatLng)).map(path => path!.map(pt => new google.maps.LatLng({ lng: pt.lng() + xDelta, lat: pt.lat() + yDelta })));

            zonePoly.setPaths(newPaths);
            zonePoly.setMap(parentMap ?? null);
            zonePoly.setVisible(true);
        }
    }, [parentMap, coordsHelper, shapes, zonePoly]);

    const handleClick = useCallback((e: google.maps.MapMouseEvent) => {

        if (originalCenter.current && e.latLng) {
            const xDelta = e.latLng.lng() - originalCenter.current.lng();
            const yDelta = e.latLng.lat() - originalCenter.current.lat();

            const updatedShapes = shapes.map(shape => {
                if (!shape.points) { return shape; }
                const ns = shape.$type === DetectionZone.$type ? Object.assign(new DetectionZone(), { ...shape }) : Object.assign(new ScreenLine(), { ...shape });
                ns.points = shape.points
                    .map(coordsHelper.toLatLng)
                    .map(pt => new google.maps.LatLng({ lng: pt.lng() + xDelta, lat: pt.lat() + yDelta }))
                    .map(coordsHelper.fromLatLng);
                return ns;
            });
            onClick?.(updatedShapes);
        }
    }, [shapes, coordsHelper, onClick]);

    useEffect(() => {
        listeners.current.forEach(l => l.remove());
        listeners.current = [];

        if (!isEditable) {
            return;
        }

        if (zonePoly && parentMap) {
            listeners.current.push(parentMap.addListener('mouseout', () => zonePoly.setVisible(false)));
            listeners.current.push(parentMap.addListener('mousein', () => zonePoly.setVisible(true)));
            listeners.current.push(parentMap.addListener('mousemove', handleMove));
            listeners.current.push(parentMap.addListener('click', handleClick));

            return () => {
                listeners.current.forEach(l => l?.remove());
                listeners.current = [];
            }
        }
    }, [zonePoly, parentMap, isEditable, handleClick, handleMove]);

    return null;
}

function initPoly(shapes: Array<DetectionZone | ScreenLine>, parentMap: google.maps.Map, coordsHelper: ICoordsHelper, color?: string,) {
    return createGooglePolygon(shapes.map(s => s.points ?? []), coordsHelper, parentMap, {
        visible: false,

        strokeOpacity: 0.8,
        draggable: true,
        zIndex: 100
    });
}