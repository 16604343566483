import { FC, PropsWithChildren } from "react";
import { Toaster } from "react-hot-toast";

export const NoAuthLayout: FC<PropsWithChildren & { className?: string }> = (props) => {
    return (
        <>
            <Toaster />
            <div className={props.className ?? "h-full w-full overflow-auto"}>
                {props.children}
            </div>
        </>
    );
}
